import {Button, Card, Col, Row, Table} from 'antd';
import React, {FC, memo, Fragment, ReactNode} from 'react';
import {Redirect} from 'react-router-dom';
import {BaseQuoteLine, useQuote} from '../../contexts/Quote';
import ConfirmationButton from '../../util/ConfirmationButton';
import OptionDescription from './OptionDescription';
import {useUser} from '../../contexts/Session';
import DocumentModal from './DocumentModal';
import {getOrder, ArticleListItem} from './util';
import {ColumnProps} from 'antd/lib/table';

interface CartLineProps {
  line: BaseQuoteLine;
  onEdit?: () => void;
  onDelete?: () => void;
}

const CartLine: FC<CartLineProps> = ({line, onEdit, onDelete}) => {
  const {product, options, articles, total} = line;

  const [quote] = useQuote();
  const user = useUser();

  if (!user) {
    return <Redirect to="/login" push={true} />;
  }

  const order = getOrder(quote, user, quote.lines.indexOf(line));

  const columns: ColumnProps<ArticleListItem>[] = [
    {
      title: 'N°',
      dataIndex: 'num',
      key: 'num',
    },
    {
      title: 'Code',
      dataIndex: 'code',
      key: 'code',
    },
    {
      title: 'Libellé',
      dataIndex: 'libelle',
      key: 'libelle',
    },
    {
      title: 'Oreille',
      dataIndex: 'oreille',
      key: 'oreille',
    },
    {
      align: 'center',
      title: 'Qté',
      dataIndex: 'qty',
      key: 'qty',
    },
    {
      title: 'Unité',
      dataIndex: 'unit',
      key: 'unit',
    },
  ];

  if (user && user.blChiffre) {
    const renderPrice = (price: number): ReactNode => (
      <span>{typeof price !== 'number' || !price ? '-' : price.toFixed(2).replace('.', ',')}</span>
    );

    columns.push(
      {
        align: 'right',
        title: 'Px unit. (€)',
        dataIndex: 'unitPrice',
        key: 'unitPrice',
        // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
        // @ts-ignore
        render: renderPrice,
      },
      {
        align: 'right',
        title: 'Rem. (%)',
        dataIndex: 'discount',
        key: 'discount',
        // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
        // @ts-ignore
        // eslint-disable-next-line react/display-name
        render: (discount) => (
          <span>
            {discount === 0
              ? '-'
              : (discount * 100).toString().replace('.', ',')}
          </span>
        ),
      },
      {
        align: 'right',
        title: 'Px net (€)',
        dataIndex: 'netPrice',
        key: 'netPrice',
        render: renderPrice,
      },
      {
        align: 'right',
        title: 'Px total (€)',
        dataIndex: 'totalPrice',
        key: 'totalPrice',
        render: renderPrice,
      },
    );
  }

  const footer: (() => ReactNode) | undefined =
    user && user.blChiffre
      ? () => (
          <Row type="flex" justify="end">
            <Col>
              <span
                style={{
                  fontSize: 20,
                  fontWeight: 600,
                }}
              >
                Total: {(total || 0).toFixed(2).replace('.', ',')} €
              </span>
            </Col>
          </Row>
        )
      : undefined;

  return (
    <Card
      size="small"
      css={{marginBottom: '10px'}}
      title={
        <Row type="flex" justify="space-between">
          <Col css={{lineHeight: '32px'}}>{product?.label}</Col>
          <Col>
            <DocumentModal order={order} />

            <Button
              type="primary"
              onClick={onEdit}
              title="Modifier produit"
              css={{marginLeft: '2px', marginRight: '2px'}}
              icon="edit"
            />

            <ConfirmationButton
              callback={onDelete}
              confirmationText={
                <p>
                  Êtes-vous sûr(e) ?<br />
                  Les options sélectionnées seront perdues.
                </p>
              }
              confirmationButton="Oui, supprimer"
              type="danger"
              icon="delete"
            />
          </Col>
        </Row>
      }
    >
      <OptionDescription nocss={true} title="" options={options} showSelectedEars={false} />
      {articles && articles.length > 0 ? (
        <Fragment>
          <Table
            columns={columns}
            dataSource={articles}
            pagination={false}
            footer={footer}
          />
        </Fragment>
      ) : null}
    </Card>
  );
};

export default memo(CartLine);
