import {Icon, Select} from 'antd';
import {AbstractSelectProps} from 'antd/es/select';
import React, {memo, RefForwardingComponent, useEffect, useState} from 'react';
import {useAsync} from 'react-async-hook';
import {useSession} from '../../../contexts/Session';
import {WakandaService} from '../../../util/fetch-wakanda';
import {typedForwardRef} from '../../../util/render';
import {Adresse} from '../../Orders/data';
import CustomAddressModal from './CustomAddressModal';

const {Option} = Select;

export interface ShippingAddressSelectProps extends AbstractSelectProps {
  value?: Adresse;
  onChange?: (value: Adresse | undefined) => void;
  clientId: string;
}

const ShippingAddressSelect: RefForwardingComponent<
  Select<string>,
  ShippingAddressSelectProps
> = (props, ref) => {
  const {clientId, ...selectProps} = props;
  const {value: shippingAddress, onChange} = selectProps;

  const [customAddressModalVisible, setCustomAddressModalVisible] = useState(
    false,
  );
  const session = useSession();

  const addresses = useAsync(
    async (session: WakandaService, clientId: string) =>
      (clientId &&
        (await session.fetch<Adresse[]>('Clients', 'getAdressesLivraison', {
          clientId,
        }))) ||
      [],
    [session, clientId],
  );

  useEffect(() => {
    if (!addresses.result || addresses.result.length === 0) {
      return;
    }

    const currentAddressID = shippingAddress?.ID;
    const defaultAddress: Adresse | undefined =
      (addresses.result.length === 1
        ? addresses.result[0]
        : ['LP', 'G', 'L']
            .map((addressType) =>
              addresses.result?.find(({type}) => type === addressType),
            )
            .find((address) => !!address)) ?? undefined;

    if (
      (currentAddressID &&
        currentAddressID !== '-1' &&
        !addresses.result.find(({ID}) => ID === currentAddressID)) ||
      (!currentAddressID && defaultAddress)
    ) {
      onChange?.(defaultAddress);
    }
  }, [addresses, shippingAddress, onChange]);

  const customAddress: Adresse | null =
    shippingAddress?.ID === '-1' ? shippingAddress : null;

  const selectAddresses: Adresse[] = [
    ...(addresses?.result || []),
    ...(customAddress ? [customAddress] : []),
  ];

  return (
    <div>
      <Select<string>
        ref={ref}
        {...selectProps}
        value={shippingAddress?.ID}
        onChange={(addressID) => {
          if (addressID === 'custom') {
            return setCustomAddressModalVisible(true);
          }

          onChange?.(selectAddresses.find(({ID}) => ID === addressID));
        }}
      >
        {selectAddresses.map(({ID, adresse, codePostal, ville}, index) => (
          <Option value={ID} key={index}>
            {adresse}, {codePostal} {ville}
          </Option>
        ))}
        <Option value={'custom'}>
          <Icon type={customAddress ? 'edit' : 'plus'} style={{fontSize: 13}} />
          &nbsp;
          {customAddress ? 'Modifier la nouvelle adresse' : 'Nouvelle adresse'}
        </Option>
      </Select>
      <CustomAddressModal
        visible={customAddressModalVisible}
        onCancel={() => setCustomAddressModalVisible(false)}
        customAddress={customAddress ?? undefined}
        onValidate={(shippingAddress) => {
          onChange?.(shippingAddress);
          setCustomAddressModalVisible(false);
        }}
      />
    </div>
  );
};

export default memo(typedForwardRef(ShippingAddressSelect));
