import {css} from '@emotion/core';
import React, {FC} from 'react';
import {Option, TBoolean, UndefinedTuple} from '../../contexts/Quote';
import {borderContainerCss} from '../../util/render';
import {EVENT, TYPE} from './data';
import {OptionSelector} from './OptionSelector';
import {OPTION_GROUP_CHECK_TYPE, SelectableOption} from './static';

// prevents border overlap with left panel
export const withoutBorderLeftCss = css`
  position: relative;
  right: 1px;
`;

interface Props {
  options: SelectableOption[];
  checkType: OPTION_GROUP_CHECK_TYPE;
  setSelectedOption: (
    option: Option,
    checkType: OPTION_GROUP_CHECK_TYPE,
  ) => void;
}

export const OptionsGroup: FC<Props> = ({
  options,
  checkType,
  setSelectedOption,
}) => {
  if (!options) {
    return <div />;
  }

  const onCheck = (
    type: TYPE,
    name: string,
    label: string,
    selected: TBoolean,
    value?: UndefinedTuple<number>,
  ): void => {
    setSelectedOption({type, name, label, selected, value}, checkType);

    return undefined;
  };

  return (
    <div css={[borderContainerCss, withoutBorderLeftCss]}>
      {options.map((option, index) => {
        return (
          <OptionSelector
            key={index}
            {...option}
            checkType={
              checkType === OPTION_GROUP_CHECK_TYPE.EVENT_INPUT &&
              option.name !== EVENT.CUSTOM
                ? OPTION_GROUP_CHECK_TYPE.RADIO
                : checkType
            }
            onCheck={onCheck}
          />
        );
      })}
    </div>
  );
};
