import {css} from '@emotion/core';
import {Checkbox, Col, Radio, Row, Select} from 'antd';
import React, {FC, ReactNode} from 'react';
import {
  TBoolean,
  UndefinedTuple,
  useCurrentEars,
  useCurrentLockedEars,
} from '../../contexts/Quote';
import {
  colors,
  cssCursorPointer,
  cssDisabledLine,
  cssOption,
  DisabledEar,
} from '../../util/render';
import {
  CheckFunction,
  getSelectedEars,
  OPTION_GROUP_CHECK_TYPE,
  SelectableOption,
  SELECTED_EARS,
} from './static';

const {Option} = Select;

const cssNoMarginRight = css`
  margin-right: 0;
`;

const cssOptionSelector = css`
  &[data-check-type='EVENT_INPUT'] {
    .checked {
      background-color: ${colors.primary};
    }
    .input-container {
      padding: 6px;

      .ant-select-selection-selected-value {
        width: 100%;
      }
    }
  }
`;

interface Props extends SelectableOption {
  checkType: OPTION_GROUP_CHECK_TYPE;
  onCheck: CheckFunction;
}

export const OptionSelector: FC<Props> = ({
  checkType,
  type,
  name,
  label,
  style,
  disabled,
  checked,
  value,
  onCheck,
}) => {
  const [ears] = useCurrentEars();
  const [lockedEars] = useCurrentLockedEars();

  const [selectedEars, disabledEars] = [ears, disabled].map(getSelectedEars);
  const cssOptionArray = [cssCursorPointer, cssOption];

  const getComponent = (
    checkType: OPTION_GROUP_CHECK_TYPE,
    index: number,
  ): ReactNode => {
    switch (checkType) {
      case OPTION_GROUP_CHECK_TYPE.CHECKBOX:
        return <Checkbox disabled={disabled[index]} checked={checked[index]} />;
      case OPTION_GROUP_CHECK_TYPE.RADIO:
        return (
          <Radio
            css={cssNoMarginRight}
            disabled={disabled[index]}
            checked={checked[index]}
          />
        );
      case OPTION_GROUP_CHECK_TYPE.EVENT_INPUT:
        // [0.8, 0.9, ..., 3]
        // min={0.8} max={3} step={0.1}
        const values = Array(23)
          .fill(0)
          .map((_, index) => (index + 8) / 10);

        return (
          <Select<number>
            showSearch
            disabled={disabled[index]}
            style={{width: '100%'}}
            optionFilterProp="children"
            value={value?.[index]}
            dropdownClassName="dropdown-mm"
            onSelect={(_value) =>
              onCheck(
                type,
                name,
                label,
                setIndexChecked(index, true),
                setIndexValue(index, _value),
              )
            }
            filterOption={(input, option) =>
              (option.props.children || '')
                .toString()
                .replace(/[,.]/g, '')
                .includes(input.toLowerCase())
            }
            showArrow={false}
            dropdownMatchSelectWidth={false}
            dropdownStyle={{width: 100}}
          >
            {values.map((val) => (
              <Option key={val} value={val}>
                {val.toFixed(1).replace('.', ',')}
              </Option>
            ))}
          </Select>
        );
      default:
        return null;
    }
  };

  const toggleBoth = (): TBoolean =>
    (checked[0] || disabled[0]) && (checked[1] || disabled[1])
      ? [false, false]
      : [!disabled[0], !disabled[1]];

  const toggleIndex = (index: number): TBoolean =>
    [0, 1].map((_index) =>
      index === _index
        ? !disabled[_index] && !checked[_index]
        : checked[_index],
    ) as TBoolean;

  const setIndexChecked = (index: number, _checked: boolean): TBoolean =>
    [0, 1].map((_index) =>
      index === _index ? !disabled[_index] && _checked : checked[_index],
    ) as TBoolean;

  const setIndexValue = (
    index: number,
    _value: number,
  ): UndefinedTuple<number> =>
    [0, 1].map((_index) =>
      index === _index ? _value : (value || [])[_index] || undefined,
    ) as UndefinedTuple<number>;

  const lockedMode = selectedEars === SELECTED_EARS.BOTH && lockedEars;
  const displayedEars = lockedMode ? [ears[0]] : ears;

  return (
    <Row
      type="flex"
      justify="center"
      data-check-type={checkType}
      css={[
        cssOptionSelector,
        checkType === OPTION_GROUP_CHECK_TYPE.EVENT_INPUT
          ? null
          : cssCursorPointer,
        disabledEars === SELECTED_EARS.BOTH ? cssDisabledLine : null,
      ]}
    >
      <Col
        span={18}
        css={[cssOption, disabledEars !== SELECTED_EARS.BOTH ? style : null]}
        onClick={() => {
          if (checkType === OPTION_GROUP_CHECK_TYPE.EVENT_INPUT) {
            return;
          }
          onCheck(type, name, label, toggleBoth());
        }}
      >
        {label}
      </Col>
      {displayedEars.map((ear, index) => (
        <Col
          key={index}
          span={6 / displayedEars.length}
          className={[
            'input-container',
            checked[index] ? 'checked' : '',
            // TODO 'has-error',
          ]
            .filter((className) => !!className)
            .join(' ')}
          css={[
            cssOptionArray,
            !ear || disabled[index] ? cssDisabledLine : null,
          ]}
          onClick={(event) => {
            // prevents firing onCheck twice when clicking the outside of the radio
            event.preventDefault();
            if (checkType === OPTION_GROUP_CHECK_TYPE.EVENT_INPUT) {
              return;
            }

            onCheck(
              type,
              name,
              label,
              lockedMode ? toggleBoth() : toggleIndex(index),
            );
          }}
        >
          {ear ? getComponent(checkType, index) : <DisabledEar />}
        </Col>
      ))}
    </Row>
  );
};
