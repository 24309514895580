import React, {FC, memo} from 'react';
import {useHistory} from 'react-router-dom';
import {
  Product,
  useCurrentCategory,
  useCurrentProduct,
  useCurrentSelectedOptions,
} from '../../contexts/Quote';
import ProductSelection from './ProductSelection';

const Products: FC<{parentPath: string}> = ({parentPath}) => {
  const [category] = useCurrentCategory();
  const [product, setProduct] = useCurrentProduct();
  const [_, setSelectedOptions] = useCurrentSelectedOptions();
  const history = useHistory();

  if (!category) {
    return null;
  }

  const onSelect = (_product: Product): void => {
    if (_product !== product) {
      setProduct(_product);
      setSelectedOptions([]);
    }

    history.push(`${parentPath}/options`);
  };

  return (
    <ProductSelection
      products={category.products}
      onSelect={onSelect}
      value={product || undefined}
    />
  );
};

export default memo(Products);
