import {css} from '@emotion/core';
import {Col, Icon, Layout, Row} from 'antd';
import React, {FC, memo} from 'react';
import {version} from '../package.json';

const {Footer: AntFooter} = Layout;

const footerCss = css`
  background-color: white;
  border-top: solid 1px #ccc;
  padding: 12px 50px;
`;

const Footer: FC = () => {
  return (
    <AntFooter css={footerCss}>
      <Row type="flex" justify="center" gutter={20}>
        <Col span={10}>
          <p>
            <span css={{fontWeight: 'bold'}}>
              Fabricant d&apos;embouts et protections auditives sur mesure
            </span>
            <br />
            MADE IN FRANCE DEPUIS 1945
            <br />
            V: {version}
          </p>
        </Col>
        <Col span={10}>
          <p>
            <a href="tel:+33147709711" css={{fontStyle: 'italic'}}>
              01 47 70 97 11
            </a>
            <br />
            DU LUNDI AU VENDREDI DE 9h À 18h
            <br />
            <Icon type="mail" /> Nous contacter
          </p>
        </Col>
      </Row>
    </AntFooter>
  );
};

export default memo(Footer);
