import {Alert, Card, Col, Form, Row, Spin} from 'antd';
import React, {FC, memo, useState} from 'react';
import {useHistory} from 'react-router-dom';
import {useRestore, useSession} from '../../contexts/Session';
import Logo from '../../Logo';
import {cssUtils} from '../../util/render';
import LoginForm, {LoginFormProps, LoginFormSubmitCallback} from './Form';

export interface LoginProps {
  callback?: string;
}

const Login: FC<LoginProps> = ({callback}) => {
  const session = useSession();
  const history = useHistory();
  const [attemptedRestore, restoredUser] = useRestore();
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);

  const WrappedLoginForm = Form.create<LoginFormProps>({
    name: 'loginForm',
  })((props) => <LoginForm {...props} loading={loading} />);

  const submit: LoginFormSubmitCallback = async ({
    username,
    password,
    remember,
  }) => {
    setError('');
    setLoading(true);

    const res = await session.login(username, password, remember);
    setLoading(false);

    if (res) {
      history.push(callback || '/');
      return;
    }

    let healthStatus = 500;
    try {
      healthStatus = (
        await fetch(`${process.env.REACT_APP_WAKANDA_HOST}/health`)
      ).status;
    } finally {
      setError(
        healthStatus === 200
          ? "Votre nom d'utilisateur et/ou mot de passe sont invalides. Merci de ré-éssayer"
          : [
              "Connexion au serveur impossible.",
              'Merci vérifier votre connexion, ou de ré-éssayer dans quelques minutes',
            ].join(' '),
      );
    }
  };

  if (!attemptedRestore) {
    return (
      <Row type="flex" justify="center" align="middle" css={cssUtils.heightP()}>
        <Col span={20} xl={8} css={{textAlign: 'center'}}>
          <Spin size="large" />
        </Col>
      </Row>
    );
  }

  if (attemptedRestore && restoredUser) {
    history.goBack();
    return null;
  }

  return (
    <Row type="flex" justify="center" align="middle" css={cssUtils.heightP()}>
      <Col span={20} xl={8}>
        <Row
          type="flex"
          justify="center"
          align="middle"
          css={{backgroundColor: 'white', padding: '20px 0', marginBottom: 20}}
        >
          <Col>
            <Logo />
          </Col>
        </Row>
        <Row>
          {error ? (
            <Alert
              message="Erreur de connexion"
              description={error}
              type="error"
              closable
              css={{marginBottom: 20}}
            />
          ) : null}
          <Card title="Connexion à Surdifuse" css={{width: '100%'}}>
            <WrappedLoginForm onSubmit={submit} />
          </Card>
        </Row>
      </Col>
    </Row>
  );
};

export default memo(Login);
