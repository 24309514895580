import {Button, Col, Row} from 'antd';
import React, {FC, memo, useState} from 'react';
import {Redirect, useHistory} from 'react-router-dom';
import {useUser} from '../contexts/Session';
import {cssUtils} from '../util/render';
import News from './News';
import {UserType} from '../util/fetch-wakanda';

const Landing: FC = () => {
  const user = useUser();
  const history = useHistory();

  const [imageErrorStatus, setImageErrorStatus] = useState(false);

  if (!user) {
    return <Redirect to="/login" push={true} />;
  }

  return (
    <Row type="flex" justify="center" css={cssUtils.heightP()}>
      <Col span={20} xl={14}>
        <Col css={{flex: 1, textAlign: 'center'}}>
          <div css={{margin: '10px 0'}}>
            <h1>Bienvenue, {user.type === UserType.CLIENT ? user.fullName : user.name}</h1>
            <Button icon="logout" onClick={() => history.push('/logout')}>
              Déconnexion
            </Button>
          </div>
          {user.type === UserType.CLIENT && !imageErrorStatus ? (
            <img
              src={`${process.env.REACT_APP_ASSETS_HOST}/centrale/${user.groupID}.png`}
              alt="Logo centrale"
              css={{margin: '10px 0', maxHeight: '150px', maxWidth: '400px'}}
              onError={() => setImageErrorStatus(true)}
            />
          ) : null}
          <News />
        </Col>
      </Col>
    </Row>
  );
};

export default memo(Landing);
