import {Input, Row} from 'antd';
import React, {FC, memo} from 'react';
import {useComment} from '../../contexts/Quote';

const {TextArea} = Input;

export const MAX_COMMENT_LENGTH = 4000;

const Comment: FC = () => {
  const [comment, setComment] = useComment();

  return (
    <Row>
      <h3><label htmlFor="comment">Commentaire</label></h3>
      <TextArea
        id="comment"
        placeholder="Écrivez votre commentaire"
        value={comment || ''}
        onChange={({target}) => setComment(target.value.slice(0, MAX_COMMENT_LENGTH))}
        autoSize={{minRows: 3}}
      />
      <p css={{float: 'right'}}>
        {(comment ?? '').length} / {MAX_COMMENT_LENGTH}
      </p>
    </Row>
  );
};

export default memo(Comment);
