import {css} from '@emotion/core';
import {Form, Row, Col} from 'antd';
import React, {FC, memo} from 'react';
import {Redirect} from 'react-router-dom';
import {useQuote} from '../../contexts/Quote';
import {useUser} from '../../contexts/Session';
import countries from '../util/countries.json';
import ShipmentForm from './ShipmentForm';

const Shipment: FC = () => {
  const user = useUser();
  const [quote, setQuote] = useQuote();

  if (!user) {
    return <Redirect to="/login" push={true} />;
  }

  const {shippingAddress} = quote;

  const WrappedShipmentForm = Form.create({name: 'shipmentForm'})((props) => (
    <ShipmentForm
      {...props}
      user={user}
      quote={quote}
      onSubmit={(data) => setQuote({...quote, ...data})}
    />
  ));

  return (
    <div>
      <h3>Livraison</h3>
      <Row type="flex" gutter={20}>
        <Col
          css={css`
            margin-top: 10px;
            p {
              margin: 0;
            }
          `}
        >
          <p>{shippingAddress?.raisonSociale}</p>
          <p>{shippingAddress?.adresse}</p>
          <p>
            {shippingAddress?.codePostal} {shippingAddress?.ville}
          </p>
          <p>
            <span
              className={`flag-icon flag-icon-${(
                shippingAddress?.isoCountry ?? 'FR'
              ).toLowerCase()}`}
              css={{marginRight: 5}}
            />
            {
              countries[
                (shippingAddress?.isoCountry as keyof typeof countries) ?? 'FR'
              ]
            }
          </p>
        </Col>
        <Col css={{maxWidth: '100%'}}>
          <WrappedShipmentForm />
        </Col>
      </Row>
    </div>
  );
};

export default memo(Shipment);
