import {css} from '@emotion/core';
import {Layout} from 'antd';
import React, {FC, memo, useState} from 'react';
import DocumentTitle from 'react-document-title';
import {hot} from 'react-hot-loader/root';
import {BrowserRouter, Route} from 'react-router-dom';
import QuoteContext, {
  BaseQuoteData,
  getBaseQuoteOptions,
} from '../contexts/Quote';
import SessionContext from '../contexts/Session';
import Footer from '../Footer';
import Header from '../Header';
import Logout from '../Logout';
import {WakandaService} from '../util/fetch-wakanda';
import {cssUtils} from '../util/render';
import './App.scss';
import Landing from './Landing';
import Login from './Login';
import Orders from './Orders';
import Quote from './Quote';
import Settings from './Settings';
import Summary from './Summary';

const {Content} = Layout;

const baseQuote: BaseQuoteData = getBaseQuoteOptions();
const layoutCss = css`
  &.ant-layout {
    overflow: hidden;
    > .ant-layout-content {
      overflow: auto;
      padding: 10px 0;
    }
  }
`;

const App: FC = () => {
  const [quote, setQuote] = useState<BaseQuoteData>(baseQuote);
  const [session, setSession] = useState<WakandaService>(new WakandaService());
  session.resetService = () => setSession(new WakandaService());

  const baseDocumentTitle = process.env.REACT_APP_HEAD_TITLE || 'Store';

  return (
    <SessionContext.Provider value={[session, setSession]}>
      <QuoteContext.Provider value={[quote, setQuote]}>
        <DocumentTitle title={baseDocumentTitle}>
          <Layout css={[cssUtils.heightP(), layoutCss]}>
            <BrowserRouter>
              <Header />
              <Content>
                <Route exact path="/">
                  <DocumentTitle
                    title={[baseDocumentTitle, 'Accueil'].join(' - ')}
                  >
                    <Landing />
                  </DocumentTitle>
                </Route>
                <Route path="/login">
                  <DocumentTitle
                    title={[baseDocumentTitle, 'Connexion'].join(' - ')}
                  >
                    <Login />
                  </DocumentTitle>
                </Route>
                <Route path="/quote">
                  <DocumentTitle
                    title={[baseDocumentTitle, 'Commande'].join(' - ')}
                  >
                    <Quote parentPath="/quote" />
                  </DocumentTitle>
                </Route>
                <Route exact path="/orders">
                  <DocumentTitle
                    title={[baseDocumentTitle, 'Suivi commandes'].join(' - ')}
                  >
                    <Orders />
                  </DocumentTitle>
                </Route>
                <Route path="/orders/edit">
                  <DocumentTitle
                    title={[baseDocumentTitle, 'Modification commande'].join(' - ')}
                  >
                    <Quote parentPath="/orders/edit" />
                  </DocumentTitle>
                </Route>
                <Route path="/settings">
                  <DocumentTitle
                    title={[baseDocumentTitle, 'Paramètres'].join(' - ')}
                  >
                    <Settings />
                  </DocumentTitle>
                </Route>
                <Route path="/cart">
                  <DocumentTitle
                    title={[baseDocumentTitle, 'Panier'].join(' - ')}
                  >
                    <Summary />
                  </DocumentTitle>
                </Route>
              </Content>
              <Route path="/login">
                <Footer />
              </Route>
              <Route path="/logout">
                <Logout />
              </Route>
            </BrowserRouter>
          </Layout>
        </DocumentTitle>
      </QuoteContext.Provider>
    </SessionContext.Provider>
  );
};

export default hot(memo(App)) as FC;
