import {css} from '@emotion/core';
import {Card, Col, Row} from 'antd';
import {darken} from 'polished';
import React, {ReactElement} from 'react';
import {BaseProduct} from '../../contexts/Quote';
import {colors, typedMemo} from '../../util/render';

const categoryCss = css`
  --selected-color: white;
  --hover-border-color: ${darken(0.3, 'white')};

  --selected-background-color: ${colors.primary};
  --selected-hover-border-color: ${darken(0.2, colors.primary)};

  --disabled-background-color: #e1e1e1;

  .ant-card-cover {
    height: 275px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    img {
      height: 100%;
      width: auto;
      object-fit: contain;
      padding: 0 5px;
    }
  }

  :hover {
    border-color: var(--hover-border-color);
  }

  &.selected {
    .ant-card-body {
      color: var(--selected-color);
      background-color: var(--selected-background-color);
    }

    :hover {
      border-color: var(--selected-hover-border-color);
    }
  }

  &.disabled {
    pointer-events: none;
    cursor: default;
    .ant-card-body {
      background-color: var(--disabled-background-color);
    }
  }
`;

export interface ProductSelectionProps<T extends BaseProduct> {
  readonly value?: T;
  readonly products: T[];
  readonly onSelect?: (product: T) => void;
}

function ProductSelection<T extends BaseProduct>({
  value,
  products,
  onSelect,
}: ProductSelectionProps<T>): ReactElement {
  return (
    <Row type="flex" gutter={5}>
      {products.map((product, index) => (
        <Col
          span={12}
          md={8}
          xl={6}
          xxl={products.length > 4 ? 4 : 6}
          key={index}
        >
          <Card
            hoverable
            cover={<img alt={product.label} src={product.img} />}
            css={categoryCss}
            className={[
              value === product ? 'selected' : '',
              product.disabled ? 'disabled' : '',
            ]
              .filter((className) => !!className)
              .join(' ')}
            onClick={() => !product.disabled && onSelect && onSelect(product)}
          >
            <p css={product.subLabel ? {margin: 0} : null}>{product.label}</p>
            {product.subLabel ? <p><em>{product.subLabel}</em></p> : null}
          </Card>
        </Col>
      ))}
    </Row>
  );
}

export default typedMemo(ProductSelection);
