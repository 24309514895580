import {Col, Row, Modal, Result, Button} from 'antd';
import React, {FC, memo, useEffect, Fragment, useState, ReactNode} from 'react';
import {cssUtils} from '../../util/render';
import Buttons from './Buttons';
import Cart from './Cart';
import Comment from './Comment';
import Patient from './Patient';
import Prints from './Prints';
import Shipment from './Shipment';
import Terms from './Terms';
import {finalize, getArticles} from './util';
import {useQuote, useLines, getBaseQuoteOptions} from '../../contexts/Quote';
import {useUser, useSession} from '../../contexts/Session';
import {User, UserType} from '../../util/fetch-wakanda';
import {Link, Redirect} from 'react-router-dom';
import DocumentModal from './DocumentModal';
import {Order} from '../Orders/data';
import StickerModal from './StickerModal';
import TechnicalModal from './TechnicalModal';

const Summary: FC = () => {
  const [quote, setQuote] = useQuote();
  const [lines, setLines] = useLines();
  const user = useUser();
  const session = useSession();

  const [modalVisible, setModalVisible] = useState(false);
  const [loading, setLoading] = useState(false);

  const [order, setOrder] = useState<Order>();

  const prepareLines = async (usr: User): Promise<void> => {
    await Promise.all(
      lines.map(async (line) => {
        await getArticles(quote, line, session, usr);
      }),
    );

    setLines(lines);
  };

  useEffect(() => {
    if (!user) {
      return;
    }

    (async () => {
      await prepareLines(user);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, quote.transfer.method]);

  const onFinalize = async (): Promise<void> => {
    if (!user) {
      return;
    }

    setLoading(true);
    const result = await finalize(session, quote, lines, user);

    if (result) {
      setOrder(result);
      setModalVisible(true);
    }
    setLoading(false);
  };

  if (!user) {
    return <Redirect to="/login" push={true} />;
  }

  const emptyQuote = (): void => {
    setQuote(getBaseQuoteOptions());
  };

  const orderButtons: ReactNode[] = [];
  if (order) {
    if (user.type !== UserType.CLIENT) {
      orderButtons.push(<StickerModal key={0} order={order} large={true} />);
      orderButtons.push(<TechnicalModal key={1} order={order} large={true} />);
    } else {
      orderButtons.push(<DocumentModal key={0} order={order} large={true} />);
    }
  }

  return (
    <Fragment>
      <Row type="flex" justify="center" css={cssUtils.heightP()}>
        <Col span={20} xl={16}>
          <h1 css={{marginBottom: 20}}>Panier</h1>

          <Patient patientOnly={user.type === UserType.CLIENT} />
          <Cart css={{margin: '10px 0'}} />
          <Row css={{margin: '10px 0'}}>
            <Col span={user.type === UserType.CLIENT ? 8 : 24}>
              <Shipment />
            </Col>
            {user.type === UserType.CLIENT ? (
              <Col span={16}>
                <Prints />
              </Col>
            ) : null}
          </Row>
          <Comment />
          {user?.type === UserType.CLIENT ? <Terms /> : null}
          <Buttons loading={loading} onFinalize={onFinalize} />
        </Col>
      </Row>
      <Modal
        title="Commande réussie"
        visible={modalVisible}
        closable={false}
        footer={null}
        width={720}
      >
        <Result
          status="success"
          title="Votre commande a été enregistrée avec succès !"
          extra={
            <Fragment>
              {orderButtons}
              <Link to="/orders">
                <Button
                  type="primary"
                  onClick={emptyQuote}
                  style={{marginLeft: 8}}
                >
                  Suivi des commandes
                </Button>
              </Link>
            </Fragment>
          }
        />
      </Modal>
    </Fragment>
  );
};

export default memo(Summary);
