import {createContext, useContext} from 'react';
import {User, WakandaService} from '../util/fetch-wakanda';
import {ValueDispatcher} from '../util/render';

const SessionContext = createContext<ValueDispatcher<WakandaService>>([
  new WakandaService(),
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  () => {},
]);

export const useSession: () => WakandaService = () =>
  useContext(SessionContext)[0];

export const useRestore = (): [boolean, boolean] => useSession().useRestore();
export const useUser = (): User | null => {
  const user = useSession().user;
  /*const restore = */ useRestore();
  /*const history = useHistory();*/

  /*if (restore &&  (!user || user.ID === '00000000000000000000000000000000')) {
    history.replace('/login');
  }*/

  return user && !user.isGuest ? user : null;
};

export default SessionContext;
