import {Checkbox, Row} from 'antd';
import React, {FC, memo} from 'react';
import {useTerms} from '../../contexts/Quote';

const termsUrl =
  'https://surdis.fr/bo/assets/pdfs/cgv_surdifuse_2018-terms-and-conditions.pdf';

const Terms: FC = () => {
  const [terms, setTerms] = useTerms();

  return (
    <Row css={{marginBottom: 10, textAlign: 'right'}}>
      <Checkbox checked={terms} onChange={({target}) => setTerms(target.checked)}>
        <a href={termsUrl} target="_blank" rel="noopener noreferrer">
          En cliquant, vous acceptez les Conditions d&apos;utilisation
        </a>
      </Checkbox>
    </Row>
  );
};

export default memo(Terms);
