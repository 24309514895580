import {css} from '@emotion/core';
import {Col, Row, Tooltip} from 'antd';
import {lighten, readableColor} from 'polished';
import React, {FC} from 'react';
import {Link, useLocation} from 'react-router-dom';
import {TBoolean, useCurrentSelectedOptions} from '../../contexts/Quote';
import {
  borderContainerCss,
  colors,
  cssOption,
  getEmptyRequiredGroups,
  getMissingEarsRequiredGroups,
  getSelectedGroups,
} from '../../util/render';
import {getSelectedEars, Group, SELECTED_EARS} from './static';

const cssLink = css`
  --color: ${readableColor(lighten(0.3, colors.primary))};
  --background-color: ${lighten(0.3, colors.primary)};
  --selected-background-color: ${colors.primary};
  --selected-color: ${readableColor(colors.primary)};
  --filled-background-color: ${lighten(0.1, colors.primary)};

  color: black;
  :hover {
    color: black;
  }

  .ant-col {
    background-color: var(--background-color);
    color: var(--color);
    cursor: pointer;
  }

  &.selected .ant-col {
    cursor: default;
  }
  &.disabled .ant-col {
    cursor: not-allowed;
  }

  &.selected .ant-col {
    background-color: var(--selected-background-color);
    color: var(--selected-color);
  }
  &.filled .ant-col {
    background-color: var(--filled-background-color);
  }
  &.disabled .ant-col {
    filter: grayscale(100%);
  }
  &.required .ant-col::after {
    content: '*';
  }
`;

interface Props {
  groups: Group[];
  ears: TBoolean;
}

export const OptionsGroupPicker: FC<Props> = ({groups, ears}) => {
  const {pathname} = useLocation();
  const pathTokens = pathname.split('/');
  const [selectedOptions] = useCurrentSelectedOptions();

  const selectedEars = getSelectedEars(ears);

  const missingRequiredGroups = [
    ...getEmptyRequiredGroups(selectedOptions, groups),
    ...getMissingEarsRequiredGroups({
      ears,
      groups,
      selectedOptions,
    }).flat(),
  ];
  const selectedGroups = Object.keys(getSelectedGroups(selectedOptions));

  return (
    <div css={[borderContainerCss]}>
      {groups.map(({name, label, disabled, required}, index) => (
        <Row key={index}>
          <Link
            to={name}
            css={cssLink}
            onClick={disabled ? (event) => event.preventDefault() : undefined}
            className={[
              'option-group-link',
              disabled ? 'disabled' : '',
              pathTokens[pathTokens.length - 1] === name ? 'selected' : '',
              required ? 'required' : '',
              missingRequiredGroups.find(({name: _name}) => _name === name)
                ? 'required-missing'
                : '',
              selectedGroups.includes(name) ? 'filled' : '',
            ]
              .filter((className) => !!className)
              .join(' ')}
          >
            <Tooltip
              placement="top"
              title={
                disabled
                  ? selectedEars === SELECTED_EARS.NONE
                    ? 'Sélectionnez au moins une oreille'
                    : missingRequiredGroups.length
                    ? 'Sélectionnez une valeur pour les options obligatoires précédentes'
                    : 'Aucune option disponible'
                  : null
              }
            >
              <Col span={24} css={cssOption}>
                {label}
              </Col>
            </Tooltip>
          </Link>
        </Row>
      ))}
    </div>
  );
};
