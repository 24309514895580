import {css, SerializedStyles} from '@emotion/core';
import {Badge, Col, Icon, Layout, Menu, Row} from 'antd';
import React, {FC, memo, ReactNode} from 'react';
import {NavLink, useLocation} from 'react-router-dom';
import {useValidLineCount} from './contexts/Quote';
import {useUser} from './contexts/Session';
import Logo, {headerLogoCss} from './Logo';
import {User, UserType} from './util/fetch-wakanda';
import {matchRoutePath} from './util/path';

const {Header: AntHeader} = Layout;

const headerCss = css`
  user-select: none;
  @media (max-width: 992px) {
    padding: 0 20px;
    .ant-badge-count {
      min-width: 15px;
      height: 15px;
      font-size: 10px;
      line-height: 12px;
    }
  }
  .anticon {
    margin-right: 0;
  }
  @media (min-width: 992px) {
    .ant-col .ant-col:last-of-type {
      .anticon {
        font-size: 23px;
      }
    }
  }
`;

interface HeaderMenu {
  path: string;
  label: string | ReactNode;
  style?: SerializedStyles;
  disabled?: boolean;
}

const getHeaderMenus = (lineCount = 0, user: User | null = null): HeaderMenu[][] => [
  [
    {
      path: '/',
      label: (
        <Row type="flex">
          <Col>
            <Icon type="home" />
          </Col>
          <Col xs={0} md={1}>
            <span css={{marginLeft: 10}}>Accueil</span>
          </Col>
        </Row>
      ),
    },
    {
      path: '/quote',
      label: (
        <Row type="flex">
          <Col>
            <Icon type="plus" />
          </Col>
          <Col xs={0} md={1}>
            <span css={{marginLeft: 10}}>Nouvelle commande</span>
          </Col>
        </Row>
      ),
    },
    {
      path: '/orders',
      label: (
        <Row type="flex">
          <Col>
            <Icon type="unordered-list" />
          </Col>
          <Col xs={0} md={1}>
            <span css={{marginLeft: 10}}>Suivi commandes</span>
          </Col>
        </Row>
      ),
    },
  ],
  [
    ...(user && user.type === UserType.CLIENT ? [{
      path: '/settings',
      label: <Icon type="setting" />,
    }] : []),
    {
      path: '/cart',
      disabled: !lineCount,
      label: (
        <Badge count={lineCount} showZero>
          <Icon type="shopping-cart" />
        </Badge>
      ),
    },
    {
      path: '/logout',
      label: <Icon type="logout" />,
    },
  ],
];

const Header: FC = () => {
  const user = useUser();
  const location = useLocation();
  const lineCount = useValidLineCount();
  const headerMenus = getHeaderMenus(lineCount, user);

  const {path: currentPath} = matchRoutePath(
    location.pathname,
    headerMenus.flat(),
  ) || {
    path: '/',
  };

  return (
    <AntHeader css={headerCss}>
      <Row type="flex" gutter={20}>
        <Col>
          <Logo style={headerLogoCss} />
        </Col>
        <Col css={{flex: 1}}>
          <Row type="flex" justify="space-between">
            {headerMenus.map((menus, index) => (
              <Col key={index}>
                <Menu
                  theme="dark"
                  mode="horizontal"
                  selectedKeys={[currentPath]}
                  css={{lineHeight: '64px'}}
                >
                  {menus.map(({path, label, style, disabled}) => (
                    <Menu.Item key={path} css={style} disabled={disabled}>
                      {path === currentPath ? (
                        label
                      ) : (
                        <NavLink to={path}>{label}</NavLink>
                      )}
                    </Menu.Item>
                  ))}
                </Menu>
              </Col>
            ))}
          </Row>
        </Col>
      </Row>
    </AntHeader>
  );
};

export default memo(Header);
