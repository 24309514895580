import React, {FC, memo, useState, Fragment} from 'react';
import jsPDF from 'jspdf';
import {Order, Client} from '../Orders/data';
import {WakandaService} from '../../util/fetch-wakanda';
import {useSession} from '../../contexts/Session';
import {Modal, Button, Tooltip} from 'antd';
import {loadLogo, loadQRCode} from './DocumentModal';
import {Article, getLabelFromRule} from './util';
import moment from 'moment';
import {addFont} from './roboto';
import {
  eventRules,
  eventLabels,
  tubeRules,
  tubeLabels,
  optionTubeRules,
  optionTubeLabels,
  optionRules,
  optionLabels,
  cordColorRules,
  cordColorLabels,
} from '../Options/data';

const generate = async (
  session: WakandaService,
  commande: Order,
): Promise<string> => {
  const client =
    (await session.fetch<Client>('Clients', 'getClient', {
      // eslint-disable-next-line @typescript-eslint/camelcase
      id_client: commande.id_client.__KEY,
    })) || null;

  if (!client) {
    return '';
  }

  const OGArtID = commande.details.OG?.article;
  const ODArtID = commande.details.OD?.article;

  const oreille: string[] = [];
  const articleIDs: string[] = [];
  if (OGArtID === ODArtID && ODArtID) {
    oreille.push('DG');
    articleIDs.push(ODArtID);
  } else {
    if (ODArtID) {
      oreille.push('D');
      articleIDs.push(ODArtID);
    }
    if (OGArtID) {
      oreille.push('G');
      articleIDs.push(OGArtID);
    }
  }

  const articles = await session.fetch<Article[]>(
    'Articles',
    'getArticlesFromIDs',
    {ids: articleIDs},
  );
  if (!articles) {
    return '';
  }

  const img = await loadLogo();

  const doc = new jsPDF({
    unit: 'pt',
    format: [102.047, 252.283],
  });

  addFont(doc);
  doc.setTextColor('#000000');

  for (const article of articles) {
    const index = articles.indexOf(article);
    const detailsIndex = oreille[index] === 'G' ? 'OG' : 'OD';
    const details = commande.details[detailsIndex];
    if (!details) {
      continue;
    }

    if (index > 0) {
      doc.addPage();
    }

    let id = commande.ID;
    if (oreille[index] === 'D' && commande.details.uuidBis) {
      id = commande.details.uuidBis;
    }
    const qr = await loadQRCode(
      `${id}|${commande.numeroBon}|${oreille[index]}|${client.code}|${commande.patient}|${article.code}`,
    );

    // logo
    doc.addImage(img, 'PNG', 70, -10, 30, 25, undefined, undefined, 270);

    doc.setFontSize(8);
    doc.setFont('roboto', 'bold');

    doc.text('N° : ' + commande.numeroBon, 90, 55, {angle: 270});

    doc.setFont('roboto', 'normal');

    doc.text(oreille[index], 90, 160, {angle: 270});

    doc.setFontSize(7);
    doc.setFont('roboto', 'bold');

    doc.text(
      'Client : ' + [client.code, client.raisonSociale].join(' - '),
      60,
      17,
      {angle: 270},
    );
    doc.text(
      'CP : ' + [commande.livr_codePostal, commande.livr_ville].join(' '),
      50,
      17,
      {angle: 270},
    );
    doc.text('Patient : ' + commande.patient, 40, 17, {angle: 270});

    doc.setFont('roboto', 'normal');

    doc.text(
      'Article : ' +
        [article.libelle, details.couleur, details.finition].join(' '),
      25,
      17,
      {angle: 270},
    );

    let bague = '';
    if (details.bague) {
      const bagueArts = await session.fetch<Article[]>(
        'Articles',
        'getArticlesFromIDs',
        {
          ids: [details.bague],
        },
      );
      if (bagueArts && bagueArts.length > 0) {
        bague = bagueArts[0].code;
      }
    }
    let event = '';
    if (details.event) {
      event = getLabelFromRule(details.event, eventRules, eventLabels);
    }
    if (details.eventDiam || details.eventSurMesure) {
      event = 'Event ' + (details.eventDiam || details.eventSurMesure) + ' mm';
    }
    let tube = '';
    if (details.tube) {
      tube = getLabelFromRule(details.tube, tubeRules, tubeLabels);
    }
    const optionTubeArr: string[] = (
      details.optionTube || []
    ).map((opt): string =>
      getLabelFromRule(opt, optionTubeRules, optionTubeLabels),
    );
    let aquason = '';
    if (details.optionAquason && details.optionAquason === 'OPT_option01') {
      aquason = 'Flotteur';
    }
    let ep2 = '';
    if (details.optionEP2) {
      ep2 = getLabelFromRule(details.optionEP2, optionRules, optionLabels);
    }
    let cordonEp2 = '';
    if (details.optionCordonEP2) {
      cordonEp2 =
        'Cordon ' +
        getLabelFromRule(
          details.optionCordonEP2,
          cordColorRules,
          cordColorLabels,
        );
    }
    let gravureEp2 = '';
    if (
      details.optionGravureEP2 &&
      details.optionGravureEP2 === 'OPT_option06'
    ) {
      gravureEp2 = 'Gravure';
    }
    const optionObturateurPasstopTArr = (details.optionObturateur || [])
      .concat(details.optionPasstopT || [])
      .map((opt) => getLabelFromRule(opt, optionRules, optionLabels));
    let optionTir = '';
    if (details.optionTir && details.optionTir === 'OPT_option01') {
      optionTir = 'Camouflage';
    }
    let vernis = '';
    if (
      details.vernisAntibacterien &&
      details.vernisAntibacterien === 'OPT_foption01'
    ) {
      vernis = 'Vernis antibactérien';
    }

    doc.text(
      'Options : ' +
        [
          bague,
          event,
          tube,
          ...optionTubeArr,
          aquason,
          vernis,
          ep2,
          cordonEp2,
          gravureEp2,
          ...optionObturateurPasstopTArr,
          details.optionPianissimo,
          optionTir,
        ]
          .filter((opt) => opt)
          .join(', '),
      14,
      17,
      {
        angle: 270,
        maxWidth: 225,
      },
    );

    doc.setFillColor('white');
    doc.rect(4, 155, 8, 252 - 155, 'F');

    const mom = moment(commande.dateSaisie);
    doc.text(
      mom.format('MM/YYYY') +
        (article.moisGarantie > 0
          ? '  Garantie : ' + article.moisGarantie + ' mois'
          : ''),
      5,
      160,
      {angle: 270},
    );

    // add qr code last if text is too long
    doc.addImage(qr, 'PNG', 30, 110, 70, 70, undefined, undefined, 270);
  }

  return doc.output('bloburi');
};

interface Props {
  large?: boolean;
  disabled?: boolean;
  order: Partial<Order>;
}

const DocumentModal: FC<Props> = ({large, disabled, order}) => {
  const session = useSession();

  const [dataUri, setDataUri] = useState('');

  const hideModal = (): void => {
    setDataUri('');
  };

  const getSticker = async (): Promise<void> => {
    const dataUri = await generate(session, order as Order);

    setDataUri(dataUri);
  };

  return (
    <Fragment>
      <Tooltip
        title={!disabled ? "Télécharger l'étiquette de la commande" : null}
      >
        <Button
          disabled={disabled}
          shape={large ? undefined : 'circle'}
          icon="barcode"
          style={{margin: '0 8px'}}
          onClick={getSticker}
        >
          {large ? 'Etiquette' : null}
        </Button>
      </Tooltip>
      <Modal
        width="90vw"
        visible={dataUri.length > 0}
        closable={false}
        footer={[
          <a key="download" href={dataUri} download={`${order.patient}.pdf`}>
            <Button>Télécharger</Button>
          </a>,
          <Button
            key="close"
            type="primary"
            onClick={hideModal}
            style={{marginLeft: 8}}
          >
            Fermer
          </Button>,
        ]}
      >
        <div style={{height: '80vh'}}>
          {dataUri.length > 0 ? (
            <iframe
              title="PDF"
              src={`/pdf/web/viewer.html?file=${dataUri}`}
              width="100%"
              height="100%"
              style={{border: 0}}
            />
          ) : null}
        </div>
      </Modal>
    </Fragment>
  );
};

export default memo(DocumentModal);
