import {mix} from 'polished';

const commonRules = {
  groovedBlowhole: 'DBC_Event_rainure',
  antibacterialVarnish: 'OPT_foption01',
};

export enum PRODUCT_CATEGORY {
  CLASSIQUE = 'Classique',
  DEPORTE = 'Deporte',
  PROTECTION = 'Protection',
  CHASSE_TIR = 'Chasse & Tir',
}

export enum CLASSIQUE_SHAPE {
  CANULE = 'Classique_Canule',
  CANULE_EPAULEMENT = 'Classique_CanuleEpaulement',
  COQUILLE = 'Classique_Coquille',
  DEMI_CONQUE = 'Classique_DemiConque',
  PHANTOMOLD = 'Classique_Phantomold',
  SILHOUETTE = 'Classique_Silhouette',
  IROS = 'Classique_Iros',
}
export enum CLASSIQUE_CANULE_MATERIAL {
  RESINE_ACRYLIQUE = 'Classique_Canule_ResineAcrylique',
  SILICON_40 = 'Classique_Canule_Silicon40',
  SILICON_70 = 'Classique_Canule_Silicon70',
  THERMOTEC = 'Classique_Canule_Thermotec',
}
export enum CLASSIQUE_CANULE_EPAULEMENT_MATERIAL {
  RESINE_ACRYLIQUE = 'Classique_CanuleEpaulement_ResineAcrylique',
  SILICON_40 = 'Classique_CanuleEpaulement_Silicon40',
  SILICON_70 = 'Classique_CanuleEpaulement_Silicon70',
  THERMOTEC = 'Classique_CanuleEpaulement_Thermotec',
}

export enum CLASSIQUE_COQUILLE_MATERIAL {
  RESINE_ACRYLIQUE = 'Classique_Coquille_ResineAcrylique',
  SILICON_40 = 'Classique_Coquille_Silicon40',
  SILICON_70 = 'Classique_Coquille_Silicon70',
  THERMOTEC = 'Classique_Coquille_Thermotec',
}

export enum CLASSIQUE_DEMI_CONQUE_MATERIAL {
  RESINE_ACRYLIQUE = 'Classique_DemiConque_ResineAcrylique',
  SILICON_40 = 'Classique_DemiConque_Silicon40',
  SILICON_70 = 'Classique_DemiConque_Silicon70',
  THERMOTEC = 'Classique_DemiConque_Thermotec',
}

export enum CLASSIQUE_PHANTOMOLD_MATERIAL {
  RESINE_ACRYLIQUE = 'Classique_Phantomold_ResineAcrylique',
  SILICON_40 = 'Classique_Phantomold_Silicon40',
  SILICON_70 = 'Classique_Phantomold_Silicon70',
  THERMOTEC = 'Classique_Phantomold_Thermotec',
}

export enum CLASSIQUE_SILHOUETTE_MATERIAL {
  RESINE_ACRYLIQUE = 'Classique_Silhouette_ResineAcrylique',
  SILICON_40 = 'Classique_Silhouette_Silicon40',
  SILICON_70 = 'Classique_Silhouette_Silicon70',
  THERMOTEC = 'Classique_Silhouette_Thermotec',
}

export enum CLASSIQUE_IROS_MATERIAL {
  RESINE_ACRYLIQUE = 'Classique_Iros_ResineAcrylique',
  SILICON_40 = 'Classique_Iros_Silicon40',
  SILICON_70 = 'Classique_Iros_Silicon70',
  THERMOTEC = 'Classique_Iros_Thermotec',
}

export enum DEPORTE_BRAND {
  SIEMENS = 'Deporte_Siemens',
  OTICON = 'Deporte_Oticon',
  BELTONE = 'Deporte_Beltone',
  WIDEX = 'Deporte_Widex',
  PHONAK = 'Deporte_Phonak',
  STARKEY = 'Deporte_Starkey',
  COQUE_INTRA = 'Deporte_CoqueIntra',
}
export enum DEPORTE_SHAPE {
  CANULE = 'Deporte_Canule',
  EPAULEMENT_COURT = 'Deporte_EpaulementCourt',
  EPAULEMENT_MOYEN = 'Deporte_EpaulementMoyen',
  EPAULEMENT_LONG = 'Deporte_EpaulementLong',
  DEMI_CONQUE = 'Deporte_DemiConque',
  COQUILLE = 'Deporte_Coquill',
  PHANTOMOLD = 'Deporte_Phantomold',
  SILOUETTE = 'Deporte_Silouette',
}
export enum DEPORTE_CANULE_MATERIAL {
  RESINE_DURE = 'Deporte_Canule_ResineDure',
  SILICON_70 = 'Deporte_Canule_Silicon70',
  THERMOTEC = 'Deporte_Canule_Thermotec',
}

export enum PROTECTION_SHAPE {
  EAR = 'Protection_Ear',
  AQUASON = 'Protection_Aquason',
  PASSTOP_EP2 = 'Protection_PasstopEP2',
  PIANISSIMO = 'Protection_Pianissimo',
  PASSTOP_T = 'Protection_PasstopT',
  OBTURATEUR = 'Protection_Obturateur',
  SOMMEIL = 'Protection_Sommeil',
  AQUASTOP = 'Protection_Aquastop',
}

export enum PROTECTION_PASSTOP_SHAPE {
  CONQUE = 'Protection_Passtop_Conque',
  CANULE = 'Protection_Passtop_Canule',
}

export enum PROTECTION_OBSTRUATEUR_SHAPE {
  CONQUE = 'Protection_Obturateur_Conque',
  CANULE = 'Protection_Obturateur_Canule',
}

export enum PROTECTION_OBTURATEUR_MATERIAL {
  SILICON_25 = 'Protection_Obturateur_Silicon25',
  SILICON_40 = 'Protection_Obturateur_Silicon40',
  THERMOTEC = 'Protection_Obturateur_Thermotec',
  RESINE_DURE = 'Protection_Obturateur_ResineDure',
}

export enum PROTECTION_SOMMEIL_SHAPE {
  CONQUE = 'Protection_Sommeil_Conque',
  CANULE = 'Protection_Sommeil_Canule',
}

export enum PROTECTION_AQUASTOP_SHAPE {
  CONQUE = 'Protection_Aquastop_Conque',
}

export enum PROTECTION_PASSTOPEP2_MATERIAL {
  RESINE_ACRYLIQUE = 'Protection_PasstopEP2_ResineAcrylique',
}

export enum CHASSETIR_SHAPE {
  PASSTOP_TIR_CHASSE = 'Chassetir_PasstopTirChasse',
  STOPGUN_E = 'Chassetir_StopgunE',
  STOPGUN_FLEX = 'Chassetir_StopgunFlex',
  STOPGUN = 'Chassetir_Stopgun',
}

export enum COLOR {
  COLORLESS = 'COLORLESS',
  ROSE = 'ROSE',
  BEIGE_FLESH = 'BEIGE_FLESH',
  BROWN = 'BROWN',
  DARK_BROWN = 'DARK_BROWN',

  BLACK_OPAQUE = 'BLACK_OPAQUE',
  WHITE_OPAQUE = 'WHITE_OPAQUE',
  RED_OPAQUE = 'RED_OPAQUE',
  BLUE_OPAQUE = 'BLUE_OPAQUE',
  GREEN_OPAQUE = 'GREEN_OPAQUE',
  YELLOW_OPAQUE = 'YELLOW_OPAQUE',
  PURPLE_OPAQUE = 'PURPLE_OPAQUE',
  ORANGE_OPAQUE = 'ORANGE_OPAQUE',

  COLORLESS_TRANSLUCENT = 'COLORLESS_TRANSLUCENT',
  BLUE_TRANSLUCENT = 'BLUE_TRANSLUCENT',
  ROSE_TRANSLUCENT = 'ROSE_TRANSLUCENT',
  GREEN_TRANSLUCENT = 'GREEN_TRANSLUCENT',
  BLACK_TRANSLUCENT = 'BLACK_TRANSLUCENT',
  BEIGE_FLESH_TRANSLUCENT = 'BEIGE_FLESH_TRANSLUCENT',
  BROWN_TRANSLUCENT = 'BROWN_TRANSLUCENT',

  LIGHT_BROWN_TRANSPARENT = 'LIGHT_BROWN_TRANSPARENT',
  LIGHT_BEIGE = 'LIGHT_BEIGE',

  PINK_FLUORESCENT = 'PINK_FLUORESCENT',

  OTHER = 'OTHER',

  HARD_RESIN_COLORLESS = 'HARD_RESIN_COLORLESS',
  HARD_RESIN_BEIGE_FLESH = 'HARD_RESIN_BEIGE_FLESH',

  ROSE_TRANSPARENT = 'ROSE_TRANSPARENT',
  BLUE_PHOSOPHORESCENT = 'BLUE_PHOSOPHORESCENT',
  RED_PHOSOPHORESCENT = 'RED_PHOSOPHORESCENT',
  ORANGE_PHOSOPHORESCENT = 'ORANGE_PHOSOPHORESCENT',
  YELLOW_PHOSOPHORESCENT = 'YELLOW_PHOSOPHORESCENT',
  GREEN_PHOSOPHORESCENT = 'GREEN_PHOSOPHORESCENT',
  PINK_PHOSOPHORESCENT = 'PINK_PHOSOPHORESCENT',
  WHITE = 'WHITE',
  BLACK = 'BLACK',

  BLUE_FOOD = 'BLUE_FOOD',
}

export enum CORD_COLOR {
  ORANGE = 'ORANGE',
  GREEN = 'GREEN',
  BLUE = 'BLUE',
  BLACK = 'BLACK',
}

export const colorLabels: {[key in COLOR]: string} = {
  [COLOR.COLORLESS]: 'Incolore',
  [COLOR.ROSE]: 'Rosé',
  [COLOR.BEIGE_FLESH]: 'Beige/Chair',
  [COLOR.BROWN]: 'Marron',
  [COLOR.BLACK_OPAQUE]: 'Noir opaque',
  [COLOR.WHITE_OPAQUE]: 'Blanc opaque',
  [COLOR.RED_OPAQUE]: 'Rouge opaque',
  [COLOR.BLUE_OPAQUE]: 'Bleu opaque',

  [COLOR.DARK_BROWN]: 'Marron foncé',
  [COLOR.GREEN_OPAQUE]: 'Vert opaque',
  [COLOR.YELLOW_OPAQUE]: 'Jaune opaque',
  [COLOR.PURPLE_OPAQUE]: 'Violet opaque',
  [COLOR.ORANGE_OPAQUE]: 'Orange opaque',
  [COLOR.BLUE_TRANSLUCENT]: 'Bleu translucide',
  [COLOR.LIGHT_BROWN_TRANSPARENT]: 'Marron clair transparent',
  [COLOR.PINK_FLUORESCENT]: 'Rose fluo',
  [COLOR.BEIGE_FLESH_TRANSLUCENT]: 'Beige/Chair translucide',
  [COLOR.BROWN_TRANSLUCENT]: 'Marron translucide',

  [COLOR.COLORLESS_TRANSLUCENT]: 'Incolore translucide',
  [COLOR.ROSE_TRANSLUCENT]: 'Rosé translucide',
  [COLOR.OTHER]: 'Autre',

  [COLOR.GREEN_TRANSLUCENT]: 'Vert translucide',
  [COLOR.BLACK_TRANSLUCENT]: 'Noir translucide',

  [COLOR.HARD_RESIN_COLORLESS]: 'Résine dure Incolore',
  [COLOR.HARD_RESIN_BEIGE_FLESH]: 'Résine dure Beige/Chair',

  [COLOR.ROSE_TRANSPARENT]: 'Rosé Transparent',
  [COLOR.BLUE_PHOSOPHORESCENT]: 'Bleu phosphorescent',
  [COLOR.RED_PHOSOPHORESCENT]: 'Rouge phosphorescent',
  [COLOR.ORANGE_PHOSOPHORESCENT]: 'Orange phosphorescent',
  [COLOR.YELLOW_PHOSOPHORESCENT]: 'Jaune phosphorescent',
  [COLOR.GREEN_PHOSOPHORESCENT]: 'Vert phosphorescent',
  [COLOR.PINK_PHOSOPHORESCENT]: 'Rose phosphorescent',
  [COLOR.WHITE]: 'Blanc',
  [COLOR.BLACK]: 'Noir',

  [COLOR.LIGHT_BEIGE]: 'Beige clair',

  [COLOR.BLUE_FOOD]: 'Bleu agro',
};

export const cordColorLabels: {[key in CORD_COLOR]: string} = {
  [CORD_COLOR.ORANGE]: 'Orange',
  [CORD_COLOR.GREEN]: 'Vert',
  [CORD_COLOR.BLUE]: 'Bleu',
  [CORD_COLOR.BLACK]: 'Noir',
};
export const cordColorRules: {[key in CORD_COLOR]: string} = {
  [CORD_COLOR.ORANGE]: 'OPT_option02_01',
  [CORD_COLOR.GREEN]: 'OPT_option02_02',
  [CORD_COLOR.BLUE]: 'OPT_option02_03',
  [CORD_COLOR.BLACK]: 'OPT_option02_04',
};

export const colorHex: {[key in COLOR]: string} = {
  [COLOR.COLORLESS]: '#ffffff',
  [COLOR.ROSE]: '#ffc1cb',
  [COLOR.BEIGE_FLESH]: '#f1c27d',
  [COLOR.BEIGE_FLESH_TRANSLUCENT]: '#f1c27d',
  [COLOR.BROWN]: '#bf6000',
  [COLOR.BROWN_TRANSLUCENT]: '#bf6000',
  [COLOR.BLACK_OPAQUE]: '#000000',
  [COLOR.WHITE_OPAQUE]: '#ffffff',
  [COLOR.RED_OPAQUE]: '#FF3C44',
  [COLOR.BLUE_OPAQUE]: '#2922ff',
  [COLOR.DARK_BROWN]: '#964b00',
  [COLOR.GREEN_OPAQUE]: '#2BCF29',
  [COLOR.YELLOW_OPAQUE]: '#dcc600',
  [COLOR.PURPLE_OPAQUE]: '#b800ff',
  [COLOR.ORANGE_OPAQUE]: '#ff8500',
  [COLOR.BLUE_TRANSLUCENT]: mix(0.5, '#2922ff', 'white'),
  [COLOR.LIGHT_BROWN_TRANSPARENT]: mix(0.5, '#eb9e47', 'white'),
  [COLOR.PINK_FLUORESCENT]: '#ff00ff',

  [COLOR.COLORLESS_TRANSLUCENT]: mix(0.5, '#ffffff', 'white'),
  [COLOR.ROSE_TRANSLUCENT]: mix(0.5, '#ffc1cb', 'white'),
  [COLOR.OTHER]: '#ffffff',

  [COLOR.GREEN_TRANSLUCENT]: mix(0.5, '#2BCF29', 'white'),
  [COLOR.BLACK_TRANSLUCENT]: mix(0.5, '#000000', 'white'),

  [COLOR.HARD_RESIN_COLORLESS]: '#ffffff',
  [COLOR.HARD_RESIN_BEIGE_FLESH]: '#f1c27d',

  [COLOR.ROSE_TRANSPARENT]: mix(0.5, '#ffc1cb', 'white'),
  [COLOR.BLUE_PHOSOPHORESCENT]: '#0035ff',
  [COLOR.RED_PHOSOPHORESCENT]: '#ff002d',
  [COLOR.ORANGE_PHOSOPHORESCENT]: '#ff5d00',
  [COLOR.YELLOW_PHOSOPHORESCENT]: '#d5dc00',
  [COLOR.GREEN_PHOSOPHORESCENT]: '#0acf00',
  [COLOR.PINK_PHOSOPHORESCENT]: '#ff00ff',
  [COLOR.WHITE]: '#ffffff',
  [COLOR.BLACK]: '#000000',
  [COLOR.LIGHT_BEIGE]: '#f1c27d',

  [COLOR.BLUE_FOOD]: '#0073ff',
};

export const cordColorHex: {[key in CORD_COLOR]: string} = {
  [CORD_COLOR.ORANGE]: '#ff8500',
  [CORD_COLOR.GREEN]: '#2BCF29',
  [CORD_COLOR.BLUE]: '#2922ff',
  [CORD_COLOR.BLACK]: '#000000',
};

export enum FILTER {
  DB_15 = 'DB_15',
  DB_25 = 'DB_25',
  DB_30 = 'DB_30',

  SNR22 = 'SNR22',
  SNR24 = 'SNR24',

  SNR21 = 'SNR21',
  SNR26 = 'SNR26',
}

export const filterLabels: {[key in FILTER]: string} = {
  [FILTER.DB_15]: '-15 dB',
  [FILTER.DB_25]: '-25 dB',
  [FILTER.DB_30]: '-30 dB',

  [FILTER.SNR22]: 'T3 (industries de production) SNR22',
  [FILTER.SNR24]: 'T4 (industries lourdes) SNR24',

  [FILTER.SNR21]: 'C2 (usage privé) SNR21',
  [FILTER.SNR26]: 'C3 (industrie de conditionnement) SNR26',
};

export const required = {required: true};
export const unique = {unique: true};
export const requiredAndUnique = {...required, ...unique};

export enum SURFACE {
  ANTIBACTERIAL = 'ANTIBACTERIAL',
  MATTE = 'MATTE',
  SANDED = 'SANDED',
}
export const surfaceLabels: {[key in SURFACE]: string} = {
  [SURFACE.ANTIBACTERIAL]: 'Vernis antibactérien',
  [SURFACE.MATTE]: 'Vernis mat',
  [SURFACE.SANDED]: 'Sablage',
};
export const surfaceRules: {[key in SURFACE]?: string} = {
  [SURFACE.ANTIBACTERIAL]: commonRules.antibacterialVarnish,
};

export enum TYPE {
  PRODUCT_CATEGORY = 'category',
  SHAPE = 'shape',
  BRAND = 'brand',
  MATERIAL = 'material',
  COLOR = 'color',
  FILTER = 'filter',
  SURFACE = 'surface',
  EVENT = 'event',
  TUBE = 'tube',
  OPTION_TUBE = 'option_tube',
  SHOULDER = 'shoulder',
  COLOR_MARKER = 'color_marker',
  OTOSCAN = 'otoscan',
  EAR_BUD = 'ear_bud',
  EAR_BUD_ASSEMBLY = 'ear_bud_assembly',
  MISCELLANEOUS = 'miscellaneous',
  EARWAX_SHIELDING = 'earwax_shielding',
  FLOAT = 'float',
  CORD_COLOR = 'cord_color',
  OPTION = 'option',
  ENGRAVING = 'engraving',
  CAMO = 'CAMO',
  CORD = 'CORD',
}

export const getTypeLabel = (type: TYPE): string => {
  switch (type) {
    case TYPE.PRODUCT_CATEGORY:
      return 'Catégorie';
    case TYPE.SHAPE:
      return 'Forme';
    case TYPE.BRAND:
      return 'Marque';
    case TYPE.MATERIAL:
      return 'Matière';
    case TYPE.COLOR:
      return 'Couleur';
    case TYPE.FILTER:
      return 'Filtre';
    case TYPE.SURFACE:
      return 'Traitement de surface';
    case TYPE.EVENT:
      return 'Diamètre event';
    case TYPE.TUBE:
      return 'Connexions - Tube';
    case TYPE.OPTION_TUBE:
      return 'Options Tube';
    case TYPE.SHOULDER:
      return 'Longueur épaulement';
    case TYPE.COLOR_MARKER:
      return 'Repère de couleur';
    case TYPE.OTOSCAN:
      return 'OTOSCAN Otometrics';
    case TYPE.EAR_BUD:
      return 'Écouteur ou µ tube';
    case TYPE.EAR_BUD_ASSEMBLY:
      return 'Montage Écouteur';
    case TYPE.MISCELLANEOUS:
      return 'Divers';
    case TYPE.EARWAX_SHIELDING:
      return 'Pare-cérumen';
    case TYPE.FLOAT:
      return 'Options';
    case TYPE.CORD_COLOR:
      return 'Couleur cordon';
    case TYPE.OPTION:
      return 'Options';
    case TYPE.ENGRAVING:
      return 'Gravure';
    case TYPE.CAMO:
      return 'Option';
    case TYPE.CORD:
      return 'Option';
    default:
      return '%MISSING_LABEL%';
  }
};

export type Typed = {type?: TYPE};
export type Product =
  | CLASSIQUE_SHAPE
  | DEPORTE_BRAND
  | PROTECTION_SHAPE
  | CHASSETIR_SHAPE;
export type Shape =
  | DEPORTE_SHAPE
  | PROTECTION_PASSTOP_SHAPE
  | PROTECTION_OBSTRUATEUR_SHAPE
  | PROTECTION_SOMMEIL_SHAPE
  | PROTECTION_AQUASTOP_SHAPE;
export type Material =
  | CLASSIQUE_CANULE_MATERIAL
  | CLASSIQUE_CANULE_EPAULEMENT_MATERIAL
  | CLASSIQUE_COQUILLE_MATERIAL
  | CLASSIQUE_DEMI_CONQUE_MATERIAL
  | CLASSIQUE_PHANTOMOLD_MATERIAL
  | CLASSIQUE_SILHOUETTE_MATERIAL
  | CLASSIQUE_IROS_MATERIAL
  | DEPORTE_CANULE_MATERIAL
  | PROTECTION_PASSTOPEP2_MATERIAL
  | PROTECTION_OBTURATEUR_MATERIAL;

type CommonOption = {
  [key in
    | Material
    | COLOR
    | FILTER
    | PROTECTION_PASSTOP_SHAPE
    | PROTECTION_AQUASTOP_SHAPE]?: OptionDataContainer & Typed;
};

export const CommonOptionDataKeys: string[] = ['type', 'required', 'unique'];

export const BOOLEAN_VALUE = '__BOOLEAN_VALUE';
type BOOLEAN_VALUE_TYPE = typeof BOOLEAN_VALUE;

export interface OptionData<T = string> {
  required?: boolean;
  unique?: boolean;
  type: TYPE;
  values: T[];
}

export interface OptionDataContainer {
  options: OptionData[];
  required?: boolean;
  unique?: boolean;
}

export const materialLabels: {[key in Material]: string} = {
  [CLASSIQUE_CANULE_MATERIAL.RESINE_ACRYLIQUE]: 'Résine Acrylique',
  [CLASSIQUE_CANULE_MATERIAL.SILICON_40]: 'Silicone 40 ShA',
  [CLASSIQUE_CANULE_MATERIAL.SILICON_70]: 'Silicone 70 ShA',
  [CLASSIQUE_CANULE_MATERIAL.THERMOTEC]: 'Thermotec',
  [CLASSIQUE_CANULE_EPAULEMENT_MATERIAL.RESINE_ACRYLIQUE]: 'Résine acrylique',
  [CLASSIQUE_CANULE_EPAULEMENT_MATERIAL.SILICON_40]: 'Silicone 40 ShA',
  [CLASSIQUE_CANULE_EPAULEMENT_MATERIAL.SILICON_70]: 'Silicone 70 ShA',
  [CLASSIQUE_CANULE_EPAULEMENT_MATERIAL.THERMOTEC]: 'Thermotec',
  [CLASSIQUE_COQUILLE_MATERIAL.RESINE_ACRYLIQUE]: 'Résine Acrylique',
  [CLASSIQUE_COQUILLE_MATERIAL.SILICON_40]: 'Silicone 40 ShA',
  [CLASSIQUE_COQUILLE_MATERIAL.SILICON_70]: 'Silicone 70 ShA',
  [CLASSIQUE_COQUILLE_MATERIAL.THERMOTEC]: 'Thermotec',
  [CLASSIQUE_DEMI_CONQUE_MATERIAL.RESINE_ACRYLIQUE]: 'Résine Acrylique',
  [CLASSIQUE_DEMI_CONQUE_MATERIAL.SILICON_40]: 'Silicone 40 ShA',
  [CLASSIQUE_DEMI_CONQUE_MATERIAL.SILICON_70]: 'Silicone 70 ShA',
  [CLASSIQUE_DEMI_CONQUE_MATERIAL.THERMOTEC]: 'Thermotec',
  [CLASSIQUE_PHANTOMOLD_MATERIAL.RESINE_ACRYLIQUE]: 'Résine acrylique',
  [CLASSIQUE_PHANTOMOLD_MATERIAL.SILICON_40]: 'Silicone 40 ShA',
  [CLASSIQUE_PHANTOMOLD_MATERIAL.SILICON_70]: 'Silicone 70 ShA',
  [CLASSIQUE_PHANTOMOLD_MATERIAL.THERMOTEC]: 'Thermotec',
  [CLASSIQUE_SILHOUETTE_MATERIAL.RESINE_ACRYLIQUE]: 'Résine acrylique',
  [CLASSIQUE_SILHOUETTE_MATERIAL.SILICON_40]: 'Silicone 40 ShA',
  [CLASSIQUE_SILHOUETTE_MATERIAL.SILICON_70]: 'Silicone 70 ShA',
  [CLASSIQUE_SILHOUETTE_MATERIAL.THERMOTEC]: 'Thermotec',
  [CLASSIQUE_IROS_MATERIAL.RESINE_ACRYLIQUE]: 'Résine acrylique',
  [CLASSIQUE_IROS_MATERIAL.SILICON_40]: 'Silicone 40 ShA',
  [CLASSIQUE_IROS_MATERIAL.SILICON_70]: 'Silicone 70 ShA',
  [CLASSIQUE_IROS_MATERIAL.THERMOTEC]: 'Thermotec',
  [DEPORTE_CANULE_MATERIAL.RESINE_DURE]: 'Résine dure',
  [DEPORTE_CANULE_MATERIAL.SILICON_70]: 'Silicone 70 ShA',
  [DEPORTE_CANULE_MATERIAL.THERMOTEC]: 'Thermotec',
  [PROTECTION_PASSTOPEP2_MATERIAL.RESINE_ACRYLIQUE]: 'Résine acrylique',
  [PROTECTION_OBTURATEUR_MATERIAL.SILICON_25]: 'Silicone 25 ShA',
  [PROTECTION_OBTURATEUR_MATERIAL.SILICON_40]: 'Silicone 40 ShA',
  [PROTECTION_OBTURATEUR_MATERIAL.THERMOTEC]: 'Thermotec',
  [PROTECTION_OBTURATEUR_MATERIAL.RESINE_DURE]: 'Résine dure',
};

export const shapeLabels: {[key in Shape]: string} = {
  [DEPORTE_SHAPE.CANULE]: 'Canule',
  [DEPORTE_SHAPE.EPAULEMENT_COURT]: 'Épaulement Court',
  [DEPORTE_SHAPE.EPAULEMENT_MOYEN]: 'Épaulement Moyen',
  [DEPORTE_SHAPE.EPAULEMENT_LONG]: 'Épaulement Long',
  [DEPORTE_SHAPE.DEMI_CONQUE]: '1/2 conque',
  [DEPORTE_SHAPE.COQUILLE]: 'Coquille',
  [DEPORTE_SHAPE.PHANTOMOLD]: 'Phantomold',
  [DEPORTE_SHAPE.SILOUETTE]: 'Silhouette/Pince de crabe',
  [PROTECTION_PASSTOP_SHAPE.CONQUE]: 'Conque complète (PasStop T)',
  [PROTECTION_PASSTOP_SHAPE.CANULE]: 'Canule discrète (pasStop C)',

  [PROTECTION_OBSTRUATEUR_SHAPE.CONQUE]: 'Conque complète',
  [PROTECTION_OBSTRUATEUR_SHAPE.CANULE]: 'Canule',

  [PROTECTION_SOMMEIL_SHAPE.CONQUE]: 'Conque discrète (SweetNight/confort)',
  [PROTECTION_SOMMEIL_SHAPE.CANULE]:
    'Canule + poignée (Embout Sommeil/confort)',

  [PROTECTION_AQUASTOP_SHAPE.CONQUE]: 'Conque complète',
};

export const brandLabels: {[key in DEPORTE_BRAND]: string} = {
  [DEPORTE_BRAND.SIEMENS]: 'Siemens',
  [DEPORTE_BRAND.OTICON]: 'Oticon',
  [DEPORTE_BRAND.BELTONE]: 'Beltone',
  [DEPORTE_BRAND.WIDEX]: 'Widex',
  [DEPORTE_BRAND.PHONAK]: 'Phonak',
  [DEPORTE_BRAND.STARKEY]: 'Starkey',
  [DEPORTE_BRAND.COQUE_INTRA]: 'Coque intra',
};

type DeporteShape = {
  [key in DEPORTE_SHAPE]?: CommonOption & Typed;
};
export type ProductCategoryType = (
  | DeporteShape
  | CommonOption
  | OptionDataContainer
) &
  Typed;

export const isOptionData = (type: ProductCategoryType): type is OptionData =>
  !!(type as OptionData).values;

type ProductCategory = {
  [key in Product]?: ProductCategoryType;
};

export type OptionsTree = {
  [key in PRODUCT_CATEGORY]: ProductCategory & Typed;
};

export enum EVENT {
  D00_MM = 'D00_MM',
  D08_MM = 'D08_MM',
  D10_MM = 'D10_MM',
  D13_MM = 'D13_MM',
  D15_MM = 'D15_MM',
  D20_MM = 'D20_MM',
  D25_MM = 'D25_MM',
  D30_MM = 'D30_MM',
  MAX = 'MAX',
  SPIRAL = 'SPIRAL',
  SPIRAL_EXTERNAL = 'SPIRAL_EXTERNAL',
  GROOVED = 'GROOVED',
  CUSTOM = 'CUSTOM',
}
export const eventLabels: {[key in EVENT]: string} = {
  [EVENT.D00_MM]: '0 mm',
  [EVENT.D08_MM]: '0,8 mm',
  [EVENT.D10_MM]: '1 mm',
  [EVENT.D13_MM]: '1,3 mm',
  [EVENT.D15_MM]: '1,5 mm',
  [EVENT.D20_MM]: '2 mm',
  [EVENT.D25_MM]: '2,5 mm',
  [EVENT.D30_MM]: '3 mm',
  [EVENT.MAX]: 'Event max',
  [EVENT.SPIRAL]: 'Event spiral intérieur 3D exclusivement',
  [EVENT.SPIRAL_EXTERNAL]: 'Event spiral extérieur',
  [EVENT.GROOVED]: 'Event rainuré',
  [EVENT.CUSTOM]: 'Event à la demande',
};
export const eventRules: {[key in EVENT]?: string} = {
  [EVENT.D00_MM]: 'DBC_00',
  [EVENT.D08_MM]: 'DBC_08',
  [EVENT.D10_MM]: 'DBC_10',
  [EVENT.D13_MM]: 'DBC_13',
  [EVENT.D15_MM]: 'DBC_15',
  [EVENT.D20_MM]: 'DBC_20',
  [EVENT.D25_MM]: 'DBC_25',
  [EVENT.D30_MM]: 'DBC_30',
  [EVENT.MAX]: 'DBC_MX',
  [EVENT.SPIRAL]: 'DBC_Event_spiraleInt',
  [EVENT.SPIRAL_EXTERNAL]: 'DBC_Event_spiraleExt',
  [EVENT.GROOVED]: commonRules.groovedBlowhole,
};

export const ClassicEventOptions: OptionData<EVENT> = {
  ...requiredAndUnique,
  type: TYPE.EVENT,
  values: [
    EVENT.D00_MM,
    EVENT.D10_MM,
    EVENT.D15_MM,
    EVENT.D20_MM,
    EVENT.D25_MM,
    EVENT.D30_MM,
    EVENT.MAX,
    EVENT.SPIRAL,
    EVENT.GROOVED,
  ],
};

export const ClassicResineEventOptions: OptionData<EVENT> = {
  ...requiredAndUnique,
  type: TYPE.EVENT,
  values: [
    EVENT.D00_MM,
    EVENT.D10_MM,
    EVENT.D15_MM,
    EVENT.D20_MM,
    EVENT.D25_MM,
    EVENT.D30_MM,
    EVENT.MAX,
    EVENT.SPIRAL,
    EVENT.SPIRAL_EXTERNAL,
    EVENT.GROOVED,
  ],
};

export const PhantomoldEventOptions: OptionData<EVENT> = {
  ...requiredAndUnique,
  type: TYPE.EVENT,
  values: [
    EVENT.D00_MM,
    EVENT.D10_MM,
    EVENT.D15_MM,
    EVENT.D20_MM,
    EVENT.D25_MM,
    EVENT.D30_MM,
    EVENT.MAX,
    EVENT.SPIRAL,
    EVENT.GROOVED,
  ],
};

export const DeporteEventOptions: OptionData<EVENT> = {
  ...requiredAndUnique,
  type: TYPE.EVENT,
  values: [
    EVENT.D00_MM,
    EVENT.D08_MM,
    EVENT.D10_MM,
    EVENT.D13_MM,
    EVENT.D15_MM,
    EVENT.D20_MM,
    EVENT.D25_MM,
    EVENT.D30_MM,
    EVENT.MAX,
    EVENT.CUSTOM,
  ],
};

export const ResineDeporteEventOptions: OptionData<EVENT> = {
  ...requiredAndUnique,
  type: TYPE.EVENT,
  values: [
    EVENT.D00_MM,
    EVENT.D08_MM,
    EVENT.D10_MM,
    EVENT.D13_MM,
    EVENT.D15_MM,
    EVENT.D20_MM,
    EVENT.D25_MM,
    EVENT.D30_MM,
    EVENT.SPIRAL_EXTERNAL,
    EVENT.MAX,
    EVENT.CUSTOM,
  ],
};

export enum TUBE {
  D25_MM = 'D25_MM',
  D30_MM = 'D30_MM',
  D33_MM = 'D33_MM',
  WATERPROOF = 'WATERPROOF',
}
export const tubeLabels: {[key in TUBE]: string} = {
  [TUBE.D25_MM]: 'Tube diamètre 2,5mm',
  [TUBE.D30_MM]: 'Tube diamètre 3 mm (standard)',
  [TUBE.D33_MM]: 'Tube diamètre 3,3 mm',
  [TUBE.WATERPROOF]: 'Tube anti-humidité',
};
export const tubeRules: {[key in TUBE]: string} = {
  [TUBE.D25_MM]: 'TUB_tube25',
  [TUBE.D30_MM]: 'TUB_tube30',
  [TUBE.D33_MM]: 'TUB_tube33',
  [TUBE.WATERPROOF]: 'TUB_humide',
};

export enum OPTION_TUBE {
  GUIDE = 'GUIDE',
  RIGHT_ANGLE = 'RIGHT_ANGLE',
  LOCK = 'LOCK',
}
export const optionTubeLabels: {[key in OPTION_TUBE]: string} = {
  [OPTION_TUBE.GUIDE]: 'Guide tube',
  [OPTION_TUBE.RIGHT_ANGLE]: 'Coude équerre angle droit',
  [OPTION_TUBE.LOCK]: 'Bague lock',
};
export const optionTubeRules: {[key in OPTION_TUBE]: string} = {
  [OPTION_TUBE.GUIDE]: 'OPT_guideTube',
  [OPTION_TUBE.RIGHT_ANGLE]: 'OPT_coudeEq',
  [OPTION_TUBE.LOCK]: 'OPT_bague',
};

export const TubeOptions: OptionData<TUBE> = {
  ...unique,
  type: TYPE.TUBE,
  values: [TUBE.D25_MM, TUBE.D30_MM, TUBE.D33_MM, TUBE.WATERPROOF],
};

export const OptionTubeOptions: OptionData<OPTION_TUBE> = {
  type: TYPE.OPTION_TUBE,
  values: [OPTION_TUBE.GUIDE, OPTION_TUBE.RIGHT_ANGLE, OPTION_TUBE.LOCK],
};

export enum SHOULDER {
  LONG = 'LONG',
  SHORT = 'SHORT',
}
export const shoulderLabels: {[key in SHOULDER]: string} = {
  [SHOULDER.LONG]: 'Épaulement long',
  [SHOULDER.SHORT]: 'Épaulement court',
};

export const ShoulderOptions: OptionData<SHOULDER> = {
  ...unique,
  type: TYPE.SHOULDER,
  values: [SHOULDER.LONG, SHOULDER.SHORT],
};

export const ColorMarkerOptions: OptionData<BOOLEAN_VALUE_TYPE> = {
  ...unique,
  type: TYPE.COLOR_MARKER,
  values: [BOOLEAN_VALUE],
};

export const OtoscanOptions: OptionData<BOOLEAN_VALUE_TYPE> = {
  ...unique,
  type: TYPE.OTOSCAN,
  values: [BOOLEAN_VALUE],
};

const resineFinishes = {
  ...unique,
  type: TYPE.SURFACE,
  values: [SURFACE.ANTIBACTERIAL, SURFACE.SANDED],
};

const siliconeFinishes = {
  ...unique,
  type: TYPE.SURFACE,
  values: [SURFACE.ANTIBACTERIAL, SURFACE.MATTE],
};

export enum EAR_BUD {
  S = 'S',
  M = 'M',
  P = 'P',
  SP = 'SP',
  MICRO = 'MICRO',
  SILK = 'SILK',

  STANDARD = 'STANDARD',
  MEDIUM = 'MEDIUM',
  INTIGA = 'INTIGA',
  ALTA_60 = 'ALTA_60',
  ALTA_85 = 'ALTA_85',
  ALTA_100 = 'ALTA_100',
  RIC80 = 'RIC80',
  CORDA_SQUARED = 'CORDA_SQUARED',
  MINI_FIT_CORDA = 'MINI_FIT_CORDA',

  LP_40DB = 'LP_40DB',
  MP_50DB = 'MP_50DB',
  HP_60DB = 'HP_60DB',
  HP2 = 'HP2',
  HPG = 'HPG',
  LP_NP = 'LP_NP',

  EASYWEAR_S = 'EASYWEAR_S',
  EASYWEAR_M = 'EASYWEAR_M',
  EASYWEAR_P = 'EASYWEAR_P',
  MICRO_TUBE_ELAN_13 = 'MICRO_TUBE_ELAN_13',
  MICRO_TUBE_M_10 = 'MICRO_TUBE_M_10',
  MICRO_TUBE_EASYWEAR_09_16 = 'MICRO_TUBE_EASYWEAR_09_16',

  ECOUTEUR_XS = 'ECOUTEUR_XS',
  ECOUTEUR_XP = 'ECOUTEUR_XP',
  M_TUBE = 'M_TUBE',
  ECOUTEUR_MARVEL_S = 'ECOUTEUR_MARVEL_S',
  ECOUTEUR_MARVEL_M = 'ECOUTEUR_MARVEL_M',
  ECOUTEUR_MARVEL_P = 'ECOUTEUR_MARVEL_P',
  STANDARD_45DB = 'STANDARD_45DB',
  POWER = 'POWER',
  CROS = 'CROS',

  DB_40 = 'DB_40',
  DB_50 = 'DB_50',
  DB_60 = 'DB_60',
}
export const earBudLabels: {[key in EAR_BUD]: string} = {
  [EAR_BUD.S]: 'S',
  [EAR_BUD.M]: 'M',
  [EAR_BUD.P]: 'P',
  [EAR_BUD.SP]: 'SP',
  [EAR_BUD.MICRO]: 'µ tube',
  [EAR_BUD.SILK]: 'SILK',

  [EAR_BUD.STANDARD]: 'Standard',
  [EAR_BUD.MEDIUM]: 'Medium',
  [EAR_BUD.INTIGA]: 'Intiga',
  [EAR_BUD.ALTA_60]: 'Alta 60',
  [EAR_BUD.ALTA_85]: 'Alta 85',
  [EAR_BUD.ALTA_100]: 'Alta 100',
  [EAR_BUD.RIC80]: 'Design RIC80',
  [EAR_BUD.CORDA_SQUARED]: 'µ tube Corda ²',
  [EAR_BUD.MINI_FIT_CORDA]: 'µ tube mini Fit Corda',

  [EAR_BUD.LP_40DB]: 'LP - 40db',
  [EAR_BUD.MP_50DB]: 'MP - 50dB',
  [EAR_BUD.HP_60DB]: 'HP - 60dB',
  [EAR_BUD.HP2]: 'HP2',
  [EAR_BUD.HPG]: 'HPG ancienne génération',
  [EAR_BUD.LP_NP]: 'LP/NP ancienne génération',

  [EAR_BUD.EASYWEAR_S]: 'Easywear S',
  [EAR_BUD.EASYWEAR_M]: 'Easywear M',
  [EAR_BUD.EASYWEAR_P]: 'Easywear P',
  [EAR_BUD.MICRO_TUBE_ELAN_13]: 'µ tube Elan série 9 -Pile 13',
  [EAR_BUD.MICRO_TUBE_M_10]: 'µ tube Série M -Pile 10',
  [EAR_BUD.MICRO_TUBE_EASYWEAR_09_16]: 'µ tube Easywear 09 ou 14',

  [EAR_BUD.ECOUTEUR_XS]: 'Ecouteur XS',
  [EAR_BUD.ECOUTEUR_XP]: 'Ecouteur XP',
  [EAR_BUD.M_TUBE]: 'µ tube',
  [EAR_BUD.ECOUTEUR_MARVEL_S]: 'Ecouteur Marvel S',
  [EAR_BUD.ECOUTEUR_MARVEL_M]: 'Ecouteur Marvel M',
  [EAR_BUD.ECOUTEUR_MARVEL_P]: 'Ecouteur Marvel P',
  [EAR_BUD.STANDARD_45DB]: 'Standard 45dB ancienne génération',
  [EAR_BUD.POWER]: 'Power ancienne génération',
  [EAR_BUD.CROS]: 'CROS',

  [EAR_BUD.DB_40]: 'Ecouteur 40 dB',
  [EAR_BUD.DB_50]: 'Ecouteur 50 dB',
  [EAR_BUD.DB_60]: 'Ecouteur 60 dB',
};

export const SiemensEarBudOptions: OptionData<EAR_BUD> = {
  ...requiredAndUnique,
  type: TYPE.EAR_BUD,
  values: [
    EAR_BUD.S,
    EAR_BUD.M,
    EAR_BUD.P,
    EAR_BUD.SP,
    EAR_BUD.MICRO,
    EAR_BUD.SILK,
  ],
};

export const OticonEarBudOptions: OptionData<EAR_BUD> = {
  ...requiredAndUnique,
  type: TYPE.EAR_BUD,
  values: [
    EAR_BUD.STANDARD,
    EAR_BUD.MEDIUM,
    EAR_BUD.INTIGA,
    EAR_BUD.ALTA_60,
    EAR_BUD.ALTA_85,
    EAR_BUD.ALTA_100,
    EAR_BUD.RIC80,
    EAR_BUD.CORDA_SQUARED,
    EAR_BUD.MINI_FIT_CORDA,
  ],
};

export const BeltoneEarBudOptions: OptionData<EAR_BUD> = {
  ...requiredAndUnique,
  type: TYPE.EAR_BUD,
  values: [
    EAR_BUD.LP_40DB,
    EAR_BUD.MP_50DB,
    EAR_BUD.HP_60DB,
    EAR_BUD.S,
    EAR_BUD.HP2,
    EAR_BUD.HPG,
    EAR_BUD.LP_NP,
    EAR_BUD.M_TUBE,
  ],
};

export const WidexEarBudOptions: OptionData<EAR_BUD> = {
  ...requiredAndUnique,
  type: TYPE.EAR_BUD,
  values: [
    EAR_BUD.EASYWEAR_S,
    EAR_BUD.EASYWEAR_M,
    EAR_BUD.EASYWEAR_P,
    EAR_BUD.MICRO_TUBE_ELAN_13,
    EAR_BUD.MICRO_TUBE_M_10,
    EAR_BUD.MICRO_TUBE_M_10,
    EAR_BUD.MICRO_TUBE_EASYWEAR_09_16,
  ],
};

export const PhonakEarBudOptions: OptionData<EAR_BUD> = {
  ...requiredAndUnique,
  type: TYPE.EAR_BUD,
  values: [
    EAR_BUD.ECOUTEUR_XS,
    EAR_BUD.ECOUTEUR_XP,
    EAR_BUD.M_TUBE,
    EAR_BUD.ECOUTEUR_MARVEL_S,
    EAR_BUD.ECOUTEUR_MARVEL_M,
    EAR_BUD.ECOUTEUR_MARVEL_P,
    EAR_BUD.STANDARD_45DB,
    EAR_BUD.POWER,
    EAR_BUD.CROS,
  ],
};

export const StarkeyEarBudOptions: OptionData<EAR_BUD> = {
  ...requiredAndUnique,
  type: TYPE.EAR_BUD,
  values: [EAR_BUD.DB_40, EAR_BUD.DB_50, EAR_BUD.DB_60, EAR_BUD.M_TUBE],
};

export enum MISCELLANEOUS {
  COLOR_MARKER = 'COLOR_MARKER',
  EXTRACTION_CORDS = 'EXTRACTION_CORDS',
  GROOVED_EVENT = 'GROOVED_EVENT',
  ANTIBACTERIAL_VARNISH = 'ANTIBACTERIAL_VARNISH',
  SANDING = 'SANDING',
}
export const miscellaneousLabels: {[key in MISCELLANEOUS]: string} = {
  [MISCELLANEOUS.COLOR_MARKER]: 'Repère de couleur',
  [MISCELLANEOUS.EXTRACTION_CORDS]: "Fils d'extraction",
  [MISCELLANEOUS.GROOVED_EVENT]: 'Event rainuré',
  [MISCELLANEOUS.ANTIBACTERIAL_VARNISH]: 'Vernis antibactérien',
  [MISCELLANEOUS.SANDING]: 'Sablage',
};
export const miscellaneousRules: {[key in MISCELLANEOUS]?: string} = {
  [MISCELLANEOUS.EXTRACTION_CORDS]: 'OPT_fil',
  [MISCELLANEOUS.GROOVED_EVENT]: commonRules.groovedBlowhole,
  [MISCELLANEOUS.ANTIBACTERIAL_VARNISH]: commonRules.antibacterialVarnish,
};

export const MiscellaneousOptions: OptionData<MISCELLANEOUS> = {
  type: TYPE.MISCELLANEOUS,
  values: [
    MISCELLANEOUS.COLOR_MARKER,
    MISCELLANEOUS.EXTRACTION_CORDS,
    MISCELLANEOUS.GROOVED_EVENT,
  ],
};

export const EarBudAssemblyOptions: OptionData<BOOLEAN_VALUE_TYPE> = {
  ...unique,
  type: TYPE.EAR_BUD_ASSEMBLY,
  values: [BOOLEAN_VALUE],
};

export enum EARWAX_SHIELDING {
  HF4 = 'HF4',
  VALVE = 'VALVE',
}
export const earwaxShieldingLabels: {[key in EARWAX_SHIELDING]: string} = {
  [EARWAX_SHIELDING.HF4]: 'Pare-cérumen HF4',
  [EARWAX_SHIELDING.VALVE]: 'Clapet',
};
export const earwaxShieldingRules: {[key in EARWAX_SHIELDING]: string} = {
  [EARWAX_SHIELDING.HF4]: 'OPT_parCerumen',
  [EARWAX_SHIELDING.VALVE]: 'OPT_clapet',
};

export const EarwaxShieldingOptions: OptionData<EARWAX_SHIELDING> = {
  ...unique,
  type: TYPE.EARWAX_SHIELDING,
  values: [EARWAX_SHIELDING.HF4, EARWAX_SHIELDING.VALVE],
};

const resineDeporteOptions = [
  DeporteEventOptions,
  MiscellaneousOptions,
  resineFinishes,
  EarBudAssemblyOptions,
];

const siliconeDeporteOptions = [
  ResineDeporteEventOptions,
  MiscellaneousOptions,
  siliconeFinishes,
  EarBudAssemblyOptions,
];

const themotecDeporteOptions = [
  DeporteEventOptions,
  MiscellaneousOptions,
  EarBudAssemblyOptions,
];

const deporteColors = {
  ...requiredAndUnique,
  type: TYPE.COLOR,
  values: [
    COLOR.COLORLESS_TRANSLUCENT,
    COLOR.BEIGE_FLESH,
    COLOR.ROSE_TRANSLUCENT,
    COLOR.OTHER,
  ],
};

const deporteThermotecColors = {
  ...requiredAndUnique,
  type: TYPE.COLOR,
  values: [COLOR.COLORLESS_TRANSLUCENT, COLOR.ROSE_TRANSLUCENT],
};

const deporteShapeCanuleSiemens: CommonOption & Typed = {
  ...requiredAndUnique,
  type: TYPE.MATERIAL,
  [DEPORTE_CANULE_MATERIAL.RESINE_DURE]: {
    options: [
      deporteColors,
      SiemensEarBudOptions,
      ...resineDeporteOptions,
      EarwaxShieldingOptions,
    ],
  },
  [DEPORTE_CANULE_MATERIAL.SILICON_70]: {
    options: [
      deporteColors,
      SiemensEarBudOptions,
      ...siliconeDeporteOptions,
      EarwaxShieldingOptions,
    ],
  },
  [DEPORTE_CANULE_MATERIAL.THERMOTEC]: {
    options: [
      deporteThermotecColors,
      SiemensEarBudOptions,
      ...themotecDeporteOptions,
      EarwaxShieldingOptions,
    ],
  },
};

const getDeporteShapeCanuleOticon = (
  earBudOptions: OptionData<EAR_BUD>,
): CommonOption & Typed => ({
  ...requiredAndUnique,
  type: TYPE.MATERIAL,
  [DEPORTE_CANULE_MATERIAL.RESINE_DURE]: {
    options: [deporteColors, earBudOptions, ...resineDeporteOptions],
  },
  [DEPORTE_CANULE_MATERIAL.SILICON_70]: {
    options: [deporteColors, earBudOptions, ...siliconeDeporteOptions],
  },
  [DEPORTE_CANULE_MATERIAL.THERMOTEC]: {
    options: [deporteThermotecColors, earBudOptions, ...themotecDeporteOptions],
  },
});

export const FloatOptions: OptionData<BOOLEAN_VALUE_TYPE> = {
  ...unique,
  type: TYPE.FLOAT,
  values: [BOOLEAN_VALUE],
};

export const EngravingOptions: OptionData<BOOLEAN_VALUE_TYPE> = {
  ...unique,
  type: TYPE.ENGRAVING,
  values: [BOOLEAN_VALUE],
};

export enum OPTION {
  CLIP = 'CLIP',
  HYGIENE = 'HYGIENE',

  CORD = 'CORD',
  EAR_BUD_WHITE = 'EAR_BUD_WHITE',
  EAR_BUD_BLACK = 'EAR_BUD_BLACK',
  EAR_BUD_SWITCH = 'EAR_BUD_SWITCH',

  MAGNETIC = 'MAGNETIC',
  COLOR_BLUE = 'COLOR_BLUE',
  HANDLE = 'HANDLE',

  LINK_CORD = 'LINK_CORD',
  PINK_SPARKLES = 'PINK_SPARKLES',
  BLUE_SPARKLES = 'BLUE_SPARKLES',

  SPARKLES = 'SPARKLES',
}
export const optionLabels: {[key in OPTION]: string} = {
  [OPTION.CLIP]: 'Clip de sécurité',
  [OPTION.HYGIENE]: 'Kit hygiène',

  [OPTION.CORD]: 'Sans poignée avec cordon',
  [OPTION.EAR_BUD_WHITE]: 'Ecouteur (coloris blanc)',
  [OPTION.EAR_BUD_BLACK]: 'Ecouteur (coloris noir)',
  [OPTION.EAR_BUD_SWITCH]: 'Ecouteur Switch (main libre)',

  [OPTION.MAGNETIC]: 'Détection magnétique',
  [OPTION.COLOR_BLUE]: 'Couleur bleu alimentaire',
  [OPTION.HANDLE]: 'Poignée de préhension',

  [OPTION.LINK_CORD]: 'Cordon de liaison',
  [OPTION.PINK_SPARKLES]: 'Paillettes roses',
  [OPTION.BLUE_SPARKLES]: 'Paillettes bleues',

  [OPTION.SPARKLES]: 'Paillettes',
};
export const optionRules: {[key in OPTION]?: string} = {
  [OPTION.CLIP]: 'OPT_option07',
  [OPTION.HYGIENE]: 'OPT_option08',

  [OPTION.MAGNETIC]: 'OPT_option13',
  [OPTION.COLOR_BLUE]: 'OPT_option14',
  [OPTION.HANDLE]: 'OPT_option15',

  [OPTION.SPARKLES]: 'OPT_option16',
  [OPTION.PINK_SPARKLES]: 'OPT_option16',
  [OPTION.BLUE_SPARKLES]: 'OPT_option16',
  [OPTION.LINK_CORD]: 'OPT_aquastopC',
};

export const OptionOptions: OptionData<OPTION> = {
  ...unique,
  type: TYPE.OPTION,
  values: [OPTION.CLIP /*, OPTION.HYGIENE*/],
};

export const PasStopOptions: OptionData<OPTION> = {
  type: TYPE.OPTION,
  values: [OPTION.MAGNETIC, OPTION.COLOR_BLUE, OPTION.HANDLE],
};

export const ObturateurOptions: OptionData<OPTION> = {
  type: TYPE.OPTION,
  values: [OPTION.MAGNETIC, OPTION.LINK_CORD, OPTION.SPARKLES],
};

export const CamoOptions: OptionData<BOOLEAN_VALUE_TYPE> = {
  ...unique,
  type: TYPE.CAMO,
  values: [BOOLEAN_VALUE],
};

export const CordOptions: OptionData<BOOLEAN_VALUE_TYPE> = {
  ...unique,
  type: TYPE.CORD,
  values: [BOOLEAN_VALUE],
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const getClassicOptions = (material: any): ProductCategory & Typed => ({
  ...requiredAndUnique,
  type: TYPE.MATERIAL,
  [material.RESINE_ACRYLIQUE]: {
    ...requiredAndUnique,
    options: [
      {
        ...requiredAndUnique,

        type: TYPE.COLOR,
        values: [
          COLOR.COLORLESS_TRANSLUCENT,
          COLOR.ROSE_TRANSLUCENT,
          COLOR.BEIGE_FLESH_TRANSLUCENT,
          COLOR.BLACK_OPAQUE,
          COLOR.WHITE_OPAQUE,
        ],
      },
      ClassicResineEventOptions,
      resineFinishes,
      TubeOptions,
      OptionTubeOptions,
      ColorMarkerOptions,
    ],
  },
  [material.SILICON_40]: {
    ...requiredAndUnique,
    options: [
      {
        ...requiredAndUnique,

        type: TYPE.COLOR,
        values: [
          COLOR.COLORLESS_TRANSLUCENT,
          COLOR.ROSE_TRANSLUCENT,
          COLOR.BROWN,
        ],
      },
      ClassicEventOptions,
      siliconeFinishes,
      TubeOptions,
      OptionTubeOptions,
      ColorMarkerOptions,
    ],
  },
  [material.SILICON_70]: {
    ...requiredAndUnique,
    options: [
      {
        ...requiredAndUnique,

        type: TYPE.COLOR,
        values: [
          COLOR.COLORLESS_TRANSLUCENT,
          COLOR.ROSE_TRANSLUCENT,
          COLOR.BLACK_OPAQUE,
          COLOR.WHITE_OPAQUE,
        ],
      },
      ClassicEventOptions,
      siliconeFinishes,
      TubeOptions,
      OptionTubeOptions,
      ColorMarkerOptions,
    ],
  },
  [material.THERMOTEC]: {
    ...requiredAndUnique,
    options: [
      {
        ...requiredAndUnique,
        type: TYPE.COLOR,
        values: [COLOR.COLORLESS, COLOR.ROSE],
      },
      ClassicEventOptions,
      TubeOptions,
      OptionTubeOptions,
      ColorMarkerOptions,
    ],
  },
});

const getPhantomoldOptions = (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  material: any,
  epaulement = false,
): ProductCategory & Typed => ({
  ...requiredAndUnique,
  type: TYPE.MATERIAL,
  [material.RESINE_ACRYLIQUE]: {
    ...requiredAndUnique,
    options: [
      {
        ...requiredAndUnique,
        type: TYPE.COLOR,
        values: [
          COLOR.COLORLESS,
          COLOR.ROSE,
          COLOR.BEIGE_FLESH_TRANSLUCENT,
          COLOR.BROWN,
        ],
      },
      PhantomoldEventOptions,
      resineFinishes,
      TubeOptions,
      OptionTubeOptions,
      ...(epaulement ? [ShoulderOptions] : []),
      ColorMarkerOptions,
    ],
  },
  [material.SILICON_40]: {
    ...requiredAndUnique,
    options: [
      {
        ...requiredAndUnique,
        type: TYPE.COLOR,
        values: [
          COLOR.COLORLESS,
          COLOR.ROSE,
          COLOR.BLACK_OPAQUE,
          COLOR.WHITE_OPAQUE,
        ],
      },
      PhantomoldEventOptions,
      siliconeFinishes,
      TubeOptions,
      OptionTubeOptions,
      ...(epaulement ? [ShoulderOptions] : []),
      ColorMarkerOptions,
    ],
  },
  [material.SILICON_70]: {
    ...requiredAndUnique,
    options: [
      {
        ...requiredAndUnique,
        type: TYPE.COLOR,
        values: [
          COLOR.COLORLESS,
          COLOR.ROSE,
          COLOR.BLACK_OPAQUE,
          COLOR.WHITE_OPAQUE,
        ],
      },
      PhantomoldEventOptions,
      siliconeFinishes,
      TubeOptions,
      OptionTubeOptions,
      ...(epaulement ? [ShoulderOptions] : []),
      ColorMarkerOptions,
    ],
  },
  [material.THERMOTEC]: {
    ...requiredAndUnique,
    options: [
      {
        ...requiredAndUnique,
        type: TYPE.COLOR,
        values: [COLOR.COLORLESS, COLOR.ROSE],
      },
      PhantomoldEventOptions,
      TubeOptions,
      OptionTubeOptions,
      ...(epaulement ? [ShoulderOptions] : []),
      ColorMarkerOptions,
    ],
  },
});

export const optionsTree: OptionsTree = {
  [PRODUCT_CATEGORY.CLASSIQUE]: {
    type: TYPE.SHAPE,
    [CLASSIQUE_SHAPE.CANULE]: getClassicOptions(CLASSIQUE_CANULE_MATERIAL),
    [CLASSIQUE_SHAPE.CANULE_EPAULEMENT]: getPhantomoldOptions(
      CLASSIQUE_CANULE_EPAULEMENT_MATERIAL,
      true,
    ),
    [CLASSIQUE_SHAPE.COQUILLE]: getClassicOptions(CLASSIQUE_COQUILLE_MATERIAL),
    [CLASSIQUE_SHAPE.DEMI_CONQUE]: getClassicOptions(
      CLASSIQUE_DEMI_CONQUE_MATERIAL,
    ),
    [CLASSIQUE_SHAPE.PHANTOMOLD]: getPhantomoldOptions(
      CLASSIQUE_PHANTOMOLD_MATERIAL,
    ),
    [CLASSIQUE_SHAPE.SILHOUETTE]: getPhantomoldOptions(
      CLASSIQUE_SILHOUETTE_MATERIAL,
    ),
    [CLASSIQUE_SHAPE.IROS]: {
      ...requiredAndUnique,
      type: TYPE.MATERIAL,
      [CLASSIQUE_IROS_MATERIAL.RESINE_ACRYLIQUE]: {
        ...requiredAndUnique,
        options: [
          {
            ...requiredAndUnique,
            type: TYPE.COLOR,
            values: [
              COLOR.COLORLESS,
              COLOR.ROSE,
              COLOR.BEIGE_FLESH_TRANSLUCENT,
              COLOR.BROWN,
            ],
          },
          resineFinishes,
          TubeOptions,
          OptionTubeOptions,
          ColorMarkerOptions,
        ],
      },
      [CLASSIQUE_IROS_MATERIAL.SILICON_40]: {
        ...requiredAndUnique,
        options: [
          {
            ...requiredAndUnique,
            type: TYPE.COLOR,
            values: [
              COLOR.COLORLESS,
              COLOR.ROSE,
              COLOR.BEIGE_FLESH_TRANSLUCENT,
              COLOR.BROWN,
            ],
          },
          resineFinishes,
          TubeOptions,
          OptionTubeOptions,
          ColorMarkerOptions,
        ],
      },
      [CLASSIQUE_IROS_MATERIAL.SILICON_70]: {
        ...requiredAndUnique,
        options: [
          {
            ...requiredAndUnique,
            type: TYPE.COLOR,
            values: [
              COLOR.COLORLESS,
              COLOR.ROSE,
              COLOR.BEIGE_FLESH_TRANSLUCENT,
              COLOR.BROWN,
            ],
          },
          resineFinishes,
          TubeOptions,
          OptionTubeOptions,
          ColorMarkerOptions,
        ],
      },
      [CLASSIQUE_IROS_MATERIAL.THERMOTEC]: {
        ...requiredAndUnique,
        options: [
          {
            ...requiredAndUnique,
            type: TYPE.COLOR,
            values: [
              COLOR.COLORLESS,
              COLOR.ROSE,
              COLOR.BEIGE_FLESH_TRANSLUCENT,
              COLOR.BROWN,
            ],
          },
          TubeOptions,
          OptionTubeOptions,
          ColorMarkerOptions,
        ],
      },
    },
  },
  [PRODUCT_CATEGORY.DEPORTE]: {
    type: TYPE.BRAND,
    ...[
      [DEPORTE_BRAND.SIEMENS, deporteShapeCanuleSiemens],
      [DEPORTE_BRAND.OTICON, getDeporteShapeCanuleOticon(OticonEarBudOptions)],
      [
        DEPORTE_BRAND.BELTONE,
        getDeporteShapeCanuleOticon(BeltoneEarBudOptions),
      ],
      [DEPORTE_BRAND.WIDEX, getDeporteShapeCanuleOticon(WidexEarBudOptions)],
      [DEPORTE_BRAND.PHONAK, getDeporteShapeCanuleOticon(PhonakEarBudOptions)],
      [
        DEPORTE_BRAND.STARKEY,
        getDeporteShapeCanuleOticon(StarkeyEarBudOptions),
      ],
      [
        DEPORTE_BRAND.COQUE_INTRA,
        {
          ...requiredAndUnique,
          type: TYPE.COLOR,
          [COLOR.HARD_RESIN_COLORLESS]: {
            ...requiredAndUnique,
            options: [DeporteEventOptions],
          },
          [COLOR.HARD_RESIN_BEIGE_FLESH]: {
            ...requiredAndUnique,
            options: [DeporteEventOptions],
          },
        },
      ],
    ].reduce<ProductCategory>((res, [brand, data]) => {
      res[brand as DEPORTE_BRAND] = {
        type: TYPE.SHAPE,
        ...Object.fromEntries(
          Object.values(DEPORTE_SHAPE).map((shape) => [shape, data]),
        ),
      };

      return res;
    }, {}),
    [DEPORTE_BRAND.COQUE_INTRA]: {
      ...requiredAndUnique,
      type: TYPE.COLOR,
      [COLOR.HARD_RESIN_COLORLESS]: {
        ...requiredAndUnique,
        options: [DeporteEventOptions],
      },
      [COLOR.HARD_RESIN_BEIGE_FLESH]: {
        ...requiredAndUnique,
        options: [DeporteEventOptions],
      },
    },
  },
  [PRODUCT_CATEGORY.PROTECTION]: {
    type: TYPE.BRAND,
    [PROTECTION_SHAPE.EAR]: {},
    [PROTECTION_SHAPE.AQUASON]: FloatOptions,
    [PROTECTION_SHAPE.PASSTOP_EP2]: {
      ...requiredAndUnique,
      type: TYPE.MATERIAL,
      [PROTECTION_PASSTOPEP2_MATERIAL.RESINE_ACRYLIQUE]: {
        ...requiredAndUnique,
        options: [
          {
            ...requiredAndUnique,
            type: TYPE.COLOR,
            values: [
              COLOR.COLORLESS_TRANSLUCENT,
              COLOR.GREEN_TRANSLUCENT,
              COLOR.BLUE_TRANSLUCENT,
              COLOR.BLACK_OPAQUE,
              // COLOR.BLUE_FOOD,
            ],
          },
          {
            ...requiredAndUnique,
            type: TYPE.CORD_COLOR,
            values: [
              CORD_COLOR.ORANGE,
              CORD_COLOR.GREEN,
              CORD_COLOR.BLUE,
              CORD_COLOR.BLACK,
            ],
          },
          EngravingOptions,
          OptionOptions,
        ],
      },
    },
    [PROTECTION_SHAPE.PIANISSIMO]: {
      ...requiredAndUnique,
      type: TYPE.FILTER,
      ...Object.fromEntries(
        [FILTER.DB_15, FILTER.DB_25, FILTER.DB_30].map<
          [FILTER, ProductCategoryType]
        >((filter) => [
          filter,
          {
            options: [
              {
                ...requiredAndUnique,
                type: TYPE.COLOR,
                values: [
                  COLOR.ROSE_TRANSPARENT,
                  COLOR.BLUE_PHOSOPHORESCENT,
                  COLOR.RED_PHOSOPHORESCENT,
                  COLOR.ORANGE_PHOSOPHORESCENT,
                  COLOR.YELLOW_PHOSOPHORESCENT,
                  COLOR.GREEN_PHOSOPHORESCENT,
                  COLOR.PINK_PHOSOPHORESCENT,
                  COLOR.WHITE,
                  COLOR.BLACK,
                ],
              },
              {
                type: TYPE.OPTION,
                values: [
                  OPTION.CORD,
                  OPTION.EAR_BUD_WHITE,
                  OPTION.EAR_BUD_BLACK,
                  OPTION.EAR_BUD_SWITCH,
                ],
              },
            ],
          },
        ]),
      ),
    },
    [PROTECTION_SHAPE.PASSTOP_T]: {
      ...requiredAndUnique,
      type: TYPE.SHAPE,
      [PROTECTION_PASSTOP_SHAPE.CONQUE]: {
        ...requiredAndUnique,
        options: [
          {
            ...requiredAndUnique,
            type: TYPE.FILTER,
            values: [FILTER.SNR22, FILTER.SNR24],
          },
          PasStopOptions,
        ],
      },
      [PROTECTION_PASSTOP_SHAPE.CANULE]: {
        ...requiredAndUnique,
        options: [
          {
            ...requiredAndUnique,
            type: TYPE.FILTER,
            values: [FILTER.SNR21, FILTER.SNR26],
          },
          PasStopOptions,
        ],
      },
    },
    [PROTECTION_SHAPE.OBTURATEUR]: {
      ...requiredAndUnique,
      type: TYPE.SHAPE,
      ...Object.fromEntries(
        [
          PROTECTION_OBSTRUATEUR_SHAPE.CONQUE,
          PROTECTION_OBSTRUATEUR_SHAPE.CANULE,
        ].map<[PROTECTION_OBSTRUATEUR_SHAPE, ProductCategoryType]>((shape) => [
          shape,
          {
            ...requiredAndUnique,
            type: TYPE.MATERIAL,
            [PROTECTION_OBTURATEUR_MATERIAL.SILICON_25]: {
              options: [
                {
                  ...requiredAndUnique,
                  type: TYPE.COLOR,
                  values: [
                    COLOR.COLORLESS_TRANSLUCENT,
                    COLOR.ROSE_TRANSLUCENT,
                    COLOR.BROWN_TRANSLUCENT,
                    COLOR.RED_OPAQUE,
                    COLOR.GREEN_OPAQUE,
                    COLOR.BLUE_OPAQUE,
                    COLOR.ORANGE_OPAQUE,
                    COLOR.BLACK_OPAQUE,
                    COLOR.WHITE_OPAQUE,
                  ],
                },
                ObturateurOptions,
              ],
            },
            [PROTECTION_OBTURATEUR_MATERIAL.SILICON_40]: {
              options: [
                {
                  ...requiredAndUnique,
                  type: TYPE.COLOR,
                  values: [
                    COLOR.COLORLESS_TRANSLUCENT,
                    COLOR.ROSE_TRANSLUCENT,
                    COLOR.BROWN_TRANSLUCENT,
                    COLOR.RED_OPAQUE,
                    COLOR.GREEN_OPAQUE,
                    COLOR.BLUE_OPAQUE,
                    COLOR.BLACK_OPAQUE,
                    COLOR.WHITE_OPAQUE,
                  ],
                },
                ObturateurOptions,
              ],
            },
          },
        ]),
      ),
    },
    [PROTECTION_SHAPE.SOMMEIL]: {
      ...requiredAndUnique,
      type: TYPE.SHAPE,
      ...Object.fromEntries(
        [PROTECTION_SOMMEIL_SHAPE.CONQUE, PROTECTION_SOMMEIL_SHAPE.CANULE].map<
          [PROTECTION_SOMMEIL_SHAPE, ProductCategoryType]
        >((shape) => [
          shape,
          {
            options: [
              {
                ...requiredAndUnique,
                type: TYPE.COLOR,
                values: [
                  COLOR.COLORLESS_TRANSLUCENT,
                  COLOR.ROSE_TRANSLUCENT,
                  COLOR.BLUE_OPAQUE,
                  COLOR.RED_OPAQUE,
                  COLOR.GREEN_OPAQUE,
                  COLOR.ORANGE_OPAQUE,
                ],
              },
            ],
          },
        ]),
      ),
    },
    [PROTECTION_SHAPE.AQUASTOP]: {
      ...requiredAndUnique,
      type: TYPE.SHAPE,
      [PROTECTION_AQUASTOP_SHAPE.CONQUE]: {
        ...requiredAndUnique,
        options: [
          {
            ...requiredAndUnique,
            type: TYPE.COLOR,
            values: [
              COLOR.COLORLESS_TRANSLUCENT,
              COLOR.ROSE_TRANSLUCENT,
              COLOR.BROWN_TRANSLUCENT,
              COLOR.RED_OPAQUE,
              COLOR.GREEN_OPAQUE,
              COLOR.BLUE_OPAQUE,
              COLOR.YELLOW_OPAQUE,
              COLOR.ORANGE_OPAQUE,
            ],
          },
          CordOptions,
        ],
      },
    },
  },
  [PRODUCT_CATEGORY.CHASSE_TIR]: {
    type: TYPE.BRAND,
    [CHASSETIR_SHAPE.PASSTOP_TIR_CHASSE]: CamoOptions,
    [CHASSETIR_SHAPE.STOPGUN_E]: {},
    [CHASSETIR_SHAPE.STOPGUN_FLEX]: {},
    [CHASSETIR_SHAPE.STOPGUN]: {},
  },
};
