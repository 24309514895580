import {FC} from 'react';
import {useHistory} from 'react-router-dom';
import {useSession} from './contexts/Session';

const Logout: FC = () => {
  const session = useSession();
  const history = useHistory();

  (async () => {
    await session.logout();
    history.replace('/login');
  })();
  return null;
};

export default Logout;
