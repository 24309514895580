import {css} from '@emotion/core';
import {Card, Col, Descriptions, Row} from 'antd';
import React, {FC, memo} from 'react';
import {Link} from 'react-router-dom';
import {useCurrentLine} from '../../contexts/Quote';
import {colors, cssRowWithoutOuterGutter} from '../../util/render';
import {getSelectedEars, SELECTED_EARS} from '../Options/static';
import OptionDescription from '../Summary/OptionDescription';
import OrderDescription from '../Summary/OrderDescription';
import PatientDescription from '../Summary/PatientDescription';

const containerCss = css`
  .ant-col > .ant-card {
    height: 100%;
  }

  .ant-descriptions-item-label {
    color: ${colors.secondary};
  }
`;

const imageDescriptionCss = css`
  .ant-descriptions-item-content {
    height: 21px;
    img {
      max-height: 100%;
      margin-right: 5px;
    }
  }
`;

const responsiveHeaderCss = css`
  .ant-col {
    padding: 2px 0;
    @media (max-width: 1600px) {
      flex: 1;
      margin: 0;
    }
  }
`;

interface HeaderSummaryProps {
  patientOnly?: boolean;
  parentPath: string;
}

const HeaderSummary: FC<HeaderSummaryProps> = ({patientOnly = false, parentPath}) => {
  const [{category, product, options}] = useCurrentLine();

  return (
    <Row
      type="flex"
      css={[containerCss, cssRowWithoutOuterGutter, responsiveHeaderCss]}
      gutter={{xl: 0, sm: 5}}
    >
      {!patientOnly ? (
        <Col>
          <Card>
            <OrderDescription
              descriptionSpan={3}
              title={<Link to={`${parentPath}/patient`}>Informations commande</Link>}
            />
          </Card>
        </Col>
      ) : null}
      <Col>
        <Card>
          <PatientDescription
            descriptionSpan={3}
            title={<Link to={`${parentPath}/patient`}>Références Patient</Link>}
          />
        </Card>
      </Col>
      <Col>
        {category ? (
          <Card>
            <Descriptions
              title="Catégorie et produit"
              layout="horizontal"
              size="small"
              css={imageDescriptionCss}
            >
              <Descriptions.Item label="Catégorie" span={3}>
                <Link to={`${parentPath}/categories`}>
                  <img src={category.img} alt={category.label} />
                  {category.label}
                </Link>
              </Descriptions.Item>
              {product ? (
                <Descriptions.Item label="Produit" span={3}>
                  <Link to={`${parentPath}/products`}>
                    <img src={product.img} alt={product.label} />
                    {product.label}
                  </Link>
                </Descriptions.Item>
              ) : null}
            </Descriptions>
          </Card>
        ) : null}
      </Col>
      <Col>
        {getSelectedEars(options.ears) !== SELECTED_EARS.NONE ? (
          <Card>
            <OptionDescription
              descriptionSpan={3}
              options={options}
              title={<Link to={`${parentPath}/options`}>Options</Link>}
            />
          </Card>
        ) : null}
      </Col>
    </Row>
  );
};

export default memo(HeaderSummary);
