import {css, SerializedStyles} from '@emotion/core';
import React, {FC, memo} from 'react';

export const headerLogoCss = css`
  height: 50px;
  @media (max-width: 992px) {
    height: 30px;
  }
  img {
    max-height: 100%;
  }
`;

const Logo: FC<{style?: SerializedStyles}> = ({style}) => (
  <div css={style}>
    <img src="/logo.png" alt="Logo" />
  </div>
);

export default memo(Logo);
