import {Descriptions} from 'antd';
import React, {FC, memo, ReactElement} from 'react';
import {
  DEAFNESS_COLORS,
  DEAFNESS_LABEL,
  GENDER_LABEL,
  usePatient,
} from '../../contexts/Quote';

export interface PatientDescriptionProps {
  descriptionSpan?: number;
  title?: string | ReactElement;
}
const PatientDescription: FC<PatientDescriptionProps> = ({
  descriptionSpan = 1,
  title = 'Références Patient',
}) => {
  const [patient] = usePatient();

  return (
    <Descriptions title={title} layout="horizontal" size="small">
      {patient?.name ? (
        <Descriptions.Item label="Nom" span={descriptionSpan}>
          {patient?.name}
        </Descriptions.Item>
      ) : null}
      {patient?.gender ? (
        <Descriptions.Item label="Sexe" span={descriptionSpan}>
          {GENDER_LABEL[patient?.gender]}
        </Descriptions.Item>
      ) : null}
      {patient?.age ? (
        <Descriptions.Item label="Âge" span={descriptionSpan}>
          {patient?.age}
        </Descriptions.Item>
      ) : null}
      {patient?.deafness ? (
        <Descriptions.Item label="Surdité" span={descriptionSpan}>
          <span
            css={{fontWeight: 'bold', color: DEAFNESS_COLORS[patient.deafness]}}
          >
            {DEAFNESS_LABEL[patient.deafness]}
          </span>
        </Descriptions.Item>
      ) : null}
    </Descriptions>
  );
};

export default memo(PatientDescription);
