import {ProductCategory} from '../../contexts/Quote';
import {
  CHASSETIR_SHAPE,
  CLASSIQUE_SHAPE,
  DEPORTE_BRAND,
  PRODUCT_CATEGORY,
  PROTECTION_SHAPE,
} from '../Options/data';

export const productCategories: ProductCategory[] = [
  {
    name: PRODUCT_CATEGORY.CLASSIQUE,
    label: 'Embouts classiques',
    img: '/categories/classique.jpg',
    products: [
      {
        name: CLASSIQUE_SHAPE.CANULE,
        label: 'Canule',
        img: '/classique/shape/canule.jpg',
      },
      {
        name: CLASSIQUE_SHAPE.CANULE_EPAULEMENT,
        label: 'Canule épaulement',
        img: '/classique/shape/canule-epaulement.jpg',
      },
      {
        name: CLASSIQUE_SHAPE.COQUILLE,
        label: 'Coquille',
        img: '/classique/shape/coquille.jpg',
      },
      {
        name: CLASSIQUE_SHAPE.DEMI_CONQUE,
        label: '\u00bd conque',
        img: '/classique/shape/demi-conque.jpg',
      },
      {
        name: CLASSIQUE_SHAPE.PHANTOMOLD,
        label: 'Phantomold',
        img: '/classique/shape/phantomold.jpg',
      },
      {
        name: CLASSIQUE_SHAPE.SILHOUETTE,
        label: 'Silhouette',
        img: '/classique/shape/silhouette.jpg',
      },
      {
        name: CLASSIQUE_SHAPE.IROS,
        label: 'Iros',
        img: '/classique/shape/iros.jpg',
      },
    ],
  },
  {
    name: PRODUCT_CATEGORY.DEPORTE,
    label: 'Embouts déportés ou M Tubes',
    img: '/categories/deporte.jpg',
    products: [
      {
        name: DEPORTE_BRAND.SIEMENS,
        label: 'Siemens',
        img: '/deporte/brand/siemens.jpg',
      },
      {
        name: DEPORTE_BRAND.OTICON,
        label: 'Oticon',
        img: '/deporte/brand/oticon.jpg',
      },
      {
        name: DEPORTE_BRAND.BELTONE,
        label: 'Beltone',
        img: '/deporte/brand/beltone.jpg',
      },
      {
        name: DEPORTE_BRAND.WIDEX,
        label: 'Widex',
        img: '/deporte/brand/widex.jpg',
      },
      {
        name: DEPORTE_BRAND.PHONAK,
        label: 'Phonak',
        img: '/deporte/brand/phonak.jpg',
      },
      {
        name: DEPORTE_BRAND.STARKEY,
        label: 'Starkey',
        img: '/deporte/brand/starkey.png',
      },
      {
        name: DEPORTE_BRAND.COQUE_INTRA,
        label: 'Coque intra',
        img: '/deporte/brand/coque-intra.jpg',
      },
    ],
  },
  {
    name: PRODUCT_CATEGORY.PROTECTION,
    label: 'Protections',
    img: '/categories/protection.jpg',
    products: [
      {
        name: PROTECTION_SHAPE.EAR,
        label: 'EAR Monitor',
        img: '/protection/shape/ear.jpg',
      },
      {
        name: PROTECTION_SHAPE.AQUASON,
        label: 'Aquason',
        img: '/protection/shape/aquason.jpg',
      },
      {
        name: PROTECTION_SHAPE.PASSTOP_EP2,
        label: 'PassTop EP2',
        img: '/protection/shape/passtop-ep2.jpg',
      },
      {
        name: PROTECTION_SHAPE.PIANISSIMO,
        label: 'Pianissimo',
        img: '/protection/shape/pianissimo.jpg',
      },
      {
        name: PROTECTION_SHAPE.PASSTOP_T,
        label: 'PassTop T',
        img: '/protection/shape/passtop-t.jpg',
      },
      {
        name: PROTECTION_SHAPE.OBTURATEUR,
        label: 'Obturateur',
        img: '/protection/shape/obturateur.jpg',
      },
      {
        name: PROTECTION_SHAPE.SOMMEIL,
        label: 'Embout sommeil',
        img: '/protection/shape/sommeil.jpg',
      },
      {
        name: PROTECTION_SHAPE.AQUASTOP,
        label: 'Aquastop',
        subLabel: 'Obturateur silicone flottant',
        img: '/protection/shape/aquastop.jpg',
      },
    ],
  },
  {
    name: PRODUCT_CATEGORY.CHASSE_TIR,
    label: 'Chasse & Tir',
    img: '/categories/chasse-tir.jpg',
    products: [
      {
        name: CHASSETIR_SHAPE.PASSTOP_TIR_CHASSE,
        label: 'PassTop Tir & Chasse',
        img: '/chassetir/shape/passtop-tir-chasse.jpg',
      },
      {
        name: CHASSETIR_SHAPE.STOPGUN_E,
        label: 'StopGun E',
        img: '/chassetir/shape/stopgun-e.jpg',
      },
      {
        name: CHASSETIR_SHAPE.STOPGUN_FLEX,
        label: 'StopGun Flex',
        img: '/chassetir/shape/stopgun-flex.jpg',
      },
      {
        name: CHASSETIR_SHAPE.STOPGUN,
        label: 'StopGun',
        img: '/chassetir/shape/stopgun.jpg',
      },
    ],
  },
];
