import {css} from '@emotion/core';
import {Checkbox, Radio, Row, Tooltip} from 'antd';
import {darken} from 'polished';
import React, {
  MouseEvent,
  MouseEventHandler,
  ReactElement,
  ReactNode,
} from 'react';
import {colors, typedForwardRef, typedMemo} from './render';

const cssButtonContainer = css`
  --color: black;
  --border-color: #d9d9d9;
  --background-color: white;
  --hover-background-color: ${darken(0.1, 'white')};

  --selected-background-color: ${colors.primary};
  --selected-hover-background-color: ${darken(0.1, colors.primary)};
  --selected-border-color: ${colors.primary};

  --border-radius: 5px;

  > label {
    position: relative;
    cursor: pointer;
    color: var(--color);
    border: solid 1px var(--border-color);
    background-color: var(--background-color);
    user-select: none;

    &.size-sm {
      padding: 1px 1.5rem;
      font-size: 15px;
      line-height: 15px;
      .anticon {
        line-height: 1.5rem;
      }
    }

    &.size-md {
      padding: 0.5rem 2rem;
      font-size: 25px;
      .anticon {
        font-size: unset;
        line-height: 2rem;
      }
    }

    &.size-lg {
      padding: 1.5rem 4rem;
      @media (max-width: 992px) {
        padding: 1rem 2rem;
      }
      font-size: 30px;
    }

    .anticon {
      font-size: unset;
    }

    :hover {
      background-color: var(--hover-background-color);
    }

    &.selected {
      border-color: var(--selected-border-color);

      :hover {
        background-color: var(--selected-hover-background-color);
      }
    }

    &.disabled {
      cursor: default;
    }

    &:first-of-type {
      border-top-left-radius: var(--border-radius);
      border-bottom-left-radius: var(--border-radius);
    }
    &:last-of-type {
      border-top-right-radius: var(--border-radius);
      border-bottom-right-radius: var(--border-radius);
    }

    .title {
      font-size: 15px;
      line-height: 15px;
      padding: 3px;
      top: 0;
      right: 0;
      position: absolute;
    }

    .ant-checkbox-wrapper,
    .ant-radio-wrapper {
      top: calc(50% - (21px / 2));
      margin-right: 0;
      position: absolute;
      right: auto;
      left: 3px;
    }
    &:first-of-type {
      .title {
        right: auto !important;
        left: 0;
      }
      .ant-checkbox-wrapper,
      .ant-radio-wrapper {
        left: auto !important;
        right: 3px;
      }
    }

    &.size-lg {
      .ant-checkbox-wrapper,
      .ant-radio-wrapper {
        zoom: 1.5;
        left: 5px;
        transform-origin: center;
      }
      &:first-of-type {
        .ant-checkbox-wrapper,
        .ant-radio-wrapper {
          right: 5px;
        }
      }
    }
  }
`;

export interface ButtonCheckboxOption<T> {
  readonly value: T;
  readonly className?: string;
  readonly label: string | ReactNode;
  readonly title?: string;
}

export interface ButtonCheckboxGroupProps<T> {
  readonly options: ButtonCheckboxOption<T>[];
  readonly value?: T[];
  readonly onChange?: (value: T[]) => void;
  readonly maxSelectedValues?: number;
  readonly size?: 'sm' | 'md' | 'lg';
  readonly disabled?: boolean;
}

const ButtonCheckboxGroup = function ButtonCheckboxGroup<T, K = {}>(
  {
    onChange,
    value,
    options,
    maxSelectedValues = options.length,
    size = 'md',
    disabled,
  }: ButtonCheckboxGroupProps<T>,
  ref: K,
): ReactElement {
  const checkedIndexes = (value || []).reduce<number[]>((res, val) => {
    const index = options.findIndex(({value}) => value === val);
    if (index !== -1) {
      res.push(index);
    }

    return res;
  }, []);

  const onChangeHandler: (
    index: number,
  ) => MouseEventHandler<HTMLLabelElement> = (index) => (event) => {
    event.preventDefault();

    if (disabled) {
      return;
    }

    const _checkedIndex = (!checkedIndexes.includes(index)
      ? [...checkedIndexes, index]
      : checkedIndexes.filter((_index) => _index !== index)
    ).slice(-maxSelectedValues);

    // setCheckedIndexes(_checkedIndex);
    if (!onChange) {
      return;
    }

    const checkedValues = options
      .filter((_, _index) => _checkedIndex.includes(_index))
      .map(({value}) => value);

    onChange(checkedValues);
  };

  return (
    // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
    // @ts-ignore
    <Row type="flex" ref={ref} css={cssButtonContainer}>
      {options.map(({label, className, title}, index) => {
        const inputProps = {
          disabled,
          onClick: (event: MouseEvent<HTMLElement>) => event.preventDefault(),
          checked: checkedIndexes.includes(index),
        };

        const classList = [
          className,
          checkedIndexes.includes(index) ? 'selected' : '',
          `size-${size}`,
          disabled ? 'disabled' : '',
        ]
          .filter((className) => !!className)
          .join(' ');

        return (
          <Tooltip key={index} title={(size !== 'lg' && title) || null}>
            <label className={classList} onClick={onChangeHandler(index)}>
              {title && size === 'lg' ? (
                <span className="title">{title}</span>
              ) : null}
              {maxSelectedValues === 1 ? (
                <Radio {...inputProps} />
              ) : (
                <Checkbox {...inputProps} />
              )}
              {label}
            </label>
          </Tooltip>
        );
      })}
    </Row>
  );
};

export default typedMemo(
  typedForwardRef(ButtonCheckboxGroup),
  (
    {value: prevValue, disabled: prevDisabled},
    {value: newValue, disabled: newDisabled},
  ) =>
    prevValue?.toString() === newValue?.toString() &&
    prevDisabled === newDisabled,
);
