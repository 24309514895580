import {css, SerializedStyles} from '@emotion/core';
import {Button, Icon, Radio, Upload} from 'antd';
import {RadioChangeEvent} from 'antd/lib/radio/interface';
import bytes from 'bytes';
import React, {FC, memo} from 'react';
import {
  QuoteTransfer,
  transferFabricationMethod,
  TransferMethod,
  transferMethodLabel,
  transferMethodOrder,
  useQuote,
  useTransfer,
} from '../../contexts/Quote';
import {getNumericEnumValues} from './util';

const uploadListCss = (
  initialEditPrintsLength: number,
): SerializedStyles => css`
  .ant-upload-list {
    max-width: 250px;

    > div:nth-of-type(-n + ${initialEditPrintsLength})
      .ant-upload-list-item-card-actions {
      display: none;
    }
  }
`;

const transferMethodsValues = getNumericEnumValues(TransferMethod).sort(
  (t1, t2) => transferMethodOrder[t1] - transferMethodOrder[t2],
);

const Prints: FC = () => {
  const [{fabricationMethod}] = useQuote();
  const [{method, prints}, setTransfer] = useTransfer();

  const handleMethodChange = ({
    target: {value: method},
  }: RadioChangeEvent): void => {
    const _transfer: Partial<QuoteTransfer> = {method};
    if (method !== TransferMethod.UPLOAD) {
      Object.assign(_transfer, {
        prints: [],
        _antPrints: [],
      });
    }

    setTransfer(_transfer);
  };

  const totalFileSize = prints.reduce(
    (_sum, {originFileObj: file}) => _sum + (file?.size || 0),
    0,
  );

  const initialEditPrints = prints.filter(({uid}) => uid.startsWith('-'));

  const availableTransferMethods = fabricationMethod
    ? transferMethodsValues.filter(
        (value) => transferFabricationMethod[value] === fabricationMethod,
      )
    : transferMethodsValues;

  return (
    <div>
      <h3>Transfert empreintes</h3>
      <Radio.Group
        disabled={availableTransferMethods.length < 2}
        value={method}
        onChange={handleMethodChange}
        css={css`
          * {
            white-space: normal;
          }
        `}
      >
        {transferMethodsValues.map((method) => (
          <Radio
            value={method}
            key={method}
            disabled={!availableTransferMethods.includes(method)}
          >
            {transferMethodLabel[method]}
          </Radio>
        ))}
      </Radio.Group>
      {method === TransferMethod.UPLOAD ? (
        <Upload
          beforeUpload={() => false}
          onChange={({fileList: files}): void => setTransfer({prints: files})}
          fileList={prints}
          multiple={true}
          css={uploadListCss(initialEditPrints.length)}
        >
          <Button css={{marginTop: 5}}>
            <Icon type="upload" />{' '}
            <span className="ant-form-item-required">Ajouter des fichiers</span>
          </Button>
          {totalFileSize ? (
            <span css={{marginLeft: 5}}>
              (Taille totale: {bytes(totalFileSize)})
            </span>
          ) : null}
        </Upload>
      ) : null}
    </div>
  );
};

export default memo(Prints);
