import {css, SerializedStyles} from '@emotion/core';
import {
  Button,
  Checkbox,
  Col,
  DatePicker,
  Divider,
  Form,
  Icon,
  Input,
  Radio,
  Row,
  Select,
  Slider,
  Spin,
} from 'antd';
import {CheckboxChangeEvent} from 'antd/es/checkbox';
import {ColProps} from 'antd/es/col';
import {FormComponentProps} from 'antd/lib/form/Form';
import moment, {Moment} from 'moment';
import {rgba} from 'polished';
import React, {
  FC,
  FormEventHandler,
  memo,
  ReactElement,
  useRef,
  useState,
} from 'react';
import {useAsync} from 'react-async-hook';
import {
  BaseQuoteData,
  DEAFNESS_COLORS,
  DEAFNESS_LABEL,
  FabricationMethod,
  GENDER,
  GENDER_EMOJI,
  GENDER_LABEL,
  PatientData,
  PatientFormData,
  TransferMethod,
  useQuote,
} from '../../../contexts/Quote';
import {useSession} from '../../../contexts/Session';
import ButtonRadioGroup, {
  ButtonRadioOption,
} from '../../../util/ButtonRadioGroup';
import {useClientTypeahead} from '../../News/useNewsArticleTypeahead';
import {TypeaheadClient} from '../../Orders/data';
import {MAX_COMMENT_LENGTH} from '../../Summary/Comment';
import ShippingAddressSelect from './ShippingAddressSelect';
import TransporterSelect from './TransporterSelect';

const {Option} = Select;
const {TextArea} = Input;

const genderInputOptions = Object.values(GENDER).map<ButtonRadioOption<GENDER>>(
  (gender: GENDER) => ({
    value: gender,
    title: GENDER_LABEL[gender],
    label: (
      <Icon
        component={() => (
          <span role="img" aria-labelledby={GENDER_LABEL[gender]}>
            {GENDER_EMOJI[gender]}
          </span>
        )}
      />
    ),
  }),
);

const stepColors = ['gray', ...Object.values(DEAFNESS_COLORS)];

const getGradeSliderCss = (activeIndex = -1): SerializedStyles =>
  css`
    &,
    &:hover {
      .ant-slider-rail {
        background-color: #e6e6e6;
      }

      .ant-slider-handle {
        border-color: ${stepColors[activeIndex + 1]} !important;

        &:focus {
          box-shadow: 0 0 0 5px ${rgba(stepColors[activeIndex + 1], 0.2)};
        }
      }
      .ant-slider-track {
        transition-property: border-color, transform, width, background-color;
        background-color: ${stepColors[activeIndex + 1]};
      }

      .ant-slider-dot {
        &:not(.ant-slider-dot-active) {
          opacity: 0.5;
        }

        ${Object.entries(stepColors).map(
          ([index, color]) =>
            `&:nth-of-type(${Number(index) + 1}) {border-color: ${color}}`,
        )}
      }

      .ant-slider-mark-text {
        margin-top: 5px;
        user-select: none;
        font-weight: bold;

        &:nth-of-type(1) {
          color: grey;
        }

        ${Object.entries(stepColors).map(
          ([index, color]) =>
            `&:nth-of-type(${Number(index) + 1}) {color: ${color}}`,
        )}
      }
    }
  `;

export interface PatientFormProps extends FormComponentProps<PatientFormData> {
  afterSubmit?: () => void;
  submitButton?: ReactElement;
  patientOnly?: boolean;
}

const columnProps: Partial<ColProps> = {
  span: 24,
  md: 12,
};

const getDefaultShippingDate = (): Moment => moment().add({day: 1});
const getMinShippingDate = (): Moment => moment().startOf('day');

export const asapShippingDate = '0000-00-00';

const RESULT_LIMIT = 10;

const PatientForm: FC<PatientFormProps> = ({
  form,
  afterSubmit,
  patientOnly = false,
  submitButton = (
    <Button type="primary" htmlType="submit" css={{float: 'right'}}>
      Confirmer
    </Button>
  ),
}) => {
  const [quote, setQuote] = useQuote();
  const {patient} = quote;
  const {getFieldDecorator, validateFieldsAndScroll} = form;
  const [unsetShippingDate, setUnsetShippingDate] = useState(
    quote?.shippingDate === asapShippingDate || false,
  );
  const [clientHasFocus, setClientHasFocus] = useState(false);
  const session = useSession();
  const clientRef = useRef<Select | null>(null);

  const clientId = form.getFieldValue('clientId');
  const clientZipCode = form.getFieldValue('clientZipCode');

  const {
    searchInputState: [_, setSearchInput],
    search,
    fetching,
    searchOffsetState: [searchOffset, setSearchOffset],
  } = useClientTypeahead(quote, clientZipCode, clientHasFocus);

  const waitingReasons = useAsync(
    async () =>
      (await session.fetch<{titre: string}[]>(
        'Utils',
        'getWaitingReasons',
        {},
      )) || [],
    [],
  );

  const handleSubmit: FormEventHandler<HTMLFormElement> = (event) => {
    event.preventDefault();
    validateFieldsAndScroll((err, values) => {
      if (err) {
        return;
      }

      const {
        // patient values
        name,
        gender,
        age,
        deafness,

        // quote values
        clientZipCode,
        client,
        clientId,
        boxNumber,
        comment,
        shippingDate,
        shippingAddress,
        transporter,
        waiting,
        warranty,
        fabricationMethod,
        serialNumber,
      } = values;

      const patientValues: PatientData = {
        name,
        gender,
        age,
        deafness,
      };
      const quoteValues: Partial<BaseQuoteData> = {
        clientZipCode,
        boxNumber,
        comment,
        shippingDate: shippingDate
          ? shippingDate.startOf('day').format('YYYY-MM-DD')
          : asapShippingDate,
        shippingAddress,
        transporter,
        client,
        clientId,
        waiting,
        warranty,
        fabricationMethod,
        serialNumber,
      };

      if (fabricationMethod && quote.fabricationMethod !== fabricationMethod) {
        quoteValues.transfer = {
          method:
            fabricationMethod === FabricationMethod.THREE_D
              ? TransferMethod.UPLOAD
              : TransferMethod.OCTOCLOUD,
          prints:
            fabricationMethod === FabricationMethod.THREE_D
              ? quote.transfer.prints
              : [],
        };
      }

      setQuote({
        ...quote,
        ...quoteValues,
        patient: {...patient, ...patientValues},
      });

      afterSubmit && afterSubmit();
    });
  };

  const handleUnsetShippingDateChange = ({
    target: {checked},
  }: CheckboxChangeEvent): void => {
    setUnsetShippingDate(checked);
    form.setFieldsValue({
      shippingDate: checked ? null : getDefaultShippingDate(),
    });
  };

  const handleOnSearch = (search: string): void => {
    if (!/client #/.test(search)) {
      setSearchInput(search);
    }
  };

  const resultLines = search?.result?.lines ?? [];
  const isClientSet = !!clientId;

  const resetClientInput = (offset = 0): void => {
    setSearchInput('');
    form.resetFields(['clientId', 'client', 'clientSpecificite']);
    setSearchOffset(offset);
  };

  const additionalResultCount = (search?.result?.total ?? 0) - searchOffset;
  const loadMoreOption = (
    <Option value="" key="__LOAD_MORE_VALUE">
      {fetching ? (
        <Spin size="small" />
      ) : (
        `Voir ${Math.min(
          RESULT_LIMIT,
          additionalResultCount,
        )} clients de plus (${additionalResultCount} résultats restants)`
      )}
    </Option>
  );

  const handleTargetsChange = (
    client: string,
    option: ReactElement | ReactElement[],
  ): void => {
    if (option === loadMoreOption) {
      resetClientInput(searchOffset + RESULT_LIMIT);
      // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
      // @ts-ignore
      const input: HTMLInputElement = clientRef.current?.rcSelect.inputRef;

      input.blur();
      requestAnimationFrame(() => input.click());

      return;
    }

    const clientIdMatch = client.match(/#([\d\w]+)\)/);
    const clientId = clientIdMatch ? clientIdMatch[1] : undefined;

    const currentClient = resultLines.find(({id}) => id === clientId);

    form.setFieldsValue({
      client,
      clientId,
      clientSpecificite: (currentClient?.row as TypeaheadClient)?.specificite,
    });
    form.resetFields(['transporter']);
  };

  const minShippingDate = getMinShippingDate();

  return (
    <div>
      <Form onSubmit={handleSubmit}>
        {!patientOnly ? (
          <div>
            <Row gutter={20}>
              <h2>Informations commande</h2>
              <Col {...columnProps}>
                <Form.Item label="Code postal">
                  {getFieldDecorator('clientZipCode', {
                    initialValue: quote?.clientZipCode,
                    rules: [
                      {
                        required: true,
                        message:
                          'Veuillez sélectionner le code postal du client',
                      },
                    ],
                  })(
                    <Input
                      css={{width: '100%'}}
                      autoFocus={!patientOnly}
                      onChange={() => resetClientInput()}
                      placeholder="Entrez le code postal"
                    />,
                  )}
                </Form.Item>
              </Col>
              <Col {...columnProps}>
                <Form.Item label="Client">
                  {getFieldDecorator('client', {
                    initialValue: quote?.client,
                    rules: [
                      {
                        required: true,
                        message: 'Veuillez sélectionner un client',
                      },
                    ],
                  })(
                    <Select<string>
                      // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
                      // @ts-ignore
                      ref={clientRef}
                      onFocus={() => setClientHasFocus(true)}
                      onBlur={() => setClientHasFocus(false)}
                      mode="combobox"
                      css={{width: '100%'}}
                      placeholder="Sélectionnez le client"
                      onSearch={handleOnSearch}
                      onChange={handleTargetsChange}
                      filterOption={false}
                      disabled={!clientZipCode}
                      notFoundContent={
                        fetching ? (
                          <Spin size="small" />
                        ) : (
                          `Aucun client trouvé pour le code postal ${clientZipCode}`
                        )
                      }
                    >
                      {resultLines.map(({id, label}) => (
                        <Option key={id} value={`${label} (client #${id})`}>
                          {label}
                        </Option>
                      ))}

                      {additionalResultCount > 0 ? loadMoreOption : null}
                    </Select>,
                  )}
                </Form.Item>
              </Col>
              <Col css={{display: 'none'}}>
                <Form.Item label="ClientID">
                  {getFieldDecorator('clientId', {
                    initialValue: quote?.clientId,
                  })(<Input />)}
                </Form.Item>
              </Col>
              <Col {...columnProps}>
                <Form.Item label="N° de boîte">
                  {getFieldDecorator('boxNumber', {
                    initialValue: quote?.boxNumber,
                    rules: [
                      {
                        required: true,
                        message: 'Veuillez entrer le numéro de la boîte',
                      },
                      {
                        max: 6,
                        message:
                          'Le numéro de boîte ne peut pas dépasser 6 caractères',
                      },
                    ],
                  })(<Input />)}
                </Form.Item>
              </Col>
              <Col {...columnProps}>
                <Form.Item label="Adresse de livraison">
                  {getFieldDecorator('shippingAddress', {
                    initialValue: quote?.shippingAddress,
                    rules: [
                      {
                        required: true,
                        message:
                          'Veuillez sélectionner une adresse de livraison',
                      },
                    ],
                  })(
                    <ShippingAddressSelect
                      placeholder="Adresse existante ou nouvelle"
                      disabled={!isClientSet}
                      clientId={clientId}
                    />,
                  )}
                </Form.Item>
              </Col>
              <Col {...columnProps}>
                <Form.Item label="Transporteur">
                  {getFieldDecorator('transporter', {
                    initialValue: quote?.transporter,
                    rules: [
                      {
                        required: true,
                        message: 'Veuillez sélectionner un transporteur',
                      },
                    ],
                  })(
                    <TransporterSelect
                      placeholder="Méthode de livraison"
                      disabled={!isClientSet}
                      clientId={clientId}
                    />,
                  )}
                </Form.Item>
              </Col>
              <Col {...columnProps}>
                <Form.Item label="Date de départ société">
                  {getFieldDecorator('shippingDate', {
                    initialValue: quote?.shippingDate
                      ? quote.shippingDate === asapShippingDate
                        ? undefined
                        : moment(quote.shippingDate)
                      : getDefaultShippingDate(),
                  })(
                    <DatePicker
                      format="DD/MM/YYYY"
                      disabledDate={(current) =>
                        !!current && current < minShippingDate
                      }
                      disabled={unsetShippingDate}
                      placeholder={unsetShippingDate ? asapShippingDate : ''}
                      allowClear={false}
                    />,
                  )}
                  <Checkbox
                    checked={unsetShippingDate}
                    onChange={handleUnsetShippingDateChange}
                    css={{marginLeft: 20}}
                  >
                    Date inconnue
                  </Checkbox>
                </Form.Item>
              </Col>
              <Col {...columnProps}>
                <Form.Item
                  label="En attente"
                  validateStatus={
                    form.getFieldValue('waiting') ? 'warning' : ''
                  }
                >
                  {getFieldDecorator('waiting', {
                    initialValue: quote?.waiting,
                  })(
                    <Select
                      placeholder="Raison de la mise en attente"
                      disabled={!waitingReasons?.result?.length}
                    >
                      <Option value={-1}>Pas en attente</Option>
                      {(waitingReasons?.result ?? []).map(({titre}, index) => (
                        <Option value={titre} key={index}>
                          {titre}
                        </Option>
                      ))}
                    </Select>,
                  )}
                </Form.Item>
              </Col>
              <Col {...columnProps}>
                <Form.Item label="Autre">
                  {getFieldDecorator('warranty', {
                    valuePropName: 'checked',
                    initialValue: quote?.warranty,
                  })(<Checkbox>Sous garantie</Checkbox>)}
                </Form.Item>
              </Col>
              <Col {...columnProps}>
                <Form.Item label="N° de série (gravure Pastop EP2)">
                  {getFieldDecorator('serialNumber', {
                    initialValue: quote?.serialNumber,
                  })(<Input />)}
                </Form.Item>
              </Col>
              <Col {...columnProps}>
                <Form.Item label="Méthode de Fabrication">
                  {getFieldDecorator('fabricationMethod', {
                    initialValue: quote?.fabricationMethod,
                    rules: [
                      {
                        required: true,
                        message:
                          'Veuillez sélectionner une méthode de fabrication',
                      },
                    ],
                  })(
                    <Radio.Group>
                      <Radio.Button value={FabricationMethod.THREE_D}>
                        3D
                      </Radio.Button>
                      <Radio.Button value={FabricationMethod.TRADITIONAL}>
                        Traditionnelle
                      </Radio.Button>
                    </Radio.Group>,
                  )}
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item label="Commentaire">
                  {getFieldDecorator('comment', {
                    initialValue: quote?.comment,
                    rules: [
                      {
                        max: MAX_COMMENT_LENGTH,
                        message: `Votre commentaire ne peut pas contenir plus de ${MAX_COMMENT_LENGTH} caractères`,
                      },
                    ],
                  })(
                    <TextArea
                      placeholder="Écrivez votre commentaire"
                      rows={3}
                    />,
                  )}
                  <p css={{float: 'right', height: 0}}>
                    {(form.getFieldValue('comment') ?? '').length} /{' '}
                    {MAX_COMMENT_LENGTH}
                  </p>
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item label="Spécificité">
                  {getFieldDecorator('clientSpecificite', {
                    initialValue: quote?.clientSpecificite,
                  })(
                    <TextArea
                      placeholder={
                        isClientSet
                          ? 'Aucune spécificité pour le client sélectionné'
                          : ''
                      }
                      disabled
                      rows={1}
                      autoSize={true}
                    />,
                  )}
                </Form.Item>
              </Col>
            </Row>
            <Divider />
          </div>
        ) : null}

        <Row gutter={20}>
          <h2>Informations patient</h2>

          <Col {...columnProps}>
            <Form.Item label="Nom et Prénom">
              {getFieldDecorator('name', {
                initialValue: patient?.name,
                rules: [
                  {
                    required: true,
                    message: 'Veuillez entrer le nom du patient',
                  },
                ],
              })(
                <Input
                  onBlur={({target: {value = ''}}) => {
                    if (value.trim() !== value) {
                      form.setFieldsValue({name: value.trim()});
                      form.validateFields(['name']);
                    }
                  }}
                />,
              )}
            </Form.Item>
          </Col>
          <Col {...columnProps}>
            <Form.Item label="Âge">
              {getFieldDecorator('age', {
                initialValue: patient?.age,
              })(<Input type="number" min="0" />)}
            </Form.Item>
          </Col>
          <Col {...columnProps}>
            <Form.Item label="Sexe">
              {getFieldDecorator('gender', {
                initialValue: patient?.gender,
              })(<ButtonRadioGroup options={genderInputOptions} size="sm" />)}
            </Form.Item>
          </Col>
          <Col {...columnProps}>
            <Form.Item label="Niveau de surdité">
              {getFieldDecorator('deafness', {
                initialValue: patient?.deafness,
              })(
                <Slider
                  css={[
                    getGradeSliderCss(form.getFieldValue('deafness')),
                    {maxWidth: '90%', marginLeft: 'auto', marginRight: 'auto'},
                  ]}
                  marks={{'-1': 'N/C', ...DEAFNESS_LABEL}}
                  min={-1}
                  tooltipVisible={false}
                  max={Object.values(DEAFNESS_LABEL).length - 1}
                />,
              )}
            </Form.Item>
          </Col>
          <Col span={24} md={{span: 12, offset: 12}}>
            {submitButton}
          </Col>
        </Row>
      </Form>
    </div>
  );
};

export default memo(PatientForm);
