import normal from './roboto-normal.js';
import bold from './roboto-bold.js';

export const addFont = (doc: any): void => {
  doc.addFileToVFS('roboto-normal.ttf', normal);
  doc.addFont('roboto-normal.ttf', 'roboto', 'normal');

  doc.addFileToVFS('roboto-bold.ttf', bold);
  doc.addFont('roboto-bold.ttf', 'roboto', 'bold');
};
