import {SerializedStyles} from '@emotion/core';
import {Button, Popover} from 'antd';
import {ButtonSize, ButtonType} from 'antd/lib/button/button';
import React, {FC, ReactNode, useState} from 'react';
import {typedMemo} from './render';

interface AbstractConfirmationButtonProps {
  children?: string | ReactNode;
  confirmationText?: string | ReactNode;
  confirmationButton?: string;
  callback?: () => void;
  icon?: string;
  type?: ButtonType;
  size?: ButtonSize;
  style?: SerializedStyles | null;
}

interface ConfirmationButtonWithChildrenProps
  extends AbstractConfirmationButtonProps {
  children: string | ReactNode;
}

interface ConfirmationButtonWithIconProps
  extends AbstractConfirmationButtonProps {
  children?: string | ReactNode;
  icon: string;
}

export type ConfirmationButtonProps =
  | ConfirmationButtonWithChildrenProps
  | ConfirmationButtonWithIconProps;

const ConfirmationButton: FC<ConfirmationButtonProps> = ({
  callback,
  children,
  confirmationText = 'Êtes-vous sûr(e) ?',
  confirmationButton = 'Oui',
  icon,
  type = 'default',
  size = 'default',
  style,
}) => {
  const [visible, setVisible] = useState(false);

  return (
    <Popover
      content={
        <div css={{maxWidth: '250px', textAlign: 'center'}}>
          {typeof confirmationText === 'string' ? (
            <p>{confirmationText}</p>
          ) : (
            confirmationText
          )}
          <Button
            block={true}
            type="danger"
            autoFocus={true}
            onClick={() => {
              setVisible(false);
              callback && callback();
            }}
          >
            {confirmationButton}
          </Button>
        </div>
      }
      onVisibleChange={setVisible}
      visible={visible}
      trigger="click"
    >
      <Button type={type} icon={icon} size={size} css={style}>
        {children}
      </Button>
    </Popover>
  );
};

export default typedMemo(ConfirmationButton);
