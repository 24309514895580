import {Col, Row} from 'antd';
import React, {FC, memo} from 'react';
import {
  TBoolean,
  useCurrentEars,
  useCurrentLockedEars,
} from '../../contexts/Quote';
import ButtonCheckboxGroup, {
  ButtonCheckboxOption,
} from '../../util/ButtonCheckboxGroup';
import {
  EAR,
  getSelectedEarArray,
  getSelectedEars,
  SELECTED_EARS,
  SELECTED_EARS_TEXT,
} from './static';

const earInputOptions: ButtonCheckboxOption<EAR>[] = [
  {
    value: EAR.LEFT,
    title: SELECTED_EARS_TEXT[SELECTED_EARS.LEFT_ONLY],
    label: (
      <img
        css={{maxHeight: '120px'}}
        src="/icons/ear.png"
        alt={SELECTED_EARS_TEXT[SELECTED_EARS.LEFT_ONLY]}
      />
    ),
  },
  {
    value: EAR.RIGHT,
    title: SELECTED_EARS_TEXT[SELECTED_EARS.RIGHT_ONLY],
    label: (
      <img
        css={[{maxHeight: '120px'}, {transform: 'rotateY(180deg)'}]}
        src="/icons/ear.png"
        alt={SELECTED_EARS_TEXT[SELECTED_EARS.RIGHT_ONLY]}
      />
    ),
  },
];

const EarsInput: FC = () => {
  const [ears, setEars] = useCurrentEars();
  const [lockedEars, setLockedEars] = useCurrentLockedEars();

  const onEarsChange = (earValues: EAR[]): void => {
    const _ears = [EAR.LEFT, EAR.RIGHT].map((ear) =>
      earValues.includes(ear),
    ) as TBoolean;

    setEars(_ears);

    if (setLockedEars && getSelectedEars(_ears) !== SELECTED_EARS.BOTH) {
      setLockedEars(false);
    }
  };

  return (
    <div>
      <Row type="flex" justify="center" css={{margin: '5px 0'}}>
        <Col>
          <ButtonCheckboxGroup
            disabled={lockedEars}
            value={getSelectedEarArray(ears)}
            options={earInputOptions}
            // FIXME https://github.com/DefinitelyTyped/DefinitelyTyped/issues/35834
            // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
            // @ts-ignore
            onChange={onEarsChange}
            size="lg"
          />
        </Col>
      </Row>
    </div>
  );
};

export default memo(EarsInput);
