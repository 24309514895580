import {Button, Col, DatePicker, Form, Input, Row, Select} from 'antd';
import {FormComponentProps} from 'antd/lib/form/Form';
import moment, {Moment} from 'moment';
import React, {FC, FormEventHandler, memo} from 'react';
import {UndefinedTuple} from '../../contexts/Quote';
import {ORDER_STATE, ORDER_STATE_INFO} from './data';

const {RangePicker} = DatePicker;
const {Option} = Select;

type MomentTuple = UndefinedTuple<Moment>;

export interface FilterData {
  orderDateRange: MomentTuple;
  state?: ORDER_STATE;
  productDescription?: string;
}

export interface OrdersFilterFormProps extends FormComponentProps<FilterData> {
  value?: FilterData;
  onFilter?: (values: FilterData) => void;
  onResetFilter?: () => void;
}

const OrdersFilterForm: FC<OrdersFilterFormProps> = ({
  form,
  onFilter,
  onResetFilter,
  value,
}) => {
  const {getFieldDecorator, validateFieldsAndScroll} = form;

  const handleSubmit: FormEventHandler<HTMLFormElement> = (event) => {
    event.preventDefault();
    validateFieldsAndScroll((err, values) => {
      if (err) {
        return;
      }

      if (values.orderDateRange?.[0]) {
        values.orderDateRange[0] = values.orderDateRange[0].startOf('day');
      }
      if (values.orderDateRange?.[1]) {
        values.orderDateRange[1] = values.orderDateRange[1].endOf('day');
      }

      onFilter && onFilter(values);
    });
  };

  return (
    <Form onSubmit={handleSubmit}>
      <h2>Filtre</h2>
      <Row type="flex" justify="space-around" gutter={20}>
        <Col>
          <Form.Item label="Date de commande">
            {getFieldDecorator('orderDateRange', {
              initialValue: value?.orderDateRange,
            })(<RangePicker />)}
          </Form.Item>
        </Col>
        <Col>
          <Form.Item label="État">
            {getFieldDecorator('state', {
              initialValue: value?.state,
            })(
              <Select placeholder="Choisir l'état" css={{minWidth: 150}}>
                {Object.entries(ORDER_STATE_INFO).map(
                  ([value, {label, color}], index) => (
                    <Option value={value} key={index}>
                      <span css={{color}}>{label}</span>
                    </Option>
                  ),
                )}
              </Select>,
            )}
          </Form.Item>
        </Col>
        <Col>
          <Form.Item label="Description produit">
            {getFieldDecorator('productDescription', {
              initialValue: value?.productDescription,
            })(<Input />)}
          </Form.Item>
        </Col>
        <Col css={{textAlign: 'right'}}>
          <Row
            type="flex"
            justify="start"
            css={{flexDirection: 'column-reverse', height: '75px'}}
          >
            <Col>
              <Button type="primary" htmlType="submit">
                Filtrer
              </Button>
            </Col>
            <Col css={{marginBottom: 8}}>
              <Button type="default" htmlType="submit" onClick={onResetFilter}>
                Réinitialiser les filtres
              </Button>
            </Col>
          </Row>
        </Col>
      </Row>
    </Form>
  );
};

export default memo(OrdersFilterForm);
