import {Button, Card, Col, Divider, Form, Row, Tooltip} from 'antd';
import React, {FC, memo, useState} from 'react';
import PatientForm from '../Quote/patient/Form';
import OrderDescription from './OrderDescription';
import PatientDescription from './PatientDescription';

export interface PatientProps {
  patientOnly?: boolean;
}

const Patient: FC<PatientProps> = ({patientOnly = false}) => {
  const [editMode, setEditMode] = useState(false);
  const WrappedPatientForm = Form.create({name: 'patientForm'})((props) => (
    <PatientForm {...props} afterSubmit={() => setEditMode(false)} patientOnly={patientOnly} />
  ));

  return (
    <Card
      title={
        <Row type="flex" justify="space-between">
          <Col css={{lineHeight: '32px'}}>Informations Patient</Col>
          <Col>
            <Tooltip
              title={
                editMode
                  ? 'Annuler les modifications'
                  : 'Modifier infos patient'
              }
            >
              <Button
                type={editMode ? 'default' : 'primary'}
                onClick={() => setEditMode(!editMode)}
                css={{marginRight: '2px'}}
                icon="edit"
              >
                {editMode ? 'Annuler' : null}
              </Button>
            </Tooltip>
          </Col>
        </Row>
      }
    >
      {editMode ? (
        <WrappedPatientForm />
      ) : (
        <div>
          {!patientOnly ? (
            <div>
              <OrderDescription title="" />
              <Divider />
            </div>
          ) : null}
          <div>
            <PatientDescription title="" />
          </div>
        </div>
      )}
    </Card>
  );
};

export default memo(Patient);
