import {ReactNode} from 'react';
import {
  BaseQuoteData,
  BaseQuoteLine,
  DEAFNESS_LABEL,
  FabricationMethod,
  fabricationMethodRule,
  Option,
  Product,
  TBoolean,
  transferFabricationMethod,
  TransferMethod,
} from '../../contexts/Quote';
import {User, WakandaService} from '../../util/fetch-wakanda';
import {SELECTED_EARS_EMOJIS} from '../../util/render';
import {
  CHASSETIR_SHAPE,
  CLASSIQUE_CANULE_EPAULEMENT_MATERIAL,
  CLASSIQUE_CANULE_MATERIAL,
  CLASSIQUE_COQUILLE_MATERIAL,
  CLASSIQUE_DEMI_CONQUE_MATERIAL,
  CLASSIQUE_IROS_MATERIAL,
  CLASSIQUE_PHANTOMOLD_MATERIAL,
  CLASSIQUE_SILHOUETTE_MATERIAL,
  CORD_COLOR,
  cordColorRules,
  DEPORTE_CANULE_MATERIAL,
  DEPORTE_SHAPE,
  EAR_BUD,
  EARWAX_SHIELDING,
  earwaxShieldingRules,
  EVENT,
  eventRules,
  FILTER,
  MISCELLANEOUS,
  miscellaneousRules,
  OPTION,
  OPTION_TUBE,
  optionRules,
  optionTubeRules,
  PRODUCT_CATEGORY,
  PROTECTION_OBSTRUATEUR_SHAPE,
  PROTECTION_OBTURATEUR_MATERIAL,
  PROTECTION_SHAPE,
  PROTECTION_SOMMEIL_SHAPE,
  SURFACE,
  surfaceRules,
  TUBE,
  tubeRules,
  TYPE,
} from '../Options/data';
import {getSelectedEars, SELECTED_EARS} from '../Options/static';
import {Details, Order, OREILLE} from '../Orders/data';

function assert(condition: any, msg?: string): asserts condition {
  if (!condition) {
    throw new Error(msg);
  }
}

interface Bague {
  marque: string;
  ecouteur: string;
  id: string;
  qty: number;
  selected: TBoolean;
}

export interface Article {
  ID: string;
  code: string;
  regle: string;
  libelle: string;
  miniCde: number;
  id_famille: string;
  id_sousFamille: string;
  nonRemisable: boolean;
  uniteVente: string;
  moisGarantie: number;
  prix?: number | {key: number; value: number}[];
  remise?: number | {key: number; value: number}[];
  bagues?: {bagues: Bague[]};
}

export interface ArticleListItem {
  ID: string;
  key: number;
  num: number;
  code: string;
  libelle: string;
  oreille: ReactNode;
  qty: number;
  unit: string;
  totalHT: number;
  selected?: TBoolean;
  unitPrice?: number;
  discount?: number;
  netPrice?: number;
  totalPrice?: number;
  bagues?: {bagues: Bague[]};
}

interface TempOrder {
  clientID?: string;
  TRP: string;
  dateLivrSouh: number;
  message: string;
  livr_id: string;
  livr_rs: string;
  livr_adr: string;
  livr_cp: string;
  livr_ville: string;
  livr_pays: string;
  contact: string;
  email: string;
  details: Details[];
  ATT_attente: boolean;
  ATT_motif?: string;
  noBoite?: string;
  modeEmpreinte: number;
}

const getOptionRule = (baseRule: string, type: TYPE, name: string): string => {
  if (name === '') {
    return '';
  }

  let rule = baseRule;

  switch (type) {
    case TYPE.EVENT:
      const eventRule = eventRules[name as EVENT];
      if (!eventRule) {
        return '';
      }

      rule += eventRule;
      break;
    case TYPE.SURFACE:
      const surfaceRule = surfaceRules[name as SURFACE];
      if (!surfaceRule) {
        return '';
      }

      rule += surfaceRule;
      break;
    case TYPE.TUBE:
      const tubeRule = tubeRules[name as TUBE];
      if (!tubeRule) {
        return '';
      }

      rule += tubeRule;
      break;
    case TYPE.OPTION_TUBE:
      const optionTubeRule = optionTubeRules[name as OPTION_TUBE];
      if (!optionTubeRule) {
        return '';
      }

      rule += optionTubeRule;
      break;
    case TYPE.EARWAX_SHIELDING:
      const earwaxShieldingRule =
        earwaxShieldingRules[name as EARWAX_SHIELDING];
      if (!earwaxShieldingRule) {
        return '';
      }

      rule += earwaxShieldingRule;
      break;
    case TYPE.MISCELLANEOUS:
      const miscellaneousRule = miscellaneousRules[name as MISCELLANEOUS];
      if (!miscellaneousRule) {
        return '';
      }

      rule += miscellaneousRule;
      break;
    case TYPE.EAR_BUD_ASSEMBLY:
      rule += 'OPT_montage';
      break;
    case TYPE.FLOAT:
    case TYPE.CAMO:
      rule += 'OPT_option01';
      break;
    case TYPE.CORD:
      rule += 'OPT_aquastopC';
      break;
    case TYPE.OPTION:
      const optionRule = optionRules[name as OPTION];
      if (!optionRule) {
        return '';
      }

      rule += optionRule;
      break;
    case TYPE.CORD_COLOR:
      const cordColorRule = cordColorRules[name as CORD_COLOR];
      if (!cordColorRule) {
        return '';
      }

      rule += cordColorRule;
      break;
    case TYPE.ENGRAVING:
      rule += 'OPT_option06';
      break;
    default:
      return '';
  }

  return rule + ':/';
};

const readPriceDiscount = (
  art: Article,
  qty: number,
  type: 'prix' | 'remise',
): number => {
  const price = art[type];

  if (!price) {
    return 0;
  }

  if (typeof price === 'number') {
    return price;
  }

  if (Array.isArray(art[type]) && price.length > 0) {
    if (qty <= art.miniCde) {
      // Take the minimum
      return price[0].value;
    }

    let best = 0;
    let cpt = 0;
    while (price.length > cpt && price[cpt].key <= qty) {
      best = price[cpt].value;
      cpt++;
    }

    return best;
  }

  return 0;
};

const getShape = (line: BaseQuoteLine, selected: TBoolean) =>
  line.options.selected.find(
    (opt) =>
      opt.type === TYPE.SHAPE &&
      ((opt.selected[0] && opt.selected[0] === selected[0]) ||
        (opt.selected[1] && opt.selected[1] === selected[1])),
  );

const getShapeRule = (
  baseRule: string,
  line: BaseQuoteLine,
  selected: TBoolean,
): string => {
  if (baseRule === '/EMB_EPR_') {
    baseRule += 'EBT_';
  }

  const shape = getShape(line, selected);
  if (!shape) {
    return '';
  }

  let shapeRule = baseRule;

  switch (shape.name) {
    case DEPORTE_SHAPE.CANULE:
      shapeRule += 'FRM_canule';
      break;
    case DEPORTE_SHAPE.EPAULEMENT_COURT:
    case DEPORTE_SHAPE.EPAULEMENT_MOYEN:
    case DEPORTE_SHAPE.EPAULEMENT_LONG:
      shapeRule += 'FRM_canuleEpaule';
      break;
    case DEPORTE_SHAPE.COQUILLE:
      shapeRule += 'FRM_coquille';
      break;
    case DEPORTE_SHAPE.DEMI_CONQUE:
      shapeRule += 'FRM_demiConque';
      break;
    case DEPORTE_SHAPE.PHANTOMOLD:
      shapeRule += 'FRM_phantomold';
      break;
    case DEPORTE_SHAPE.SILOUETTE:
      shapeRule += 'FRM_silhouette';
      break;

    case PROTECTION_OBSTRUATEUR_SHAPE.CONQUE:
      shapeRule += 'FRM_conque';
      break;
    case PROTECTION_OBSTRUATEUR_SHAPE.CANULE:
      shapeRule += 'FRM_canule';
      break;
  }

  return shapeRule + ':/';
};

const getMaterialRules = (
  method: TransferMethod,
  baseRule: string,
  line: BaseQuoteLine,
  microSelected: TBoolean,
): {selected: TBoolean; rule: string}[] => {
  if (baseRule === '/EMB_EPR_') {
    baseRule += 'EBT_';
  }

  const materials = line.options.selected.filter(
    (opt) => opt.type === TYPE.MATERIAL,
  );

  const methodPrefix = fabricationMethodRule[transferFabricationMethod[method]];

  return (materials.map((material) => {
    let micro = false;

    for (const index in material.selected) {
      if (microSelected[index] === true && material.selected[index] === true) {
        micro = true;
      }
    }

    if (micro === true) {
      baseRule = '/EMB_MCT_';
    }

    let materialRule = methodPrefix + baseRule;

    switch (material.name) {
      case CLASSIQUE_CANULE_MATERIAL.RESINE_ACRYLIQUE:
        materialRule += 'MAT_acrylique:' + baseRule + 'FRM_canule:/';
        break;
      case CLASSIQUE_CANULE_MATERIAL.SILICON_40:
        materialRule += 'MAT_silicone40:' + baseRule + 'FRM_canule:/';
        break;
      case CLASSIQUE_CANULE_MATERIAL.SILICON_70:
        materialRule += 'MAT_silicone70:' + baseRule + 'FRM_canule:/';
        break;
      case CLASSIQUE_CANULE_MATERIAL.THERMOTEC:
        materialRule += 'MAT_thermotec:' + baseRule + 'FRM_canule:/';
        break;

      case CLASSIQUE_CANULE_EPAULEMENT_MATERIAL.RESINE_ACRYLIQUE:
        materialRule += 'MAT_acrylique:' + baseRule + 'FRM_canuleEpaule:/';
        break;
      case CLASSIQUE_CANULE_EPAULEMENT_MATERIAL.SILICON_40:
        materialRule += 'MAT_silicone40:' + baseRule + 'FRM_canuleEpaule:/';
        break;
      case CLASSIQUE_CANULE_EPAULEMENT_MATERIAL.SILICON_70:
        materialRule += 'MAT_silicone70:' + baseRule + 'FRM_canuleEpaule:/';
        break;
      case CLASSIQUE_CANULE_EPAULEMENT_MATERIAL.THERMOTEC:
        materialRule += 'MAT_thermotec:' + baseRule + 'FRM_canuleEpaule:/';
        break;

      case CLASSIQUE_COQUILLE_MATERIAL.RESINE_ACRYLIQUE:
        materialRule += 'MAT_acrylique:' + baseRule + 'FRM_coquille:/';
        break;
      case CLASSIQUE_COQUILLE_MATERIAL.SILICON_40:
        materialRule += 'MAT_silicone40:' + baseRule + 'FRM_coquille:/';
        break;
      case CLASSIQUE_COQUILLE_MATERIAL.SILICON_70:
        materialRule += 'MAT_silicone70:' + baseRule + 'FRM_coquille:/';
        break;
      case CLASSIQUE_COQUILLE_MATERIAL.THERMOTEC:
        materialRule += 'MAT_thermotec:' + baseRule + 'FRM_coquille:/';
        break;

      case CLASSIQUE_DEMI_CONQUE_MATERIAL.RESINE_ACRYLIQUE:
        materialRule += 'MAT_acrylique:' + baseRule + 'FRM_demiConque:/';
        break;
      case CLASSIQUE_DEMI_CONQUE_MATERIAL.SILICON_40:
        materialRule += 'MAT_silicone40:' + baseRule + 'FRM_demiConque:/';
        break;
      case CLASSIQUE_DEMI_CONQUE_MATERIAL.SILICON_70:
        materialRule += 'MAT_silicone70:' + baseRule + 'FRM_demiConque:/';
        break;
      case CLASSIQUE_DEMI_CONQUE_MATERIAL.THERMOTEC:
        materialRule += 'MAT_thermotec:' + baseRule + 'FRM_demiConque:/';
        break;

      case CLASSIQUE_PHANTOMOLD_MATERIAL.RESINE_ACRYLIQUE:
        materialRule += 'MAT_acrylique:' + baseRule + 'FRM_phantomold:/';
        break;
      case CLASSIQUE_PHANTOMOLD_MATERIAL.SILICON_40:
        materialRule += 'MAT_silicone40:' + baseRule + 'FRM_phantomold:/';
        break;
      case CLASSIQUE_PHANTOMOLD_MATERIAL.SILICON_70:
        materialRule += 'MAT_silicone70:' + baseRule + 'FRM_phantomold:/';
        break;
      case CLASSIQUE_PHANTOMOLD_MATERIAL.THERMOTEC:
        materialRule += 'MAT_thermotec:' + baseRule + 'FRM_phantomold:/';
        break;

      case CLASSIQUE_SILHOUETTE_MATERIAL.RESINE_ACRYLIQUE:
        materialRule += 'MAT_acrylique:' + baseRule + 'FRM_silhouette:/';
        break;
      case CLASSIQUE_SILHOUETTE_MATERIAL.SILICON_40:
        materialRule += 'MAT_silicone40:' + baseRule + 'FRM_silhouette:/';
        break;
      case CLASSIQUE_SILHOUETTE_MATERIAL.SILICON_70:
        materialRule += 'MAT_silicone70:' + baseRule + 'FRM_silhouette:/';
        break;
      case CLASSIQUE_SILHOUETTE_MATERIAL.THERMOTEC:
        materialRule += 'MAT_thermotec:' + baseRule + 'FRM_silhouette:/';
        break;

      case CLASSIQUE_IROS_MATERIAL.RESINE_ACRYLIQUE:
        materialRule += 'MAT_acrylique:' + baseRule + 'FRM_iros:/';
        break;
      case CLASSIQUE_IROS_MATERIAL.SILICON_40:
        materialRule += 'MAT_silicone40:' + baseRule + 'FRM_iros:/';
        break;
      case CLASSIQUE_IROS_MATERIAL.SILICON_70:
        materialRule += 'MAT_silicone70:' + baseRule + 'FRM_iros:/';
        break;
      case CLASSIQUE_IROS_MATERIAL.THERMOTEC:
        materialRule += 'MAT_thermotec:' + baseRule + 'FRM_iros:/';
        break;

      case DEPORTE_CANULE_MATERIAL.RESINE_DURE:
        materialRule +=
          'MAT_dure:' + getShapeRule(baseRule, line, material.selected);
        break;
      case DEPORTE_CANULE_MATERIAL.SILICON_70:
        materialRule +=
          'MAT_silicone70:' + getShapeRule(baseRule, line, material.selected);
        break;
      case DEPORTE_CANULE_MATERIAL.THERMOTEC:
        materialRule +=
          'MAT_thermotec:' + getShapeRule(baseRule, line, material.selected);
        break;

      case PROTECTION_OBTURATEUR_MATERIAL.SILICON_25:
      case PROTECTION_OBTURATEUR_MATERIAL.SILICON_40:
        materialRule += `${
          getShape(line, material.selected)?.name === PROTECTION_OBSTRUATEUR_SHAPE.CANULE
            ? 'canule'
            : 'silicone'
        }${material.name === PROTECTION_OBTURATEUR_MATERIAL.SILICON_25 ? '25' : '40'}:/`;
        break;

      default:
        return undefined;
    }

    return {selected: material.selected, rule: materialRule};
  }) as {selected: TBoolean; rule: string}[]).filter((rule) => rule);
};

export const getBaseRule = (line: BaseQuoteLine): string => {
  if (!line.category?.name) {
    return '';
  }

  let baseRule = '/EMB_';

  switch (line.category.name) {
    case PRODUCT_CATEGORY.CLASSIQUE:
      baseRule += 'RES_';
      break;
    case PRODUCT_CATEGORY.DEPORTE:
      baseRule += 'MCE_';
      break;
    case PRODUCT_CATEGORY.PROTECTION:
      baseRule += 'EPR_';
      break;
    case PRODUCT_CATEGORY.CHASSE_TIR:
      baseRule += 'ATR_';
      break;
  }

  return baseRule;
};

const getProductRules = (
  method: TransferMethod,
  baseRule: string,
  product: Product,
  selected: TBoolean,
  options: Option[],
): {selected: TBoolean; rule: string}[] => {
  if (baseRule === '/EMB_EPR_') {
    baseRule += 'EBT_';
  }

  const rules: {selected: TBoolean; rule: string}[] = [];

  const methodPrefix = fabricationMethodRule[transferFabricationMethod[method]];

  let rule = methodPrefix + baseRule;

  switch (product.name) {
    case PROTECTION_SHAPE.EAR:
      rule += 'earMonitor:/';
      rules.push({selected, rule});
      break;
    case PROTECTION_SHAPE.AQUASON:
      rule += 'aquason:/';
      rules.push({selected, rule});
      break;
    case PROTECTION_SHAPE.AQUASTOP:
      rule += 'aquastop:/';
      rules.push({selected, rule});
      break;
    case PROTECTION_SHAPE.PASSTOP_EP2:
      rule += 'passtopEP2:/';
      rules.push({selected, rule});
      break;
    case PROTECTION_SHAPE.PIANISSIMO: {
      const filterOptions = options.filter((opt) => opt.type === TYPE.FILTER);
      filterOptions.map((opt): void => {
        let optRule = rule;
        switch (opt.name) {
          case FILTER.DB_15:
            optRule += 'filtre15:/';
            break;
          case FILTER.DB_25:
            optRule += 'filtre25:/';
            break;
          case FILTER.DB_30:
            optRule += 'filtre30:/';
            break;
        }
        rules.push({
          selected: opt.selected,
          rule: optRule,
        });

        return undefined;
      });
      break;
    }
    case PROTECTION_SHAPE.PASSTOP_T:
      {
        const filterOptions = options.filter((opt) => opt.type === TYPE.FILTER);
        filterOptions.map((opt): void => {
          let optRule = rule;
          switch (opt.name) {
            case FILTER.SNR22:
              optRule += 'T3:/';
              break;
            case FILTER.SNR24:
              optRule += 'T4:/';
              break;
            case FILTER.SNR21:
              optRule += 'C2:/';
              break;
            case FILTER.SNR26:
              optRule += 'C3:/';
              break;
          }
          rules.push({
            selected: opt.selected,
            rule: optRule,
          });

          return undefined;
        });
      }
      break;
    case PROTECTION_SHAPE.SOMMEIL:
      const shapeOptions = options.filter((opt) => opt.type === TYPE.SHAPE);
      shapeOptions.map((opt): void => {
        let optRule = rule;
        switch (opt.name) {
          case PROTECTION_SOMMEIL_SHAPE.CONQUE:
            optRule += 'conque:/';
            break;
          case PROTECTION_SOMMEIL_SHAPE.CANULE:
            optRule += 'canule:/';
            break;
        }
        rules.push({
          selected: opt.selected,
          rule: optRule,
        });

        return undefined;
      });
      break;
    case CHASSETIR_SHAPE.PASSTOP_TIR_CHASSE:
      rule += 'PST_tir:/';
      rules.push({selected, rule});
      break;
    case CHASSETIR_SHAPE.STOPGUN_E:
      selected.map((enabled, index): void => {
        if (!enabled) {
          return undefined;
        }

        const optRule =
          rule + 'SPG_stopGunE' + (index === 0 ? 'OG' : 'OD') + ':/';
        rules.push({selected: [index === 0, index === 1], rule: optRule});

        return undefined;
      });
      break;
    case CHASSETIR_SHAPE.STOPGUN_FLEX:
      rule += 'SPG_stopGunFlex:/';
      rules.push({selected, rule});
      break;
    case CHASSETIR_SHAPE.STOPGUN:
      rule += 'SPG_stopGun:/';
      rules.push({selected, rule});
      break;
  }

  return rules;
};

export const getArticles = async (
  quote: BaseQuoteData,
  line: BaseQuoteLine,
  session: WakandaService,
  user: User,
): Promise<void> => {
  if (!line.category || !line.product) {
    return;
  }

  const rules: string[] = [];
  const productRules: {selected: TBoolean; rule: string}[] = [];

  const micro = line.options.selected.find(
    ({type, name}) =>
      type === TYPE.EAR_BUD &&
      [
        EAR_BUD.MICRO,
        EAR_BUD.CORDA_SQUARED,
        EAR_BUD.MINI_FIT_CORDA,
        EAR_BUD.MICRO_TUBE_ELAN_13,
        EAR_BUD.MICRO_TUBE_M_10,
        EAR_BUD.MICRO_TUBE_EASYWEAR_09_16,
        EAR_BUD.M_TUBE,
      ].includes(name as EAR_BUD),
  );

  const baseRule = getBaseRule(line);

  productRules.push(
    ...getMaterialRules(
      quote.transfer.method,
      baseRule,
      line,
      micro?.selected || [false, false],
    ),
  );

  if (!productRules.length) {
    productRules.push(
      ...getProductRules(
        quote.transfer.method,
        baseRule,
        line.product,
        line.options.ears,
        line.options.selected,
      ),
    );
  }

  rules.push(...productRules.map(({rule}) => rule));

  const optionsRulesMap: {[key: number]: string} = {};
  line.options.selected.map((opt, index): void => {
    let microSelected = false;
    for (const index in [0, 1]) {
      if (
        (micro || {selected: [false, false]}).selected[index] ===
          opt.selected[index] &&
        opt.selected[index] === true
      ) {
        microSelected = true;
      }
    }

    if (microSelected) {
      const rule = getOptionRule('/EMB_MCT_', opt.type, opt.name);
      if (!rule) {
        return undefined;
      }
      optionsRulesMap[index] = rule;
      if (!rules.includes(rule)) {
        rules.push(rule);
      }
      return undefined;
    }

    const rule = getOptionRule(baseRule, opt.type, opt.name);
    if (!rule) {
      return undefined;
    }
    optionsRulesMap[index] = rule;
    if (!rules.includes(rule)) {
      rules.push(rule);
    }
    if (!micro) {
      return undefined;
    }
    return undefined;
  });

  const filteredRules = rules.filter((rule) => rule);

  if (filteredRules.length === 0) {
    return;
  }

  line.articles = [];
  line.total = 0;
  const articles = await session.fetch<Article[]>(
    'Articles',
    'getArticlesFromRegles',
    {rules: filteredRules, clientId: quote.clientId},
  );

  if (!articles || articles.length === 0) {
    return;
  }

  let totalPrice = 0;

  const productArticles = articles.filter(
    (art) =>
      productRules.filter(({rule}) => art.regle.includes(rule)).length > 0,
  );

  const bagues: Bague[] = [];

  // eslint-disable-next-line array-callback-return
  productArticles.map((article, index): void => {
    const opt = productRules.find(({rule}) => rule === article.regle);

    if (!opt) {
      return undefined;
    }

    const qty = opt.selected.filter((ear) => ear === true).length;

    const art: ArticleListItem = {
      ID: article.ID,
      bagues: article.bagues,
      key: index,
      num: index + 1,
      code: article.code,
      libelle: article.libelle,
      selected: opt.selected,
      oreille: SELECTED_EARS_EMOJIS[getSelectedEars(opt.selected)],
      qty,
      unit: article.uniteVente,
      totalHT: readPriceDiscount(article, qty, 'prix'),
    };

    if (user && user.blChiffre) {
      const price = art.totalHT;
      const discount =
        (quote.warranty ? 100 : readPriceDiscount(article, art.qty, 'remise')) /
        100;
      const netPrice = price * (1 - discount);

      art.unitPrice = price;
      art.discount = discount;
      art.netPrice = netPrice;
      art.totalPrice = netPrice * art.qty;

      totalPrice += art.totalPrice;
    }

    if (!line.articles) {
      line.articles = [];
    }

    line.articles.push(art);

    if (
      article.bagues &&
      article.bagues.bagues &&
      article.bagues.bagues.length > 0
    ) {
      const artBagues = article.bagues.bagues;
      const marque =
        (line.category?.name === PRODUCT_CATEGORY.DEPORTE &&
          line.product?.label) ||
        null;
      opt.selected.map((sel, idx): void => {
        if (!sel) {
          return undefined;
        }

        const ecouteur = getLabel(getOption(line, idx, TYPE.EAR_BUD)?.label);

        const bague = artBagues.find(
          (bague) =>
            bague.marque.toLowerCase() === marque?.toLowerCase() &&
            bague.ecouteur.toLowerCase() === ecouteur?.toLowerCase(),
        );

        if (bague) {
          bagues.push({...bague, qty, selected: opt.selected});
        }

        return undefined;
      });
    }
  });

  let idx = line.articles.length;

  line.articles.push(
    ...(Object.keys(optionsRulesMap)
      .map((key: string, num: number): ArticleListItem | undefined => {
        const index = parseInt(key);
        const opt = line.options.selected[index];
        const rule = optionsRulesMap[index];
        const article = articles.find((art) => art.regle.includes(rule));

        if (!article) {
          return undefined;
        }

        const qty = opt.selected.filter((sel) => sel === true).length;
        const art: ArticleListItem = {
          ID: article.ID,
          bagues: article.bagues,
          key: idx + num,
          num: idx + num + 1,
          code: article.code,
          libelle: article.libelle,
          oreille: SELECTED_EARS_EMOJIS[getSelectedEars(opt.selected)],
          qty,
          unit: article.uniteVente,
          totalHT: readPriceDiscount(article, qty, 'prix'),
        };

        if (user && user.blChiffre) {
          const price = art.totalHT;
          const discount =
            (quote.warranty
              ? 100
              : readPriceDiscount(article, art.qty, 'remise')) / 100;
          const netPrice = price * (1 - discount);

          art.unitPrice = price;
          art.discount = discount;
          art.netPrice = netPrice;
          art.totalPrice = netPrice * art.qty;

          totalPrice += art.totalPrice;
        }

        return art;
      })
      .filter((art) => art) as ArticleListItem[]),
  );

  idx = line.articles.length;

  const bagueArtItems: ArticleListItem[] = articles
    .filter((article) => bagues.find((bague) => bague.id === article.ID))
    .map((article, num: number) => {
      const bague = bagues.find((bague) => bague.id === article.ID);
      if (!bague) {
        return undefined;
      }

      const qty = bague.qty;

      const art: ArticleListItem = {
        ID: article.ID,
        bagues: article.bagues,
        key: idx + num,
        num: idx + num + 1,
        code: article.code,
        libelle: article.libelle,
        oreille: SELECTED_EARS_EMOJIS[getSelectedEars(bague.selected)],
        qty,
        unit: article.uniteVente,
        totalHT: readPriceDiscount(article, qty, 'prix'),
      };

      if (user && user.blChiffre) {
        const price = art.totalHT;
        const discount =
          (quote.warranty
            ? 100
            : readPriceDiscount(article, art.qty, 'remise')) / 100;
        const netPrice = price * (1 - discount);

        art.unitPrice = price;
        art.discount = discount;
        art.netPrice = netPrice;
        art.totalPrice = netPrice * art.qty;

        totalPrice += art.totalPrice;
      }

      return art;
    }) as ArticleListItem[];

  line.articles.push(...bagueArtItems);

  line.total = totalPrice;
};

const getLabel = (label: string | undefined): string | null => {
  if (!label) {
    return null;
  }

  return label;
};

const getOptions = (
  line: BaseQuoteLine,
  index: number,
  optType: TYPE,
): Option[] | undefined => {
  const opts = line.options.selected.filter(
    ({type, selected}) => type === optType && selected[index],
  );

  return opts;
};

const getOption = (
  line: BaseQuoteLine,
  index: number,
  optType: TYPE,
  optName?: string,
): Option | undefined => {
  const opt = line.options.selected.find(
    ({name, type, selected}) =>
      (!optName || name === optName) && type === optType && selected[index],
  );

  return opt;
};

const getOrderDetails = (quote: BaseQuoteData): Details[] => {
  return quote.lines.map((line) => {
    let OREILLEStr = '';
    const ears = getSelectedEars(line.options.ears);
    switch (ears) {
      case SELECTED_EARS.BOTH:
        OREILLEStr = 'OGOD';
        break;
      case SELECTED_EARS.LEFT_ONLY:
        OREILLEStr = 'OG';
        break;
      case SELECTED_EARS.RIGHT_ONLY:
        OREILLEStr = 'OD';
        break;
    }

    const getOreille = (index: number): OREILLE | null => {
      if (!line.options.ears[index]) {
        return null;
      }

      const getSpecificOption = (
        optType: TYPE,
        name: string,
      ): Option | undefined => {
        const opt = getOption(line, index, optType, name);

        if (!opt || opt.name !== name) {
          return;
        }

        return opt;
      };

      const marque =
        (line.category?.name === PRODUCT_CATEGORY.DEPORTE &&
          line.product?.label) ||
        null;
      const ecouteur = getLabel(getOption(line, index, TYPE.EAR_BUD)?.label);

      let article = null;
      let bague = null;
      if (line.articles && line.articles.length > 0) {
        const art = line.articles.find(
          (arti) => arti.selected && arti.selected[index] === true,
        );
        if (!art) {
          return null;
        }
        article = art.ID;

        if (art.bagues && art.bagues.bagues && art.bagues.bagues.length > 0) {
          const artBagues = art.bagues.bagues;
          const artBague = artBagues.find(
            (bague) =>
              bague.marque.toLowerCase() === marque?.toLowerCase() &&
              bague.ecouteur.toLowerCase() === ecouteur?.toLowerCase(),
          );
          if (artBague) {
            bague = artBague.id;
          }
        }
      }

      const baseRule = getBaseRule(line);

      let forme = getLabel(getOption(line, index, TYPE.SHAPE)?.label);
      let formeEpaulement = null;
      if (forme && forme.includes('Épaulement')) {
        const formeTokens = forme.split(' ');
        forme = formeTokens[0];
        formeEpaulement = formeTokens[1];
      }
      const longueurEpaulement = getLabel(
        getOption(line, index, TYPE.SHOULDER)?.label,
      );
      if (longueurEpaulement && longueurEpaulement.includes('Épaulement')) {
        const longueurEpaulementTokens = longueurEpaulement.split(' ');
        formeEpaulement = longueurEpaulementTokens[1];
      }

      let event = null;
      let eventDiam = null;
      let eventSurMesure = null;
      const eventOpt = getOption(line, index, TYPE.EVENT);
      if (eventOpt) {
        if (eventOpt.label.includes(' mm')) {
          eventDiam = eventOpt.label.split(' ')[0];
        } else if (eventOpt.name === 'CUSTOM' && eventOpt.value) {
          eventSurMesure = eventOpt.value[index]?.toString() || null;
        } else {
          event = getOptionRule(baseRule, TYPE.EVENT, eventOpt.name);
        }
      }

      let optionsEcouteur: string[] | null = [];
      const extractionCord = getOptionRule(
        baseRule,
        TYPE.MISCELLANEOUS,
        getSpecificOption(TYPE.MISCELLANEOUS, MISCELLANEOUS.EXTRACTION_CORDS)
          ?.name || '',
      );
      if (extractionCord) {
        optionsEcouteur.push(extractionCord);
      }
      const earBudAssembly = getOptionRule(
        baseRule,
        TYPE.EAR_BUD_ASSEMBLY,
        getOption(line, index, TYPE.EAR_BUD_ASSEMBLY)?.name || '',
      );
      if (earBudAssembly) {
        optionsEcouteur.push(earBudAssembly);
      }
      const earwaxShielding = getOptionRule(
        baseRule,
        TYPE.EARWAX_SHIELDING,
        getOption(line, index, TYPE.EARWAX_SHIELDING)?.name || '',
      );
      if (earwaxShielding) {
        optionsEcouteur.push(earwaxShielding);
      }
      const groovedBlowhole = getOptionRule(
        baseRule,
        TYPE.MISCELLANEOUS,
        getSpecificOption(TYPE.MISCELLANEOUS, MISCELLANEOUS.GROOVED_EVENT)
          ?.name || '',
      );
      if (groovedBlowhole) {
        optionsEcouteur.push(groovedBlowhole);
      }
      if (optionsEcouteur.length === 0) {
        optionsEcouteur = null;
      }

      return {
        categorie: getLabel(line.category?.label),
        produit:
          (line.category?.name !== PRODUCT_CATEGORY.DEPORTE &&
            line.product?.label) ||
          null,
        marque,
        matiere: getLabel(getOption(line, index, TYPE.MATERIAL)?.label),
        forme,
        article,
        bague,
        formeEpaulement,
        couleur: getLabel(getOption(line, index, TYPE.COLOR)?.label),
        finition: getLabel(
          getOption(line, index, TYPE.SURFACE)?.label ||
            getSpecificOption(TYPE.MISCELLANEOUS, MISCELLANEOUS.SANDING)?.label,
        ),
        vernisAntibacterien:
          getOptionRule(
            baseRule,
            TYPE.SURFACE,
            getSpecificOption(TYPE.SURFACE, SURFACE.ANTIBACTERIAL)?.name || '',
          ) ||
          getOptionRule(
            baseRule,
            TYPE.MISCELLANEOUS,
            getSpecificOption(
              TYPE.MISCELLANEOUS,
              MISCELLANEOUS.ANTIBACTERIAL_VARNISH,
            )?.name || '',
          ) ||
          null,
        ecouteur,
        optionsEcouteur,
        tube:
          getOptionRule(
            baseRule,
            TYPE.TUBE,
            getOption(line, index, TYPE.TUBE)?.name || '',
          ) || null,
        optionTube:
          getOptions(line, index, TYPE.OPTION_TUBE)?.map((opt) =>
            getOptionRule(baseRule, TYPE.OPTION_TUBE, opt.name),
          ) || null,
        event,
        eventDiam,
        eventSurMesure,
        optionAquason:
          (line.product?.name === PROTECTION_SHAPE.AQUASON &&
            getOptionRule(
              baseRule,
              TYPE.FLOAT,
              getOption(line, index, TYPE.FLOAT)?.name || '',
            )) ||
          null,
        optionAquastop:
          (line.product?.name === PROTECTION_SHAPE.AQUASTOP &&
            getOptionRule(
              baseRule,
              TYPE.CORD,
              getOption(line, index, TYPE.CORD)?.name || '',
            )) ||
          null,
        optionEP2:
          (line.product?.name === PROTECTION_SHAPE.PASSTOP_EP2 &&
            getOptionRule(
              baseRule,
              TYPE.OPTION,
              getOption(line, index, TYPE.OPTION)?.name || '',
            )) ||
          null,
        optionCordonEP2:
          (line.product?.name === PROTECTION_SHAPE.PASSTOP_EP2 &&
            getOptionRule(
              baseRule,
              TYPE.CORD_COLOR,
              getOption(line, index, TYPE.CORD_COLOR)?.name || '',
            )) ||
          null,
        optionGravureEP2:
          (line.product?.name === PROTECTION_SHAPE.PASSTOP_EP2 &&
            getOptionRule(
              baseRule,
              TYPE.ENGRAVING,
              getOption(line, index, TYPE.ENGRAVING)?.name || '',
            )) ||
          null,
        optionPianissimo:
          (line.product?.name === PROTECTION_SHAPE.PIANISSIMO &&
            getOptions(line, index, TYPE.OPTION)?.map(
              (opt) => getLabel(opt.label) || '',
            )) ||
          null,
        optionPasstopT:
          (line.product?.name === PROTECTION_SHAPE.PASSTOP_T &&
            getOptions(line, index, TYPE.OPTION)?.map((opt) =>
              getOptionRule(baseRule, TYPE.OPTION, opt.name),
            )) ||
          null,
        optionTir:
          (line.category?.name === PRODUCT_CATEGORY.CHASSE_TIR &&
            getOptionRule(
              baseRule,
              TYPE.CAMO,
              getOption(line, index, TYPE.CAMO)?.name || '',
            )) ||
          null,
        optionObturateur:
          (line.product?.name === PROTECTION_SHAPE.OBTURATEUR &&
            getOptions(line, index, TYPE.OPTION)?.map((opt) =>
              getOptionRule(baseRule, TYPE.OPTION, opt.name),
            )) ||
          null,
        otoscan:
          transferFabricationMethod[quote.transfer.method] ===
          FabricationMethod.TRADITIONAL,
        repereCouleur:
          getOption(line, index, TYPE.COLOR_MARKER)?.selected[index] ||
          getSpecificOption(TYPE.MISCELLANEOUS, MISCELLANEOUS.COLOR_MARKER) !==
            undefined ||
          false,
        sousGarantie: quote.warranty || false,
      };
    };

    return {
      PATIENT: {
        nom: quote.patient?.name,
        age: quote.patient?.age,
        surdite:
          (quote.patient &&
            quote.patient.deafness &&
            DEAFNESS_LABEL[quote.patient.deafness]) ||
          null,
        serial: quote.serialNumber,
        sexe: quote.patient?.gender && quote.patient?.gender.slice(0, 1),
      },
      OREILLE: OREILLEStr,
      OG: getOreille(0),
      OD: getOreille(1),
      files: quote.transfer.prints.map((print) => print.name),
    } as Details;
  });
};

const getTempOrder = (quote: BaseQuoteData, user: User): TempOrder => {
  assert(quote.transporter?.ID !== undefined);
  assert(quote.shippingAddress !== undefined);

  let dateLivrSouh: string | number | undefined = quote.shippingDate;
  if (dateLivrSouh) {
    dateLivrSouh = new Date(dateLivrSouh).valueOf();
  } else {
    dateLivrSouh = new Date().valueOf();
  }

  // FIXME assert instead optional values instead of || ''
  return {
    /* eslint-disable @typescript-eslint/camelcase */
    clientID: quote.clientId,
    TRP: quote.transporter.ID,
    dateLivrSouh,
    message: quote.comment || '',
    livr_id: quote.shippingAddress.ID,
    livr_rs: quote.shippingAddress.raisonSociale,
    livr_adr: quote.shippingAddress.adresse,
    livr_cp: quote.shippingAddress.codePostal,
    livr_ville: quote.shippingAddress.ville,
    livr_pays: quote.shippingAddress.isoCountry || 'FR',
    contact: '', // set-server-side (user.contact)
    email: '', // set server-side (user.email)
    details: getOrderDetails(quote),
    ATT_attente: !!quote.waiting,
    ATT_motif: quote.waiting,
    noBoite: quote.boxNumber,
    modeEmpreinte: quote.transfer.method + 1,
    /* eslint-enable @typescript-eslint/camelcase */
  };
};

export const getOrder = (
  quote: BaseQuoteData,
  user: User,
  detailsIndex: number,
): Partial<Order> => {
  const {rs, adr, cp, ville} = user.adr_livr;

  return {
    /* eslint-disable @typescript-eslint/camelcase */
    // ID: '',
    id_client: {__KEY: user.ID, __STAMP: 1},
    // etat: 1,
    // etatNegoce: 0,
    dateSaisie: new Date().valueOf() / 1000,
    departSociete: new Date().valueOf() / 1000,
    numeroBon: '',
    // numeroAffaire: '',
    contact: user.contact,
    email: user.email,
    // profil: 2,
    // etatFabrication: 2,
    patient: quote.patient?.name,
    livr_ville: ville,
    livr_adresse: adr,
    livr_codePostal: cp,
    livr_raisonSociale: rs,
    oreille: getSelectedEars(quote.lines[detailsIndex].options.ears),
    details: getOrderDetails(quote)[detailsIndex],
    /* eslint-enable @typescript-eslint/camelcase */
  };
};

export const finalize = async (
  session: WakandaService,
  quote: BaseQuoteData,
  lines: BaseQuoteLine[],
  user: User,
): Promise<Order | undefined> => {
  try {
    const commande = getTempOrder(quote, user);

    const order =
      (await session.fetch<Order>('Commandes', 'saveCdeEmbouts', {
        ID: quote.lines[0].id,
        ...commande,
        lignes: lines.map(
          (line) =>
            line.articles?.map(({ID, qty, totalHT}) => ({ID, qty, totalHT})) ||
            [],
        ),
      })) || undefined;

    if (!order || !order.ID) {
      return undefined;
    }

    const {prints} = quote.transfer;
    if (!prints.length) {
      return order;
    }

    const formData = new FormData();
    formData.append('ID', order.ID);
    prints.map(
      ({originFileObj: print}) => print && formData.append('prints[]', print),
    );

    await fetch(`${process.env.REACT_APP_WAKANDA_HOST}/Commandes/UploadFiles`, {
      method: 'POST',
      credentials: 'include',
      body: formData,
    });

    return order;
  } catch (err) {
    // eslint-disable-next-line no-console
    console.error(err);
    return undefined;
  }
};

export const getRule = (value: string): string => {
  return value
    .slice(1, -2)
    .split('_')
    .slice(2)
    .join('_');
};

export const getLabelFromRule = (
  value: string,
  rules: any,
  labels: any,
): string => {
  const rule = getRule(value);
  const entry = Object.entries(rules).find((entry) => entry[1] === rule);
  if (entry) {
    value = labels[entry[0]];
  }

  return value;
};

export const getStringEnumValues = <
  EnumKey extends string,
  EnumValue extends string
>(
  enumVariable: {[key in EnumKey]: EnumValue},
): EnumValue[] => Object.values(enumVariable) as EnumValue[];

export const getNumericEnumValues = <
  EnumKey extends string,
  EnumValue extends number
>(
  enumVariable: {[key in EnumKey]: EnumValue},
): EnumValue[] =>
  (Object.values(enumVariable) as EnumValue[]).filter(
    // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
    // @ts-ignore
    (key: EnumValue) => typeof enumVariable[Number(key)] === 'string',
  );
