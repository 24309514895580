import {Col, Form, Row, Spin} from 'antd';
import {PaginationConfig} from 'antd/lib/pagination';
import {SorterResult, TableCurrentDataSource} from 'antd/lib/table/interface';
import moment from 'moment';
import React, {FC, memo, useMemo, useState} from 'react';
import {Redirect} from 'react-router-dom';
import {useSession, useUser} from '../../contexts/Session';
import {WakandaService} from '../../util/fetch-wakanda';
import {cssUtils} from '../../util/render';
import {Search} from '../../util/SearchFilter';
import {BetterOrder, Client, Order} from './data';
import OrdersFilterForm, {FilterData} from './Filter';
import OrderList, {FiltersType, PaginationType, SorterType} from './List';

export const getDefaultSearch = (): Search => ({
  searchedColumn: '',
  searchText: '',
});

const getDefaultFilter = (): FilterData => ({
  orderDateRange: [moment().subtract({year: 1}), moment().endOf('day')],
  state: undefined,
  productDescription: undefined,
});

const fixOrders = (_orders: Order[]): BetterOrder[] =>
  (_orders || []).map<BetterOrder>((order) =>
    Object.assign(order, {
      montant: order.CDV_LignesCollection.reduce(
        (_sum, {montantHT = 0, quantiteVente}) =>
          _sum + montantHT * quantiteVente,
        0,
      ),
    }),
  );

interface GetOrderOptions {
  pagination?: PaginationType;
  sorter?: SorterType;
  tableFilters?: FiltersType;
  headerFilters?: FilterData;
}

interface GetOrderResult {
  commandes: Order[];
  clients: Client[];
  count: number;
  unfilteredCount: number;
}

const getOrders = (
  session: WakandaService,
  {pagination, sorter, tableFilters, headerFilters}: GetOrderOptions = {},
): Promise<GetOrderResult> => {
  const options: any = {};

  if (pagination) {
    options.limit = pagination[1];
    options.offset = (pagination[0] - 1) * pagination[1];
  }

  if (sorter) {
    options.orderBy = `${sorter[0]} ${sorter[1] === 'ascend' ? 'asc' : 'desc'}`;
  }

  options.filter = {};
  if (tableFilters) {
    options.filter = {
      ...options.filter,
      ...Object.fromEntries(
        Object.entries(tableFilters)
          .filter(([_, values]) => !!values?.length)
          .map(([key, values]) => [key, values?.[0]]),
      ),
    };
  }

  if (headerFilters) {
    const stringKeys: (keyof FilterData)[] = ['state', 'productDescription'];
    for (const key of stringKeys) {
      if (headerFilters[key]) {
        options.filter[key] = headerFilters[key];
      }
    }

    if (headerFilters.orderDateRange.length === 2) {
      options.filter.orderDateRange = headerFilters.orderDateRange.map((date) =>
        date?.format(),
      );
    }
  }

  return session.fetch('Commandes', 'getCde', {
    type: 'audio',
    ...options,
  }) as Promise<GetOrderResult>;
};

const defaultSortOrder = ['dateSaisie', 'descend'];

const Orders: FC = () => {
  const session = useSession();
  const user = useUser();

  const [loading, setLoading] = useState(false);
  const [orders, setOrders] = useState<BetterOrder[] | null>(null);
  const [filter, setFilter] = useState<FilterData>(getDefaultFilter());

  const [tableFilters, setTableFilters] = useState<FiltersType>({});
  const [pagination, setPagination] = useState<PaginationType>([0, 10]);
  const [sorter, setSorter] = useState<SorterType>([
    ...defaultSortOrder,
  ] as SorterType);
  const [count, setCount] = useState<number>(0);
  const [unfilteredCount, setUnfilteredCount] = useState<number>(0);
  /*const [filteredOrders, setFilteredOrders] = useState<BetterOrder[] | null>(
    null,
  );*/
  const searchState = useState<Search>(getDefaultSearch());

  useMemo(() => {
    if (!user) {
      return;
    }

    let abort = false;
    (async () => {
      setOrders(null);
      setLoading(true);
      const result = await getOrders(session, {
        pagination,
        sorter,
        tableFilters,
        headerFilters: filter,
      });
      setLoading(false);

      if (!result || abort) {
        return;
      }

      setOrders(fixOrders(result.commandes));
      setCount(result.count);
      setUnfilteredCount(result.unfilteredCount);
    })();

    return () => {
      abort = true;
    };
  }, [session, user, pagination, sorter, tableFilters, filter]);

  /*useMemo(() => setFilteredOrders(filterOrders(orders || [], filter)), [
    orders,
    filter,
  ]);*/

  if (!user) {
    return <Redirect to="/login" push={true} />;
  }

  const WrappedFilterForm = Form.create({
    name: 'filterForm',
  })((props) => (
    <OrdersFilterForm
      {...props}
      value={filter}
      onFilter={(filterData) => {
        setFilter(filterData);
      }}
      onResetFilter={() => {
        searchState[1]({...searchState[0], searchText: ''});
        setFilter(getDefaultFilter());
      }}
    />
  ));

  const handleTableChange = (
    _pagination: PaginationConfig,
    _tableFilters: FiltersType,
    _sorter: SorterResult<BetterOrder>,
    _extra: TableCurrentDataSource<BetterOrder>,
  ): void => {
    if (
      _pagination.current &&
      _pagination.pageSize &&
      [_pagination.current, _pagination.pageSize].join(',') !==
        pagination.join(',')
    ) {
      setPagination([_pagination.current, _pagination.pageSize]);
    }

    if (JSON.stringify(_tableFilters) !== JSON.stringify(tableFilters)) {
      setTableFilters(_tableFilters);
    }

    if (_sorter.columnKey && _sorter.order) {
      if ([_sorter.columnKey, _sorter.order].join(',') !== sorter.join(',')) {
        setSorter([_sorter.columnKey, _sorter.order] as SorterType);
      }
    } else {
      if (defaultSortOrder.join(',') !== sorter.join(',')) {
        setSorter([...defaultSortOrder] as SorterType);
      }
    }
  };

  const removeOrder = (id: string): void => {
    if (!orders) {
      return;
    }

    setOrders(orders.filter(({ID}) => ID !== id));
  };

  return (
    <Row type="flex" justify="center" css={cssUtils.heightP()}>
      <Col span={20}>
        <h1>Suivi des commandes</h1>
        {loading ? (
          <div style={{textAlign: 'center', marginTop: '50%'}}>
            <Spin size="large" />
          </div>
        ) : null}
        {orders ? (
          <div>
            <WrappedFilterForm />
            <h2
              css={
                orders.length < count
                  ? {float: 'left', position: 'relative', top: 20}
                  : {}
              }
            >
              Commandes (
              {unfilteredCount !== count
                ? `${count}/${unfilteredCount}`
                : count}
              )
            </h2>
            {orders ? (
              <OrderList
                orders={orders}
                removeOrder={removeOrder}
                searchState={searchState}
                pagination={pagination}
                /*onPaginationChange={onPaginationChange}*/
                onTableChange={handleTableChange}
                count={count}
                sorter={sorter}
                filters={tableFilters}
              />
            ) : null}
          </div>
        ) : null}
      </Col>
    </Row>
  );
};

export default memo(Orders);
