import {Button, Checkbox, Col, Form, Input, Row} from 'antd';
import {FormComponentProps} from 'antd/lib/form/Form';
import React, {FC, FormEventHandler, memo} from 'react';
import {renderIconInputPrefix} from '../../util/render';

export interface LoginFormData {
  username: string;
  password: string;
  remember: boolean;
}

export type LoginFormSubmitCallback = (data: LoginFormData) => void;

export interface LoginFormProps extends FormComponentProps<LoginFormData> {
  onSubmit: LoginFormSubmitCallback;
  loading?: boolean;
}

const LoginForm: FC<LoginFormProps> = ({form, onSubmit, loading}) => {
  const {getFieldDecorator, validateFieldsAndScroll} = form;

  const handleSubmit: FormEventHandler<HTMLFormElement> = (event) => {
    event.preventDefault();
    validateFieldsAndScroll((err, values) => !err && onSubmit(values));
  };

  return (
    <Form onSubmit={handleSubmit}>
      <Form.Item label="Nom d'utilisateur">
        {getFieldDecorator('username', {
          initialValue: process.env.REACT_APP_WAKANDA_LOGIN,
          rules: [
            {
              required: true,
              message: "Veuillez entrer votre nom d'utilisateur",
            },
          ],
        })(<Input autoFocus={true} prefix={renderIconInputPrefix('user')} />)}
      </Form.Item>
      <Form.Item label="Mot de passe">
        {getFieldDecorator('password', {
          initialValue: process.env.REACT_APP_WAKANDA_PASSWORD,
          rules: [
            {required: true, message: 'Veuillez entrer votre mot de passe'},
          ],
        })(<Input prefix={renderIconInputPrefix('unlock')} type="password" />)}
      </Form.Item>
      <Row type="flex" justify="space-between">
        <Col>
          <Form.Item css={{position: 'relative', bottom: 3}}>
            {getFieldDecorator('remember', {
              valuePropName: 'checked',
              initialValue: true,
            })(<Checkbox>Se souvenir de moi</Checkbox>)}
          </Form.Item>
        </Col>
        <Col>
          <Button
            type="primary"
            htmlType="submit"
            icon="login"
            loading={loading}
          >
            Connexion
          </Button>
        </Col>
      </Row>
    </Form>
  );
};

export default memo(LoginForm);
