import {css} from '@emotion/core';
import {Button, Col, Row, Tooltip} from 'antd';
import React, {FC, memo, ReactElement} from 'react';
import {useHistory} from 'react-router-dom';
import {useCurrentEars, useCurrentLine, useCurrentSelectedOptions, useLines} from '../../contexts/Quote';
import ConfirmationButton from '../../util/ConfirmationButton';
import {cssUtils, getMissingGroups, getSelectedGroups} from '../../util/render';
import {getSelectedEars, Group, SELECTED_EARS} from './static';

export interface AddToCartButtonProps {
  groups: Group[];
  children?: ReactElement;
  parentPath: string;
}

const MissingGroupTooltip: FC<{groups: Group[]; suffix?: string}> = ({
  groups,
  suffix = '',
}) =>
  groups.length ? (
    <div>
      <p>
        Les options obligatoires suivantes n&apos;ont pas été renseignées
        <em> {suffix}</em> :
      </p>
      <ul>
        {groups.map(({label}, index) => (
          <li key={index}>{label}</li>
        ))}
      </ul>
    </div>
  ) : null;

const FormButtons: FC<AddToCartButtonProps> = ({
  groups,
  children,
  parentPath,
}) => {
  const [currentLine] = useCurrentLine();
  const [lines, setLines] = useLines();
  const [ears] = useCurrentEars();
  const [selectedOptions, setSelectedOptions] = useCurrentSelectedOptions();

  const history = useHistory();

  const selectedGroups = getSelectedGroups(selectedOptions);
  const {
    missingGroups,
    emptyRequiredGroups,
    missingEarsRequiredGroups,
  } = getMissingGroups(ears, selectedOptions, groups);

  const selectedEars = getSelectedEars(ears);
  const disabled =
    selectedEars === SELECTED_EARS.NONE || missingGroups.length > 0;

  if(currentLine.valid !== !disabled) {
    currentLine.valid = !disabled;
    setLines([...lines]);
  }

  return (
    <Row
      type="flex"
      justify="space-between"
      css={[
        children
          ? css`
              @media (max-width: 992px) {
                > .ant-col:first-of-type,
                > .ant-col:last-of-type {
                  .ant-btn {
                    display: none;
                  }
                }
              }
            `
          : null,
        css`
          margin-top: 16px;
          > .ant-col {
            align-self: flex-end;
            .ant-btn {
              margin-bottom: 10px;
            }
          }
        `,
      ]}
    >
      <Col>
        <ConfirmationButton
          css={Object.keys(selectedGroups).length ? null : cssUtils.vHidden}
          callback={() => {
            setSelectedOptions([]);
            history.push(`${parentPath}/options`);
          }}
          confirmationText={
            <p>
              Êtes-vous sûr(e) ?<br />
              Les options sélectionnées seront perdues.
            </p>
          }
          confirmationButton="Oui, réinitialiser"
          size="large"
          icon="rollback"
        >
          Réinitialiser les options
        </ConfirmationButton>
      </Col>
      {children ? <Col>{children}</Col> : null}
      <Col>
        <Tooltip
          title={
            selectedEars === SELECTED_EARS.NONE ? (
              'Sélectionnez au moins une oreille'
            ) : missingGroups.length ? (
              <div>
                <MissingGroupTooltip groups={emptyRequiredGroups} />
                {missingEarsRequiredGroups.map((missingGroups, index) => (
                  <MissingGroupTooltip
                    key={index}
                    groups={missingGroups}
                    suffix={`pour l'oreille ${index ? 'droite' : 'gauche'}`}
                  />
                ))}
              </div>
            ) : null
          }
        >
          <Button
            type="primary"
            icon="shopping-cart"
            size="large"
            disabled={disabled}
            onClick={() => history.push('/cart')}
          >
            Ajouter au Panier
          </Button>
        </Tooltip>
      </Col>
    </Row>
  );
};

export default memo(FormButtons);
