import {css} from '@emotion/core';
import {Button, Col, Row, Tooltip} from 'antd';
import React, {FC, memo, useEffect, useState} from 'react';
import {Redirect} from 'react-router-dom';
import {UndefinedTuple} from '../../contexts/Quote';
import {useSession, useUser} from '../../contexts/Session';
import {UserType, WakandaService} from '../../util/fetch-wakanda';
import NewsArticle from './Article';

export enum TargetType {
  CLIENT = 0,
  GROUP = 1,
  ALL = 2,
}

export const TargetTypeLabel: {[target in TargetType]: string} = {
  [TargetType.CLIENT]: 'Clients',
  [TargetType.GROUP]: 'Groupes',
  [TargetType.ALL]: 'Tous les utilisateurs',
};

export interface Article {
  id: number;
  hasImage?: boolean;
  image?: string;
  title: string;
  content: string;
  editMode?: boolean;
  publicationDate: string;
  visibilityDates?: UndefinedTuple<string>;
  targetType?: TargetType;
  targets?: string[];
}

export interface WakArticle {
  ID: number;
  title: string;
  body: string;
  creationDate: string;
  visibilityStartDate: string;
  visibilityEndDate: string;
  targetType: string;
  targets: string[];
  image: string;
  hasImage?: boolean;
}

const fetchArticles = async (session: WakandaService): Promise<WakArticle[]> =>
  (await session.fetch<WakArticle[]>('News', 'get')) || [];

const convertFromWak = (articles: WakArticle[]): Article[] =>
  (articles.map &&
    articles.map<Article>(
      ({
        ID,
        title,
        body,
        creationDate,
        visibilityStartDate,
        visibilityEndDate,
        targetType,
        targets,
        hasImage,
      }) => ({
        id: ID,
        title,
        content: body,
        publicationDate: creationDate,
        visibilityDates: [visibilityStartDate, visibilityEndDate],
        targetType: Number(targetType) as TargetType,
        targets,
        image: hasImage
          ? [process.env.REACT_APP_ASSETS_HOST, 'news', `${ID}.jpg`].join('/')
          : '',
      }),
    )) ||
  [];

const News: FC = () => {
  const session = useSession();
  const user = useUser();
  const [articles, setArticles] = useState<Article[]>([]);
  const [order, setOrder] = useState(true);

  useEffect(() => {
    let abort = false;
    (async () => {
      if (user && !user.isGuest) {
        const _articles = await fetchArticles(session);
        if (abort) {
          return;
        }
        setArticles(convertFromWak(_articles));
      }
    })();

    return () => {
      abort = true;
    };
  }, [session, user]);

  if (!user) {
    return <Redirect to="/login" push={true} />;
  }

  const handleArticleSave = (previousArticle: Article) => async (
    newArticle: Article,
  ): Promise<void> => {
    const formData = new FormData();
    if (newArticle.id >= 0) {
      formData.append('ID', newArticle.id.toString());
    }

    formData.append('title', newArticle.title);
    formData.append('body', newArticle.content);
    if (newArticle.visibilityDates?.[0]) {
      formData.append('visibilityStartDate', newArticle.visibilityDates[0]);
    }
    if (newArticle.visibilityDates?.[1]) {
      formData.append('visibilityEndDate', newArticle.visibilityDates[1]);
    }
    if (newArticle.targetType != null) {
      formData.append('targetType', newArticle.targetType.toString());
    }
    formData.append('targets', JSON.stringify(newArticle.targets || []));
    formData.append('hasImage', JSON.stringify(newArticle.hasImage || false));
    if (newArticle.image?.startsWith('blob:')) {
      formData.append('image', await (await fetch(newArticle.image)).blob());
    }

    (async () => {
      const wakArticle = (await (
        await fetch(`${process.env.REACT_APP_WAKANDA_HOST}/postNews`, {
          method: 'POST',
          credentials: 'include',
          body: formData,
        })
      ).json()) as WakArticle;

      newArticle.id = wakArticle.ID;
      newArticle.editMode = false;

      const _articles = [...articles];
      _articles.splice(articles.indexOf(previousArticle), 1, newArticle);
      setArticles(_articles);
    })();
  };

  const handleArticleDelete = async (article: Article): Promise<void> => {
    if (article.id >= 0) {
      await session.fetch<WakArticle[]>('News', 'delete', {ID: article.id});
    }

    setArticles(articles.filter((_article) => _article !== article));
  };

  return (
    <Row type="flex" justify="center">
      <Col css={{width: '100%'}}>
        <Row
          type="flex"
          justify="space-between"
          css={css`
            .ant-col {
              margin: 10px;
            }
          `}
        >
          <Col>
            <h2 css={{margin: 0, lineHeight: '32px'}}>Fil d&apos;actualités</h2>
          </Col>
          <Col>
            {articles.length > 1 ? (
              <Tooltip title={`Trier ${order ? 'anté' : ''}chronologiquement`}>
                <Button
                  icon={order ? 'sort-ascending' : 'sort-descending'}
                  onClick={() => {
                    setOrder(!order);
                    setArticles([...articles].reverse());
                  }}
                />
              </Tooltip>
            ) : null}
            {user.type === UserType.ADMIN ? (
              <Button
                type="primary"
                icon="plus"
                css={{marginLeft: 10}}
                onClick={() =>
                  setArticles([
                    {
                      id: -articles.length,
                      title: '',
                      content: '',
                      editMode: true,
                      publicationDate: new Date().toISOString(),
                    },
                    ...articles,
                  ])
                }
              >
                Nouvel article
              </Button>
            ) : null}
          </Col>
        </Row>

        {articles.map((article) => (
          <NewsArticle
            article={article}
            key={article.id}
            onArticleSave={handleArticleSave(article)}
            onArticleDelete={handleArticleDelete}
            editRights={user.type === UserType.ADMIN}
          />
        ))}
      </Col>
    </Row>
  );
};

export default memo(News);
